.bg{
    width: 100%;
    // height: 100%;
    top: 0;
    position: absolute;
    z-index: -1;
    img{
        width: 100%;
        height: auto;
        max-height: 915px;
        @media (max-width: $small) {  
            // height: 100vh;
            width: auto;
            max-height: inherit;
        }
    }
}