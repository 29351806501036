.page-template-page-about-us{    
    .team{
        .short-grid{
            position: relative;
        }
        h2{
            text-align: left;
            text-transform: uppercase;
            
            @media (max-width: $small) {
                text-align: center;
            }
            
        }
        h3{
            @include font-size(18px);
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            color: #215383;
            margin-top: 10px;
            text-align: center;
            @media (max-width: $small) {
                text-align: center;
            }
        }                     
        img.avatar{
            width: 100px;
            border-radius: 50%;
            text-align: center;
        }
        .column{
            @media (max-width: $small) {
                text-align: center;
            }
            margin-bottom: 50px;
        }
        img.broker{
            width: 100px;
        }
        .swiper{
            .avatar-column{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;                
            }
            .content-column{
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                h5{
                    font-weight: bold;
                    color: #215383;
                    @media (max-width: $small) {
                        text-align: center;
                        display: block;
                        width: 100%;
                        margin-top: 20px;
                    }
                    @media (max-width: $xsmall) {
                        text-align: center; 
                        padding: 0 20px;
                    }
                }
                p{
                    margin-top: 10px;
                    @media (max-width: $small) {
                        text-align: center; 
                    }
                    @media (max-width: $xsmall) {
                        text-align: center; 
                        padding: 0 20px;
                    }
                }
                a{
                    margin-top: 10px;
                    @media (max-width: $small) {
                        text-align: center;
                        display: block;
                        width: 100%;                        
                    }
                }
            }
            .swiper-button-prev,
            .swiper-button-next{
                color: #215383;
                &:after{
                    font-size: 20px;
                }
            }
            .swiper-button-prev{
                left: 0px;
            }
            .swiper-button-next{                
                right: 0px;
            }            
        }                
    }
}