.single-blog{
    .post-content{
        .quote-block{            
            position: relative;
            padding-left: 40px;
            margin: 50px 0;
            &:before{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 2px;
                height: 100%;
                background-color: #2177AB;
            }

            p{
                font-family: 'Nunito', sans-serif;
                @include font-size(24px);
                line-height: 1.3em;
                font-weight: 400;
                color: #333F51;
            }            
            .source{
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                @include font-size(18px);
                color: #8896AB;
                margin-top: 20px;
                display: block;
            }
        }
    }
}