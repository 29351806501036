.main-footer{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 20px 0;
    // height: 900px;
    height: 700px;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: $small) {
        background-position: center;
        // height: 1100px;
        height: 900px;
    }    
    @media (max-width: $xsmall) {
        background-position: center;
        // height: 1100px;
        height: 900px;
    }    
    @media (max-width: $xxsmall) {
        background-position: center;
        // height: 1400px;
        height: 800px;
    }    
    @media (min-width: $xlarge){
      background-size: 100% 120%;
    }

        .footer-wrapper{
            width: 100%;
        }

    .newsletter{        
        width: 100%;
        text-align: center;
        margin: 100px 0;
        @media (max-width: $xsmall) {
            margin: 20px 0;
        }
        h2{
            color: #FFF;
            font-weight: 700;
            @include font-size(36px);
            margin-bottom: 25px;
        }
        p{
            font-family: 'Poppins', sans-serif;
            @include font-size(20px);
            font-weight: 500;
            color: #fff;
            text-align: center;
            margin-bottom: 25px;
        }
        form{
            display: inline-flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;            
            @media (max-width: $xsmall) {
                justify-content: center;
                align-items: center;
                width: 100%;
            }
            input{
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                @include font-size(16px);
                padding: 10px 20px;
                border: none;
                border: 1px solid #D5DAE1;                
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
                border-radius: 8px;
                min-width: 330px;
                background-color: #fff;                
                outline: none;         
                @media (max-width: $xsmall) {
                    min-width: auto;
                    width: 100%;
                }       
                &[type="submit"]{
                    width: auto;
                    min-width: auto;
                    margin-left: 25px;  
                    color: #216282;               
                    cursor: pointer;
                    transition: all .5s $ease1;
                    @media (max-width: $small) {
                        margin-left: 0;
                        margin-top: 10px;
                    }   
                    &:hover{
                        transform: scale(1.02)!important;
                    }
                }                
            }
            span{
                margin-top: 10px;
                color: #FFF;
                @include font-size(12px);
            }
        }
    }    

    .logo-wrapper{
        @media (max-width: $small) {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
        }
        .logo{
            max-width: 200px;
            transition: all .5s $ease1;
            @media (max-width: $xsmall) {
                max-width: 150px;
            }
            &:hover{                
                transform: scale(1.05);                
            }
        }
    }    

    .row{
        // width: 100%;
        align-items: flex-start;
        .row{ 
            margin: 0; 
        }
    }
    
    nav{
    
        @media (max-width: $small) {
            margin-bottom: 20px;
        }    
        #menu-footer{
            @media (max-width: $xxsmall) {
                flex-direction: column;
            }
            li{
                @media (max-width: $xxsmall) {
                    margin-top: 10px;
                }
                &:first-child,&:last-child{
                    @media (max-width: $xxsmall) {
                        margin-left: 10px;
                        margin-right: 10px;
                    }       
                }
                a{
                    position: relative;   
                    @include font-size(14px);
                    // transition: font-weight .3s cubic-bezier(.17,.67,.67,.47);                                                       
                }
            }
        }
        // .privacy-policy{
        //     margin-left:20px;            
        // }
    }
    .copyright-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .copyright{
        color: #FFF;
        font-family: 'Poppins', sans-serif !important;
        font-weight: 600;
        position: relative;
        @include font-size(15px);
        // text-align: right;
        // margin-top: 50px;
        @media (max-width: $small) {
            text-align: center;
            // margin-top: 20px;
        }   
      }
      .social{
        position: relative;
        right: auto;
        display: flex;
        justify-content: center;
        @media (max-width: $small) {
          justify-content: center;
          // margin-top: 20px;
        }   
        .social-wrapper{
          margin: 20px 10px 0;
          &:first-of-type{
            margin-left: 0;
          }
        }
      }
    }
    
    .disclaimer{
        font-family: "Nunito", sans-serif;
        font-weight: 500;
        color:#fff;
        @include font-size(15px);
        line-height: 15px;
        margin-top: 50px;
    }
}

.mobile-app{
    z-index: 999999;
    width: 100%;
    // height: 100%;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;    
    // background: rgba(0,0,0,.3);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    .container{
        position: relative;
        width: 100%;
        height: 100px;
        background: #fff;
        border-radius: 0px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 20px;

        opacity: 0;
        visibility: hidden;

        img.app-logo{
            width: auto;
            height: 50px;            
            border-radius: 15px;
            margin: 0 10px;
        }
        img.app-download-img{
            height: 40%;
        }
        .content{
            padding: 0 30px 0 0;

            h6{
                font-family: "Poppins", sans-serif;
                @include font-size(12px);
                line-height: 1.4em;
                font-weight: 700;
                color: #215383;
            }
            p{
                color: #000;
                line-height: 1em;
                @include font-size(10px)
            }
        }
        a{
            padding-left: 5px;
            @include font-size(14px)
        }        
        .close{
            position: relative;
            // left: 20px;
            // top: 0px;
            width: 20px;
            height: 20px;
            // top: 50%;
            // transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            span{
                width: 2px;
                background: #215383;
                height: 100%;
                position: relative;
                display: block;
                pointer-events: none;
                &:first-of-type{
                    opacity: 0;
                    // transform: rotate(-45deg);                    
                    top: 0px;
                    right: 0px;
                }
                &:last-of-type{
                    opacity: 0;
                    // transform: rotate(45deg);                    
                    top: 0px;
                    right: 2px;
                }
            }
        }
    }
}