.page-template-page-contact{
    main{
        background: rgb(255,255,255);
        background: linear-gradient(60deg, rgba(72, 161, 243, 0.818) 0%, rgba(33,83,131,1) 58%);
        min-height: 100vh;
    }
    .contacts{
        padding-top: 200px;
        @media (max-width: $xsmall) {
            padding-top: 150px;
        }
        .column{
            margin-bottom: 120px;
            @media (max-width: $small) {
                text-align: center;
                margin-bottom: 70px;
            }
        }        

        h2,
        h1{
            font-family: "Poppins", sans-serif;
            font-weight: 900;
            color: #fff;
            margin-bottom: 60px;
            line-height: 1.2;
            text-transform: uppercase;
            @include font-size(32px);    
            @media (max-width: $small) {
                text-align: center;
            }        
        }
        h5{
            color: #fff;
            margin-bottom: 20px;
            font-weight: 700;
            @include font-size(30px);
        }
        p, a{
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            color: #fff;
            @include font-size(20px);
            line-height: 45px;
        }
        
        .form-section-wrapper{
            position: relative;                        
            @media (max-width: $small) {
                width: 80%;
                margin: 0 auto;
            }
            @media (max-width: $xsmall) {
                width: 100%;
            }
            .form-wrapper{
                                
                form{
                    display: flex;
                    flex-direction: column;
                    position: relative;    
                    
                    .input-field{
                        border-radius: 10px;
                        border: none;
                        margin-bottom: 20px;
                        outline: none;
                        padding: 14px;
                        @include font-size(12px);
                        font-family: "Poppins", sans-serif;
                        font-weight: 400;                        
                        color: #000;
                    }
                    
                    textarea{
                        height: 80px;       
                        resize: none;                 
                    }
                    button[type="submit"]{
                        font-family: "Poppins", sans-serif;
                        font-size: 1.25rem;
                        font-weight: 400;
                        padding: 0px 0;
                        cursor: pointer;
                        width: 60%;
                        margin: 0 auto;
                        border-radius: 4px;
                        border: none;        
                        span{
                            pointer-events: none;
                        }
                    }
                }
                .response{
                    opacity: 0;
                    position: absolute;
                    @include font-size(18px);
                    // font-family: 'Gill Sans';
                    font-weight: 500;                        
                    color: #fff;
                    margin-top: 20px;
                }
            }            
        }  
        .social{
            position: relative;
            right: inherit;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            @media (max-width: $small) {
                justify-content: center;
                top: auto;
            }
            .social-wrapper{
                margin-bottom: 0;
                margin: 0 10px;
                &:first-of-type{
                    margin-left: 0;
                }
            }
        }      
    }
    .main-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
        height: auto;
        @media (max-height: 825px) {
            position: relative;
        }
        @media (max-width: $small) {
            position: relative;
        }        
    }
}