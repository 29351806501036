.page-template-page-about-us{
    .how{
        h2{
            text-align: left;
            text-transform: uppercase;
            margin-bottom: 0;
            
            @media (max-width: $small) {
                text-align: center;
            }                        
        }
        p{
            margin-bottom: 40px;
            @media (max-width: $small) {
                text-align: center;
            }
        }
        .column{
            display: flex;
            flex-direction: column;            
            margin-bottom: 20px;
            .col{
                display: flex;
                flex-direction: row;
                align-items: center; 
                margin-bottom: 20px; 
                @media (max-width: $xsmall) {
                    justify-content: center;
                }                
                .nr{
                    h2{
                        border: solid 1px #215383;
                        border-radius: 50%;
                        width: 50px;
                        height: 50px;
                        margin: 0;
                        // height: 100%;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }                
                }
                h5{
                    color: #215383;
                    margin-left: 10px;
                    
                }
            }        
            .description{
                p{
                    
                    @media (max-width: $small) {
                        text-align: left;
                    }
                    @media (max-width: $xsmall) {
                        text-align: center;
                    }
                
                }
            }    
            
        }
    }
}