.home{

    .investing{
        position: relative;
        
        .watermark{
            // top: 50%;
            left: -30%;
        }
        .calculator{
            margin-bottom: 130px;
            @media (max-width: $medium){
                margin-bottom: 55px;
            }            
            // .row{
            //     display: flex;
            //     align-items: center;
            // }
            .calculator-wrapper{
                // background-color: #000;
                // min-height: 500px;
                border-radius: 20px;                
                display: flex;
                align-items: center;
                justify-content: space-between;
                #chart{
                    display: none;
                    // width: 100%!important;
                    margin: 0 auto;
                    min-height: 400px;
                    div{
                        margin: 0 auto;
                    }

                }
                .sliders{
                    @include max-screen($small){
                        margin-bottom: 50px;
                    }
                }
                h2{
                    color: #215383;
                    @include max-screen($small){
                        text-align: center;
                    }
                }
    
                .slider-wrapper{
                    margin-top: 25px;
                    
                    h5{
                        color:#215383;
                        .sign,.value{
                            font-weight: 900;
                        }        
                    }
                    .slider{                    
                        height:6px;                     
                        margin-top: 20px;
                        overflow: visible; 
                        padding:0;
                        position:relative;
                        width: 100%;
                        -moz-border-radius: 10px;
                        -webkit-border-radius: 10px;
                        border-radius: 10px;                        
                        .knob{
                            background-color: #215383;
                            text-align: center;
                            width: 26px;
                            height: 26px;
                            position: absolute;                        
                            z-index:2;                        
                            border: none;
                            border-radius:50%;
                            top: -9px;
                        }
                        .range{
                            background-color:#C4C4C4;
                            height:6px;                                                                     
                            border-radius: 10px;
                        }
                    }
                }
                .return{                    
                    text-align: center;
                    h5{
                        color: #215383;
                        @include font-size(32px);
                        margin-bottom: 15px;
                    }
                    span{
                        @include font-size(32px);
                        font-weight: 900;
                    }
                    .value{
                        color: #215383;
                        display: inline-block;
                        margin-bottom: 40px;                        
                    }
                    .sign{
                        color: #215383;
                        display: inline-block;
                    }

                    &.percent{
                        .value{
                            margin-bottom: 0;
                        }
                    }
                }
                
            }

            button.default{
                margin: 100px auto;
            }

            .watermark{
                // margin-top: 100px;
            }
        }    
    }
    .cta-finra{
      @media (max-width: $small){
        // margin-bottom:20px;
      }
      
      a{
        font-weight: 400;
        color: #000; 
        font-family: 'Nunito', sans-serif;
        text-align: right;
        @media (max-width: $small){
          text-align: center;          
        }
      }
    }
}