@keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

body{
    overflow-x: hidden;
    // font-family: 'Gill Sans', sans-serif;
    font-size: 16px;
    // -webkit-transform: translateZ(0);
    // -webkit-backface-visibility: hidden;
}

h1, h2, h3, h4, h5, p{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: auto;
}

h1{
    font-family: 'Righteous', cursive;
    font-weight: 400;
    text-transform: uppercase;
    color: #FFF;
    @include font-size(64px);
    line-height: 1.2em;
    margin-bottom: 20px;
    @include max-screen($small){
        text-align: center;
    }

    // @include max-screen($xxlarge){
    //     @include font-size(58px);
    // }

    // @include max-screen($xlarge){
    //     @include font-size(48px);
    // }

    // @include max-screen($large){
    //     @include font-size(40px);
    // }
    // @include max-screen($small){
    //     @include font-size(24px);
    // }
}
h2{
    font-family: 'Poppins', sans-serif;
    @include font-size(32px);
    line-height: 1.4em;
    font-weight: 900;
    color: #215383;
    margin-bottom: 40px;
    @include max-screen($small){
        text-align: center;
    }
    // @include max-screen($xxlarge){
    //     @include font-size(42px);
    // }

    // @include max-screen($xlarge){
    //     @include font-size(38px);
    // }

    // @include max-screen($large){
    //     @include font-size(32px);
    // }
    // @include max-screen($small){
    //     text-align: center;
    //     @include font-size(20px);
    // }

}
h3{
    @include font-size(32px);
    font-family: 'Poppins', sans-serif;
    // font-family: 'Gill Sans';
    font-weight: 500;
    color: #000;
    @media (max-width: $small){
        text-align: center;
    }
    @media (max-width: $medium){
        @include font-size(24px)
    }
}
h4{
    // @include font-size(20px);
    // font-family: 'Gill Sans';
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    @include max-screen($xxlarge){
        @include font-size(24px);
    }

    @include max-screen($xlarge){
        @include font-size(20px);
    }

    @include max-screen($large){
        @include font-size(16px);
    }
    @include max-screen($small){
        @include font-size(16px);
    }
}
h5{
    @include font-size(20px);
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000;
    @media (max-width: $medium){
        @include font-size(20px)
    }
}
strong{
    font-weight: bold;
}
.footer-menus{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.flex-row-end{
    display: flex;
    justify-content: flex-end;
}

main{
    overflow-x: hidden;
}
.header-menu{
    /*width: 100%;*/
}

.search-bar{
    display: flex;    
    order:2;
    @include max-screen($small){
        display: none;
    }
    .search-form{
        max-width: 90%;
        form{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            // width: 0;
            overflow: hidden;
            transition: all .5s $ease1;
            label.search-link{
                transform: translateY(2px);    
                cursor: pointer;   
                input{
                    display: none;
                }
                svg{                    
                    height: 16px;
                    margin-left: 10px;
                    transition: all .5s $ease1;
                    &:hover{
                        transform: scale(1.1);
                    }
                    path{
                        fill: #ffffff;
                    }
                }
            }
            label.search{
                width: 0;
                visibility: hidden;
                overflow: hidden;
                margin-left: 5px;
                input{
                    // top: 10px;
                    font-family: "Poppins", sans-serif;
                    width: 100%;
                    font-weight: 400;
                    font-size: 1rem;
                    padding: 9px 20px;
                    border: none;
                    // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
                    border-radius: 25px;
                    // min-width: 330px;
                    background-color: #fff;
                    outline: none;                    
                }
            }            
        }
    }
    .search-btn{
        position: relative;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        // svg{
        //     width: 16px;
        //     height: 16px;
        //     path{
        //         fill: #ffffff;
        //     }
        //     transition: all .5s $ease1;
        //     opacity: 0.9;
        // }
        &:hover{
            // svg{
            //     opacity: 1;
            //     transform: scale(1.2);
            // }
        }
    }

    // &:hover {
    //     .search-form {
    //         form {
    //             width: 330px;
    //             input {
    //                 // top: 0;
    //                 opacity: 1;
    //                 // transition: all .24s;
    //             }
    //         }
    //     }
    // }
}
nav{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    &.desktop-menu{
        justify-content: center;
    }
    #menu-header{
        flex-direction: row;
        li{
            padding: 0 10px;
            &.log-in{
                background: #fff;
                padding: 0px;
                border-radius: 25px;
                border: 2px solid transparent;
                color: #000; 
                margin: 0 5px;
                transition: all .3s $ease1;
                &:hover{
                    border: 2px solid #fff;
                    background-color: transparent;
                    a{
                        color: #fff;
                    }
                }
                a{
                    color: #215383;
                    display: block;
                    padding: 8px 15px;

                }

                &.sign-up{
                    background: transparent;
                    border: 2px solid #fff;
                    transition: all .3s $ease1;
                    &:hover{
                        background-color: #fff;
                        border: 2px solid transparent;
                        a{
                            color: #215383;
                        }
                    }
                    a{
                        color: #fff;
                        
                    }
                }
            }            
        }
    }
    h3{
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        position: relative;
        @include font-size(20px);
        color:#FFF;
        margin-bottom: 10px;
    }
    ul{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: column;
        font:normal 16px Arial;
        li{
            padding: 5px 0px;
            text-align: center;
            display: inline-block;

            &:last-child{
                @include padding-right(0px);
            }
            &:first-child{
                @include padding-left(0px);
            }
            a{
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                position: relative;
                @include font-size(15px);
                color:#FFF;
                text-align: center;
                &:after{
                    content: attr(title);
                    display: block;
                    font-weight: 600;
                    height: 0;
                    overflow: hidden;
                    visibility: hidden;
                    color: transparent;
                }
                &:hover{
                    font-weight: 600;
                }
            }
            &.current-menu-item{
                a{
                    font-weight: 600;
                }
            }
        }
    }
}
span{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #215383;
}
a{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-decoration: none;
    color: #215383;
}
p, td, span{
    font-family: 'Nunito', sans-serif!important;
    font-weight: 400;
    line-height: 1.7em;
    text-align: left;

    // @include max-screen($xxlarge){
    //     @include font-size(20px!important);
    // }

    // @include max-screen($xlarge){
    //     @include font-size(16px!important);
    // }

    // @include max-screen($large){
    //     @include font-size(14px!important);
    // }
    // @include max-screen($small){
    //     @include font-size(14px!important);
    // }

    &.sub-title{
        font-weight: 600;
        color: #fff;
        @include font-size(18px);
        // @include max-screen($xxlarge){
        //     @include font-size(24px);
        // }

        // @include max-screen($xlarge){
        //     @include font-size(18px);
        // }

        // @include max-screen($large){
        //     @include font-size(16px);
        // }
        @include max-screen($small){
            // @include font-size(14px);
            text-align: center;
        }
    }
}
section{
    margin-bottom: 130px;
    @media (max-width: $small){
        margin-bottom: 150px;
    }
    @media (max-width: $xsmall){
        margin-bottom: 100px;
    }
}

.btns{
    @media (max-width: $small) {
        text-align: center;
        display: flex;
        justify-content: center;
    }
    @media (max-width: $xxsmall) {
        flex-direction: column;
    }

    button.default{
        position: relative;
        background-color: #fff;
        border-radius: 16px;
        border: none;
        margin-top: 40px;
        padding: 0;
        overflow: hidden;

        @include font-size(18px);
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        color: #09427A;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;

        @media (max-width: $small) {
            margin-right: 0;
            margin: 40px 10px auto 10px;
        }
        // border: 1px solid transparent;
        .mask{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: #09427A;
            z-index: 0;
        }

        a,
        span{
            position: relative;
            font-weight: 400;
            color: #fff;
            @include font-size(20px);
            @include padding(13px 35px);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            z-index: 2;
            @media (max-width: $xsmall) {
                @include font-size(16px);
            }
        }

        &.white{
            background: #fff;
            border: none;
            a,
            span{
                color: #09427A;
            }
            .mask{
                background: #09427A;
                top: -100%;
            }
        }
        &.border{
            border: 1px solid #09427A;

            .mask{
                border: 1px solid transparent;
            }
        }
    }
}

.short-grid{
    margin: 0 200px!important;
    @include max-screen(1280px){
        margin: 0!important;
    }

}
.hide-small{
    display: inherit;
    @media (max-width: $small){
        display: none;
    }
}
.show-small{
    display: none;
    @media (max-width: $small){
        display: block;
    }
}

.scroll-wrapper{
    position: absolute;
    bottom: 20px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    @include font-size(18px);
    // font-family: 'Gill Sans';
    font-weight: normal;
    color: #fff;
    .scroll{
        position: relative;
        margin-top: 5px;
        border: 2px solid #fff;
        width: 23px;
        height: 36px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .dot{
            margin-bottom: 3px;
            opacity: 0;
        }
        .arrow{
            opacity: 0;
            overflow: hidden;
        }
    }
}

.zoom-img{
    overflow: hidden;
    height: auto;
    margin-bottom: 20px;
    position: relative;
    &>div{
        overflow: hidden;
    }
    img{
        position: relative;
        display: block;
        margin-bottom: 0;
        transition: all .5s $ease1;
    }
    &:hover img{
        transform: scale(1.1);
    }
}


.cookies-warning{
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: fixed;    
    margin: 0 auto; 
    width: 90vw;
    // height: 90vh;
    max-width: 1024px;
    z-index: 999999;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);   
    z-index: 999999;

    border-radius:25px; 
    height:850px; 
    max-height:95%;
    @media (max-width: $xsmall){
        width: 100%;
        padding: 0 10px;
        left: 0;
        transform: translate(0%, -50%);
    }
    .cookies-content{
        background-color: #FFF;
        width: 100%;
        height: 100%;
        padding: 50px;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        overflow: scroll;

        position:relative; 
        box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.26);
        overflow:scroll;
        .popup-logo{
          width:200px;
          height:auto;
          @media (max-width:$small){
            margin: 0 auto;
          }
          img{
            width:100%;
          }
        }

        .content-wrapper{
          margin-top: 40px;
          .speaker-wrapper{            
            display:flex;
            flex-direction: column;
            align-items:flex-start;
            @media (max-width:$small){
              margin-top: 10px;
              justify-content: center;
              align-items:center;
            }
            
            h3{
              @include font-size(24px);
            }
            img{
              width: 100%;
              max-width:150px;
              border-radius:50%;
            }

            & > div{
              margin-right:20px;
              @media (max-width:$medium){
                margin-right:0px;
              }
            }
            .speaker-content{
              display: flex;
              align-items: center;
              justify-content: space-between;
              @media (max-width:$medium){
                flex-direction: column;
              }
              .speaker-img{
                margin-top:30px; 
                margin-right:20px;
                @media (max-width:$medium){
                  margin: 30px auto 0;
                }
              }
            }
          }
          
        }
    }    
    .main-img{
      // margin:0 0 0 auto;
      // display:inherit;
      width:100%;
      @media (max-width:$small){
        // margin: 0 auto;
      }
    }
    
    h3{
      color:#215383;
      font-family: 'Poppins', sans-serif;
      @include font-size(28px);
      text-transform: none;
      display: inline-block;
    }
    h4{
      color:#215383;
      font-family: 'Poppins', sans-serif;
      @include font-size(30px);
      text-transform: none;
      display: inline-block;
      @media (max-width:$small){
        text-align: center;
      }
    }
    h2{
      margin:0;
    }
    .divider{
      width:100%;
      height:10px;
      border-radius:20px;
      background:#eff0f2;
      margin-top:20px;
    }
    .schedule{
      display:flex;
      justify-content:space-between;
      align-items:center;
      @media (max-width:$small){
        flex-direction: column;
      }
      .schedule-dates{
        display:flex;
        margin-top:30px;
        align-items:center;
        h3{
          margin-left:30px;
          font-size:20px;
        }        
        img{
          max-width: 50px;
        }
      }
      .link{
        margin-top:20px;
        img{
          max-height: 60px;
        }
      }
      a{
        background:#215383;
        color:#fff;
        border-radius:20px;
        padding:10px 20px;
        margin-top:30px;
      }
      .register-now{
        @media (max-width:$small){
          margin-top: 20px;
        }
      }
    }    
    p{
        font-family: 'Poppins', sans-serif;
        @include font-size(18px);
        line-height: 1.4em;
        // font-weight: 700;
        color: #215383;
    }
    .close{
        position: absolute;
        right: -10px;
        top: -15px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #215383;
        border-radius: 50%;        
        cursor: pointer;
        @media (max-width: $xsmall){
                        right: 10px;
        }
        span{
            width: 2px;
            background: #fff;
            height: 15px;
            position: relative;
            display: block;
            pointer-events: none;
            &:first-of-type{
                // opacity: 0;
                transform: rotate(-45deg);                    
                top: 0px;
                right: -1px;
            }
            &:last-of-type{
                // opacity: 0;
                transform: rotate(45deg);                    
                top: 0px;
                right: 1px;
            }
        }
    }
}

.upper-notation{
    bottom: 5px;
    font-size: 10px;
    display: inline-flex;
    position: absolute;
    margin-left: 5px;
}

.info{
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: 10px;
    bottom: 6px;
    cursor: pointer;
    svg{
        position: absolute;
        width: 100%;
        height: 100%;
        path{
            fill:#09427A;
        }
    }
}

table{
    width: 100%;
    overflow: hidden;
    tbody{
        overflow: auto;
    }
    tr{
        padding: 10px 0;
        position: relative;        
    }
    td{
        padding: 10px 0;
    }
    span{
        display: block;
        text-align: center!important;
        line-height: 1;
    }
    
}