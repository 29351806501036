.page-template-page-press{
  h4{
    font-family: 'Poppins', sans-serif;
    text-transform: none;
  }  
  .press-container{
    margin-top: 50px;
    .disc-group{
      background-color: transparent;
      box-shadow: none;
      border-bottom: 1px solid rgba(0,0,0,.1);
      border-radius: 0;
      padding: 24px 0;
      margin-bottom: 0;
      span{
        color: rgba(0,0,0,.3);
        font-size: 14px!important;
      }
    }
  }
}

.single-press{
  .press-content{
    margin-top: 150px;

    h4{
      font-family: 'Poppins', sans-serif;
      text-transform: none;
      color: #215383;
      @include font-size(20px);
    }  
    p{
      @include font-size(18px);
    }
    a{
      display: inline-block;
    }
    h2, h3, h4{
      margin-top: 50px;
    }
  }
}