.join{
    position: relative;
    
    .row{
        display: flex;
        align-items: center;        
        @media (max-width: $small) {
            text-align: center;
        }
        .columns{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        img{            
            max-width: 300px;
            @media (max-width: $small) {
                max-width: 200px;
                margin-bottom: 20px;                
            }                
        }
        p{
            width: 100%;
            @media (max-width: $small) {
                text-align: center;
            }
        }
        .btns{            
            width: 100%;
            button{
                display: inline-block;
            }
        }        
    }
}