.page-template-page-investors{
    h2, h3, h4, p{
        mix-blend-mode: plus-lighter;
    }
    .text-center{
        text-align: center;
    }
    .bg-parallax{
        position: relative;
        width: 100%;
        height: 500px;
        overflow: hidden;
    }
    section{
        position: relative;
        // min-height: 100vh;
        height: auto;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 200px;
        @include max-screen($small){
            justify-content: flex-start;
            min-height: auto;
        }
        
        &.product-top-align{
            justify-content: flex-start;
            min-height: auto;
            h3{                
                margin: 50px 0;

                &.centered-title{
                    text-align: center;
                }
            }

        }
        .row{
            width: 100%;
            position: relative;
        }
        h2{
            position: relative;
            margin: 150px 0 50px;
            top: 0;
            @include font-size(40px) ;            
            z-index: 9;
            display: block;
            @include max-screen($small){
                position: relative;
                text-align: center;
            }
        }
        h3{
            font-family: "Poppins", sans-serif;
            @include font-size(34px);
            color: #215383;
            font-weight: 500;
            margin-bottom: 20px;
            overflow: hidden;
            line-height: 1em;
            div{
                overflow: hidden;
                div{
                    padding-bottom: 5px;
                }
            }
            &.margin-top{
                margin-top: 150px;
            }
        }
        h4{
            font-family: "Poppins", sans-serif;
            @include font-size(28px);
            color: #215383;
            font-weight: 400;
            margin-bottom: 20px;
        }
        p{
            // visibility: hidden;
            // opacity: 0;
            color: #959595;
            @include font-size(24px);
        }
    }
    footer{
        background-image: none!important;
    }

    #launcher{
        display: none;
    }

    // .col-wrapper{
    //     border: 1px solid #000;
    //     padding: 20px;
    // }
    
    .back-splash{
        position: absolute;
        width: 120%;
        height: 150%;
        left: -10%;
        top: -25%;
        border-radius: 20px;        
        z-index: -1;
        display: none;
        &.color-one{
            background-color: #55acff;
        }
        &.color-two{
            background-color: #708dff;
        }
        &.pad-left{
            left: -5%;
        }
        &.pad-right{
            left: -15%;
        }
    }

    section.product{
        position: relative;        
        &.slide{
            min-height: 100vh;
        }
        h2{
            text-align: left;
            position: relative;
            // margin-bottom: 50px;
            @include max-screen($small){
                position: relative;
                text-align: center;
            }
        }    

        .img-wrapper{
            width: 100%;
            position: absolute;
            right: -15px;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: end;
            max-height: 100vh;
            overflow: hidden;
            z-index: -1;
            @include max-screen($small){
                position: relative;
                align-items: center;
                justify-content: center;
                right: inherit;
                top: inherit;
                display: flex;
                width: 100vw;
            }            
            @include max-screen($xsmall){
                margin-top: 50px;
                height: 500px;
            }
            // @include max-screen($small){
            //     position: relative;                
            //     right: inherit;
            //     top: -100px;
            //     justify-content: flex-start;
            //     transform: rotate(90deg);
            //     overflow: inherit;
            // }    
            // @include max-screen($xsmall){
            //     top: -150px;
            // }
            .section{                
                ul{                    
                    img{
                        width: 220px;
                        margin: 10px;
                        @include min-screen($large){
                            width: 250px;
                        }
                        @include max-screen($xsmall){
                            width: 200px;
                        }
                        @include max-screen($xxsmall){
                            width: 150px;
                        }
                        // @include max-screen($medium){
                        //     width: 200px;
                        // }
                        // @include max-screen($small){
                        //     width: 350px;
                        // }
                        // @include max-screen($xsmall){
                        //     width: 350px;
                        // }
                    }
                }                
            }
        }        

        &.video-section{
            .row{
                justify-content: center;
                align-items: center;
                h3{
                    margin-top: 0;
                }
                .video-container{
                    width: 120%;
                    position: relative;            
                    left: -20%;
                    @include max-screen($small){
                        left: 0;
                        width: 100%;
                    }
                    img{
                        width: 100%;
                    }
                    video{
                        position: absolute;
                        left: 12%;
                        top: 10%;
                        width: 76%;
                        height: 78%;                
                        object-fit: cover;
                    }
                    
                }
            }
        }        
    }    

    section.income{
        h2{
            margin-top: 0;
        }
        h3{
            margin: 50px 0 0;
            text-align: center;
        }
        h4{
            text-align: center;
            margin-bottom: 50px;
        }
        .corp{
            display: flex;
            align-items: flex-start;
            h4{
                line-height: 1.3;                
                @include font-size(20px);     
            }                               
            .corporate-graph{
                position: relative;
                width: 100%;
                height: 500px;
                // margin: 50px 0 10px;
                // width: 100%;
                // height: 500px;                
                .corporate-graph-content{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    #pie{
                        // position: absolute; 
                        height: 500px;
                        circle{
                            stroke: #215383;
                            stroke-linecap: round;
                        }
                    }
                    .counter{
                        position: absolute;
                        height: 100%;    
                        display: flex;
                        align-items: center;
                        span{
                            @include font-size(28px);
                            &.value{
                                @include font-size(54px);
                            }
                            &.sign{
                                margin-top: 10px;
                            } 
                        }
                    }
                }
            }
        }
        .ret{
            margin-top: 100px;
            align-items: center;
            .retail-funds-graph{
                margin: 50px 0 10px;
                width: 100%;
                height: 500px;                
            }
            h4{
                margin-top: 20px;
                line-height: 1.3;
                @include font-size(20px);
            }
        }        
    }

    section.banking{
        justify-content: flex-start;        
        .row{
            margin: 50px 0;
        }
        h2{
            margin-top: 0;
        }
        h3{
            text-align: center;
        }        
        .column{
            @include max-screen($small){
                margin: 50px 0;
            }                
            h4, p{
                mix-blend-mode: normal;
            }            
        }
        .col-wrapper{
            position: relative;
            padding: 20px;
            height: 100%;
            background-color: #f1f5f7;
            border-radius: 5px;                    
            h4{
                margin-top: 20px;
                text-align: center;
            }
            p{
                text-align: center;
            }
            svg,
            rect{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                fill: transparent;
                border-radius: 5px;
            }
            rect{
                stroke: #215383;
                stroke-width: 2;
            }
        }

        .counter-wrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                @include font-size(24px);
                line-height: 22px;                
            }
            span{
                @include font-size(34px);
                &.sign{
                    margin-right: 10px;
                }
            }
            .counter{
                width: 26%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                // width: 125px;
            }
            .counter-content{
                width: 65%;
            }
        }
        .col-content{
            margin-bottom: 20px;
            p{
                line-height: 1.4;
            }            
        }

        .image-wrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 100%;
                height: auto;
                margin: 0 auto;
                max-width: 300px;
                margin-top: 100px;
            }
        }
    }
    section.team{
        .row{
            align-items: center;
        }
        img{
            width: 100%;
            height: 120%;
            object-fit: cover;
        }

        .swiper-pagination{
            display: flex;
            align-items: center;
            justify-content: center;
            .swiper-pagination-bullet{
                width: 10px;
                height: 10px;
                transition: all .3s $ease1;
                &.swiper-pagination-bullet-active{
                    width: 7px;
                    height: 7px;
                    background: #215383;
                }
            }
        }        
    }
}