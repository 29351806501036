.page-template-page-pricing{
    .bg{
        img{
            height: 915px;
            max-height: 915px;
            width: 100%;                
            @media (max-width: $small) {
                object-fit: cover;
            }
        }
    }
    .hero{
        position: relative;
        height: 100%;        
        &>.row{            
            padding-top: 200px!important;
            position: relative;
            @media (max-width: $small) {
                padding-top: 150px!important;
            }
            .short-grid{
                @media (max-width: 1280px) {
                    padding: 0 2.5rem!important;
                }
                @media (max-width: $small) {
                    padding: 0!important;
                }
            }
        }        
        .social{
            @media (max-width: $small) {
                display: none;
            }
        }
        h1{
            font-family: 'Nunito', sans-serif;
            @include font-size(18px);
            font-weight: 600;
            color: #fff;
            margin-bottom: 0;
            text-transform: inherit;
            display: none;
            @media (max-width: $small) {
                text-align: center;
            }
        }
        h2{
            color: #fff;
            font-family: 'Poppins', sans-serif;
            font-weight: 900;
            @include font-size(32px);
            margin-bottom: 20px;
            text-transform: uppercase;
            @media (max-width: $small) {
                text-align: center;
            }
        }
        p{
            color: #fff;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            @include font-size(18px);
        }
    }
}