.main-header{    
    position: absolute;
    top: 0;
    width: 100%;
    margin: 20px 0;
    z-index: 99;
    @media (max-width: $small) {
        margin-bottom: 150px;
    }
    .row{
        align-items: center;
        .logo-wrapper{
            img{
                max-width: 200px;
                transition: all .5s $ease1;
                @media (max-width: $xsmall) {
                    max-width: 150px;
                }
                &:hover{                
                    transform: scale(1.05)!important;                
                }
            }
        }
    }
    nav.desktop-menu{
        @media (max-width: $small) {
            display: none;
        }
        .menu{            
            li{                          
                a{                                                                       
                    // transition: font-weight .3s cubic-bezier(.17,.67,.67,.47);                                                       
                }
            }
        }
    }

    .burguer-wrapper{
        display: none;
        justify-content: flex-end;
        width: 100%;  
        align-items: center;      
        @media (max-width: $small) {
            display: inline-flex;
        }
        .menu-button-container {
            height: 25px;
            width: 30px;
            cursor: pointer;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .menu-button{
                margin-top: 8px;
            }
            .menu-button,
            .menu-button::before,
            .menu-button::after {
                display: block;
                background-color: #fff;
                position: absolute;
                height: 4px;
                width: 30px;
                transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
                border-radius: 2px;
            }
            .menu-button::before {
                content: '';
                margin-top: -8px;
            }
            .menu-button::after {
                content: '';
                margin-top: 8px;
            }
        }        
        .menu-button-container.open .menu-button::before {
            margin-top: 0px;
            transform: rotate(405deg);
        }
        .menu-button-container.open .menu-button {
            background: rgba(255, 255, 255, 0);
        }
        .menu-button-container.open .menu-button::after {
            margin-top: 0px;
            transform: rotate(-405deg);
        }
    }        
}

.mobile-menu{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #215383;    
    opacity: 0;
    visibility: hidden;
    transform: translateX(100%);
    z-index: 9;    
    padding: 100px 0;
    height: 100vh;
    &.open{
        opacity: 1;
        visibility: visible;
        overflow: visible;
    }
    // @media (max-width: $xsmall) {
    //     opacity: 1;
    //     visibility: visible;
    //     transform: translateX(0);
    // }
    .search-bar{
        @include max-screen($small){            
            display: flex;
            // position: absolute; 
            // bottom: 50px;
            max-width: 90%;
            justify-content: center;
            margin-top: 50px;
            display: none;
        }        
        label.search{
            width: auto!important;
            visibility: visible!important;
            margin-right: 10px;
        }

    }    
    .first-layer{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #216282;
        opacity: .5;
    }    

    nav{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding-top: 100px;
        @media (max-height: 515px){
            padding-top: 100px;
        }

        .menu{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;                
            li{
                margin: 5px 0;                
                a{                        
                    @include font-size(40px);
                }
                &.log-in{
                    a{
                        background: #fff;
                        color: #216282;
                        padding: 5px 15px;
                        border-radius: 25px;
                        display: block;
                        border: 2px solid transparent;
                    }                   
                    &.sign-up{
                        a{
                            background: transparent;
                            color: #fff;
                            border: 2px solid #fff;
                        }
                    } 
                }
            }
            
        }            
    }
    .social{
        top: auto!important;        
    }
    
}