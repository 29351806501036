.learn{
    position: relative;
    padding: 50px 0;
    .bg{
        position: absolute;
        width: 100%; 
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        img{
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    
    h2{
        text-align: center;
        color: #FFF;

    }
    .categories-wrapper{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-template-columns: 65% 35%;
        @include max-screen(1200px){
            grid-template-columns: 60% 40%;
        }
        @include max-screen($xsmall){
            grid-template-columns: 100%;
        }

        // &:first-child{
        //     margin-right: 20px;
        // }
        // &:nth-child(2){
        //     margin-bottom: 20px;
        //     margin-left: 20px;
        // }        
        // &:nth-child(3){
        //     margin-top: 20px;
        //     margin-left: 20px;
        // }
        .cat-wrapper{
            background-color: #fff;
            // margin: 20px;
            border-radius: 16px;
            transition: all .5s $ease1;
            @include max-screen($xsmall){
                margin: 20px 0;
            }
            &:hover{
                transform: scale(1.01)!important;
            }
            a{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 40px;
                height: 100%;
                @include max-screen(1200px){
                    padding: 20px;
                }
                @include max-screen(450px){
                    flex-direction: column;
                }
                &:hover .cat-content span{
                    transform: translateX(10px);
                }

                .cat-content{
                    width: 50%;
                    @include max-screen(1200px){
                        width: auto;
                    }
                    &.img{
                        width: 30%;
                        @include max-screen(1200px){
                            width: auto;
                        }
                    }
                    img{
                        width: 140px;
                        @include max-screen(450px){
                            margin-top: 20px;
                        }
                    }
                    h5{
                        margin-bottom: 20px;
                        color: #216282;
                        font-weight: 700;
                        span{
                            margin-left: 10px;
                            display: inline-flex;
                            transition: all .5s $ease1;
                        }
                    }                    
                }   
            }
            
            &:nth-of-type(1){
                margin-right: 20px;
                margin-bottom: 20px;
                @include max-screen($xsmall){
                    margin-right: auto;
                    margin-bottom: auto;
                }
            }
            &:nth-of-type(2){
                grid-row: 3/1;
                grid-column: 2/2;                    
                margin-left: 20px;

                @include max-screen($xsmall){
                    grid-row: inherit;
                    grid-column: inherit;   
                    margin-left: auto;   
                } 
                
                a{
                    flex-direction: column;
                    justify-content: center;
                    @include max-screen($xsmall){
                        flex-direction: row;
                    }
                    @include max-screen(450px){
                        flex-direction: column;
                    }
                }
                .cat-content{
                    width: 100%;
                    @include max-screen($xsmall){
                        width: auto;
                    }
                    &.img{
                        width: 100%;
                        text-align: center;
                        @include max-screen($xsmall){
                            text-align: left;
                            width: auto;
                        }
                    }
                    &:first-of-type{
                        order: 2;
                        @include max-screen($xsmall){
                            order: inherit;
                        }
                    }                    
                    img{
                        margin-bottom: 50px;
                        @include max-screen($xsmall){
                            margin-bottom: 0;
                        }
                    }
                    
                }
            }         
            &:nth-of-type(3){
                margin-top: 20px;
                margin-right: 20px;       
                @include max-screen($xsmall){
                    margin-top: auto;
                    margin-right: auto;
                }           
            }      
        }        
    }
    .btns{
        button{
            margin: 40px auto 0 auto;
        }
    }    
}