.page-template-page-help,
.tax-help_categories,
.search,
.single-help{
    .help-wrapper{
        padding-top: 100px;
        .content#help-content{
            @media (max-width: $small) {
                margin-top: 50px;
            }
            h2{
                @include font-size(28px);                
            }
            .pricing-blocks{
                .title-wrapper{
                    h3{
                        @include font-size(20px); 
                    }
                }
            }            
        }
    }
}