.single-blog{
    .post-content{
        .text-block{
            margin: 50px 0;

            p,
            p span{
                font-family: 'Nunito', sans-serif;
                @include font-size(18px);
                font-weight: 400;
                color: #556987;
                margin-bottom: 20px;
                line-height: 1.3em;
                em{
                    font-style: italic;
                }
                strong{
                    font-weight: bold;
                }
            }
            ul{
                padding-left: 40px;
                margin-bottom: 20px;
                li{
                    font-family: 'Nunito', sans-serif;
                    @include font-size(18px);
                    line-height: 1.3em;
                    font-weight: 400;
                    color: #556987;
                    list-style: disc;
                    list-style-position: outside;
                    margin-bottom: 10px;
                }
            }

            blockquote{
                position: relative;
                padding-left: 40px;
                &:before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 2px;
                    height: 100%;
                    background-color: #2177AB;
                }    
            }
        }        
    }
}