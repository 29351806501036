.page-template-page-blog,
.page-template-page-help,
.tax-categories,
.tax-help_categories,
.search,
.single-help{
    .hero{
        min-height: 460px;
        background: rgb(255,255,255);
        background: linear-gradient(260deg, rgba(72, 161, 243, 0.818) 0%, rgba(33,83,131,1) 58%);
        display: flex;
        align-items: center;   
        margin-bottom: 0;
        @include max-screen($xsmall){
            min-height: 260px;
        }
        .watermark{
            top: 400px;
            left: auto;
            right: -30%;
        }
        .row{
            width: 100%;

            h1{
                font-family: 'Nunito', sans-serif;
                @include font-size(18px);
                font-weight: 600;
                color: #fff;
                margin-bottom: 0;
                margin-top: 50px;
            }
            h2{
                color: #fff;
                font-family: 'Poppins', sans-serif;
                font-weight: 900;
                @include font-size(32px);
            }
        }
    }
}