.page-template-page-pricing,
.page-template-page-help,
.tax-help_categories,
.search,
.single-help{ 
    .watermark{
        right: -30%;
        left: auto;
        top: 0;
    }
    .pricing-blocks{
        .accordeon-wrapper{
            width: 100%;
            background: #FAFAFA;
            border-radius: 15px;            
            pointer-events: auto;       
            cursor: pointer; 
            margin-bottom: 20px;
            padding: 5px 30px;
            transition: all .5s $ease1;

            &:hover{
                transform: scale(1.01)!important;
            }
            
            .title-wrapper{
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: center;
                padding: 20px 0;
                pointer-events: all;
                .content{
                    pointer-events: none;
                }
    
                h3{
                    font-family: 'Nunito', sans-serif;
                    @include font-size(16px);
                    font-weight: 600;
                    color: #215383;                              
                    pointer-events: none;
                    @media (max-width: $medium) {
                        text-align: left;
                    }
                }
                .arrow{             
                      pointer-events: none;
                }
            }        
    
            .accordeon-content-wrapper{
                display: flex;                
                justify-content: flex-start;
                flex-direction: column;
                // height: 0;
                overflow: hidden;            
                // pointer-events: none;                
                cursor: default;
                span.note{
                    padding: 10px;
                    background: #eee;
                    margin: 10px 0;
                    p{
                        font-family: 'Nunito', sans-serif;
                        @include font-size(14px);
                        font-weight: 400;
                        color: #2C2C2C;
                    }
                }
                .accordeon-col{
                    display: flex;                    
                    align-items: center;                                                            

                    flex-direction: row;
                    width: 100%;
                    justify-content: space-between;
                    border: none;
                    border-bottom: 1px solid #CDCDCD;
                    &:last-of-type{
                        border: none;
                    }
                    .title{
                        display: flex;
                        align-items: center;
                        justify-content: center;                        
                        height: 100%;                        
                        // font-family: 'Nunito', sans-serif;
                        // @include font-size(14px);                        
                        
                        min-height: 50px;
                        text-align: center;
                        padding:10px 0;
                        background-color: transparent;                        
                        width: auto;
                        h3,p{
                            color: #2C2C2C;
                            font-family: 'Nunito', sans-serif;
                            @include font-size(16px);
                            font-weight: 400;
                            text-align: left;
                            line-height: 1em;
                            @media (max-width: $xxsmall) {
                                @include font-size(12px)
                            }
                        }   
                    }
                    .value{           
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        padding: 10px 0 10px 20px;
                        height: 100%;
                        flex-direction: column;
                        p{
                            font-family: 'Nunito', sans-serif;
                            @include font-size(16px);
                            line-height: 1em;
                            font-weight: 400;
                            color: #2C2C2C;
                            @media (max-width: $xxsmall) {
                                @include font-size(12px)
                            }
                        }
                    }
                }
            }        
        }
    }
}