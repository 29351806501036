.page-template-page-blog,
.tax-categories{
    .video{
      display: none;
        .videos-wrapper{            
            display: grid;
            // grid-template-columns: repeat(2, 1fr);
            // grid-auto-rows: auto;
            grid-template-columns: 65% 35%;
            grid-template-areas:
            "video video-link1"
            "video video-link2";            
            @include max-screen($xsmall){
                grid-template-areas:
                    "video"
                    "video-link1"
                    " video-link2";     
                grid-template-columns: 100%;
            }  
            
            .video{
                grid-area: video;
                margin-right: 60px;
                @include max-screen($small){
                    margin-right: 20px;
                }
                iframe{
                    width: 100%;                    
                    height: 470px;
                    object-fit: cover;
                    border-radius: 30px;
                    @include max-screen($small){
                        height: 300px;
                    }
                }
            }
            .video-link1{                
                grid-area: video-link1;
                margin-left: 20px;
                // height: 145px;
            }
            .video-link2{
                grid-area: video-link2;
                margin-left: 20px;                
                // margin-top: -75px;
            }
            .grid-element{
                position: relative;
                background-color: transparent;                                
                // margin: 20px;                                            
                overflow: hidden;
                @include max-screen($xsmall){
                    margin: 20px 0;
                }

                .video-link-wrapper{                    
                    position: relative;
                    &:hover h3 span{
                        transform: translateX(10px);
                    }
                    .img-wrapper{
                        position: relative;
                        height: 145px;
                        width: 100%;
                        border-radius: 19px;
                        overflow: hidden;
                        img{
                            object-fit: cover;
                            width: 100%;                        
                            position: absolute;
                            height: 100%;
                            left: 0;
                            top: 0;
                        }
                    }    
                    h3{
                        font-family: 'Poppins', sans-serif;
                        font-weight: 700;
                        @include font-size(20px);
                        color: #215383;
                        margin-top: 20px;
                        span{
                            margin-left: 10px;
                            transition: all .5s $ease1;
                            position: relative;
                            display: inline-block;
                        }
                    }                
                    
                }
            }
        }
    }
}