.page-template-default,
.page-template-page-signup{
  .main-info{
    margin-top: 300px;
    @media (max-width:$xlarge){
      margin-top: 350px;
    }
    @media (max-width:$small){
      text-align: center;
    }
    ul{
      max-width: 90%;
      @media (max-width:$medium){
        max-width: 100%;
      }
    }
    p,li{
      font-family: 'Nunito', sans-serif!important;
      font-weight: 400;
      line-height: 1.7em;
      text-align: left;
      color: #09427A;
    }
    li{
      list-style-type: disc; 
      list-style-position: outside;
      color: #09427A;
      line-height: 1.4;
    }
    h2{
      text-transform: uppercase;
      margin: 20px 0 10px;
    }
    h3{
      font-family: 'Nunito', sans-serif;
      color: #09427A;
      font-weight: 600;
      @include font-size(28px);      
      margin: 20px 0 10px;
    }
    h4{
      font-family: 'Nunito', sans-serif;
      color: #09427A;
      font-weight: 600;
      @include font-size(18px);      
    }    
    quote{
      font-family: 'Nunito', sans-serif;
      color: #000;
      font-weight: 600;
      @include font-size(18px);      
      font-style: italic;
      display: block;
      margin-top: 20px;
    }
    .flex {
      display: inline-flex;
    }
    .flex-row{
      flex-direction: row;
    }
    .flex-col{
      flex-direction: column;
    }
    .justify-center{
      justify-content: center;
    }
    .justify-start{
      justify-content: start;
    }
    .items-center{
      align-items: center;
    }
    .items-start{
      align-items: start;
    }
    img{
      border-radius: 50%;
      max-width: 155px;      
    }
    .text-center{
      text-align: center;
      width: 100%;
      margin-top: 10px;
    }

    .form-wrapper{      
      form{
        display: flex;
        flex-direction: column;
        position: relative;    
        
        .input-field{
            border-radius: 10px;
            border: none;
            margin-bottom: 20px;
            outline: none;
            padding: 14px;
            @include font-size(12px);
            font-family: "Poppins", sans-serif;
            font-weight: 400;                        
            color: #000;
            border: 1px solid rgba(#09427A, 0.5);
        }
        
        textarea{
            height: 80px;       
            resize: none;                 
        }
        button[type="submit"]{
          font-family: "Poppins", sans-serif;
          font-size: 1.25rem;
          font-weight: 400;
          padding: 0px 0;
          cursor: pointer;
          width: 60%;
          margin: 0 auto;
          border-radius: 4px;
          border: none;        
          border: 1px solid rgba(#09427A, 0.5);
          margin-top: 20px;
          span{
              pointer-events: none;
          }
        }
        label{
          font-family: "Poppins", sans-serif;
          font-weight: 300;
          @include font-size(12px);
          line-height: 1.2;
          display: flex;   
          justify-content: flex-start;      
        }
        p.label{
          color: #000;
          @include font-size(12px);
          line-height: 1.2;
          a{
            @include font-size(12px);
            display: inline-block;
          }
        }
        input[type="checkbox"] {          
          appearance: none;
          background-color: #fff;
          margin: 0;
          font: inherit;
          color: currentColor;
          width: 20px;
          height: 20px;
          min-width: 20px;
          min-height: 20px;
          border: 1px solid rgba(#09427A, 0.5);
          border-radius: 0.15em;
          transform: translateY(-0.075em);
          display: grid;
          place-content: center;
          margin-right: 10px;
        }        
        input[type="checkbox"]::before {
          content: "";
          width: 10px;
          height: 10px;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          box-shadow: inset 1em 1em #09427A;          
        }
        input[type="checkbox"]:checked::before {
          transform: scale(1);
        }
      }
      .response{
          opacity: 0;
          display: block;
          @include font-size(18px);
          // font-family: 'Gill Sans';
          font-weight: 500;                        
          color: #09427A;
          margin-top: 20px;
          text-align: center;
          width: 100%;
      }
    }
  }
}