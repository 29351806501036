.single-blog{
    .post-content{
        .copy-feedback{
            text-align: left!important;
        }
        .social-share{
            text-align: center;            
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin: 55px auto 0;
            .social{
                position: relative;
                display: flex;
                flex-direction: row;
                right: auto;
                .social-wrapper{
                    margin-bottom: 0;
                    margin: 0 10px;
                }
                img{
                    filter: brightness(0.5) invert(1);
                }                
            }
            button{
                background-color: transparent;
                border: 1px solid #808080;
                box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
                border-radius: 6px;                
                height: 40px;
                width: auto;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin: 0 10px;

                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                @include font-size(14px);
                color: #808080;     

                transition: all .5s $ease1;

                &:first-of-type{
                    margin-left: 0;
                }

                a{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                svg{
                    height: 20px;
                }
                
                &:hover{
                    // transform: translateY(-5px);
                    // padding: 10px 18px;
                }
                
                &.socials{
                    width: 40px;
                }
                &.copy{
                    padding: 0 16px;
                    &:hover{
                        transform: scale(1.05)!important;
                    }
                    svg{
                        margin-right: 5px;
                    }
                }
            }            
        }
        .copy-feedback{
            display: block;
            color: green;
            opacity: 0;
            visibility: hidden;

            width: 100%;
            text-align: center;
            display: block;
            margin-top: 10px;
        }
    }
}