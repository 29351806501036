.single-blog {
    .post-content {
        .image-block {
            margin: 50px auto;
            max-width: 80%;

            @media (max-width: $small) {
                max-width: 100%;
            }

            img {
                height: auto;
                width: 100%;
                object-fit: contain;
            }

            span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                @include font-size(18px);
                color: #8896AB;
                display: block;
            }
        }
    }
}