
.page-template-page-about-us,
.page-template-page-disclosures,
.page-template-default,
.page-template-page-signup{
    .hero{
        // margin-bottom: 0;
        width: 100%;
        position: relative;
        // display: flex;
        // justify-content: center;
        // align-items: center;                
        // height: 915px;
        text-align: center;
        margin-bottom: 250px;
        @media (max-width: $small) {
            margin-bottom: 100px;
        }
        .row{
            position: relative;
            justify-content: center;
            .columns{
                width: 100%;
            }
        }
        
        h1{
            font-family: 'Nunito', sans-serif;
            color: #fff;
            font-weight: 600;
            @include font-size(18px);
            margin-top: 175px;
            margin-bottom: 5px;
            @media (max-width: $small) {
                margin-top: 140px;
            }
        }
        h2{
            font-family: 'Poppins', sans-serif;
            font-weight: 900;
            @include font-size(32px);
            line-height: 45px;
            color: #fff;
        }
        .sub-title{
            text-align: center;
        }
        .social{
            top: 150px;
            @media (max-width: $small) {
                display: none;
            }
        }
        .bg{
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            display: flex;
            align-items: flex-start;
            justify-content: center;
        
            img{
                width: 100%;
                height: auto;
                height: 600px;
                @media (max-width: $small) {
                    height: 595px;
                    object-fit: cover;
                    
                }
            }
        }
    }
    .images-container{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        // margin-top: 150px;
        
        img{
            @media (max-width: $small) {
                height: 600px;
            }            
            @media (max-width: $xsmall) {
                height: 500px;
            }
            &:nth-child(2){
                margin-right: -435px;
                margin-left: -390px;
                margin-top: -110px;     
                z-index: 1;       
                @media (max-width: $small) {
                    margin-right: -300px;
                    margin-left: -275px;
                }
            }
            
        }
    }    
}