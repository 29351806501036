.how-it-works{
    .title{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        h2{
            text-align: center;            
        }
        p{
            text-align: center;
        }        
    }
    .img-layout{
        h2{
            text-align: left;
        }
        p{
            text-align: left;
        }
        @media (max-width: $small) {
            h2,p{
                text-align: center;
            }
            p{
                margin-bottom: 40px;
            }
        }
        .side-img{
            text-align: center;
            img{
                width: auto;
                object-fit: cover;
                height: 100%;
                max-height: 600px;
            }            
        }
    }    
    .cols{
        margin-bottom: 40px;        

        img{
            height: 110px;
            width: auto;
        }
        .column{
            &:last-of-type .content-wrapper{
                @media (max-width: $small) {
                    margin-bottom: 0px;
                }    
            }
        }
        .content-wrapper{
            padding: 0 10px;            
            @media (max-width: $small) {
                margin-bottom: 100px;
            }
            @media (max-width: $xsmall) {
                padding: 0;
            }
            
            .content{
                background: rgba(250, 250, 250, 0.55);
                border-radius: 15px;
                padding: 10px 30px;

                img{
                    margin-top: -65px;
                    margin-bottom: 10px;
                }
                h5{
                    font-family: 'Inter', sans-serif;
                    font-weight: 700;
                    @include font-size(16px);
                    line-height: 1.2;
                    margin-bottom: 10px;
                }
                p{
                    position: relative;                    
                }
            }                        
        }
    }
    .btns{
        button{
            margin: 0 auto;
        }
    }     
    
}