.home{
    // background-color: #000;
    // color: #fff;        

    .how-popup{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0,0,0,.5);                
        .popup-content{
            position: relative;
            background: #FFF;            
            padding: 50px;
            border-radius: 15px;       
            max-width: 50%;
            overflow: hidden;
            @media (max-width:$medium) {
                max-width: 90%;
            }
            @media (max-width:$xsmall) {
                padding: 20px;
            }
            .close{
                position: absolute;
                right: 0;
                top: 10px;
                width: 20px;                
                height: 20px;
                cursor: pointer;
                span{
                    background: #215383;
                    width: 2px;
                    height: 100%;
                    display: block;
                    position: absolute;
                    &:first-of-type{
                        transform: rotate(45deg);                    
                    }
                    &:last-of-type{
                        transform: rotate(-45deg);                    
                    }
                }                
            }
        }
    }
}
