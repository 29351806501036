.page-template-page-disclosures{
    .hero{
        margin-bottom: 100px;
        h1{
            font-family: 'Poppins', sans-serif;
            font-weight: 900;
            @include font-size(32px);
            line-height: 45px;
            color: #fff;
        }        
    }
    .disclosures{        
        a{
            display: block;
            margin: 20px 0;
            width: 100%;
            &:hover{
                font-weight: 600;
            }
        }
        .files-wrapper{
            
            
        }
    }
}
a{
  display: block;
  color: #215383;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  // padding-left: 20px;
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  
  &:hover h4{
      transform: translateX(10px) !important;
  }
}
.disc-group{
  background: #FAFAFA;
  border-radius: 15px;                
  margin-bottom: 20px;
  padding: 24px 30px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);                
  color: #215383;
  box-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.12);
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  .files-list{
      // height: 0;
      overflow: hidden;
  }
  h4{
      color: #215383;
      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }                
}
