.social{
    position: absolute;
    right: -50px;
    @media (max-width: $medium) {
        right: 20px;
    }
    @media (max-width: $small) {
        top: 100%;
    }
    .social-wrapper{
        margin-bottom: 30px;
        &:last-of-type{
            margin-bottom: 0;
        }
        img{
            width: 22px;
            height: auto; 
            transition: all .3s $ease1;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
}