.page-template-page-investors{
    .investors-load-screen{
        
        min-height: 100vh;
        width: 100vw;
        position: fixed;        
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;   
        justify-content: center;
        margin-bottom: 0;
        visibility: visible;
        z-index: 999;
        // display: none;
        svg{
            width: 200px;
            path{
                stroke-width: .06px;
                stroke: white;
                fill: transparent;
            }
        }
        h1{margin-top: 50px;}
        .screen{
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgb(255,255,255);
            background: linear-gradient(260deg, rgba(72, 161, 243, 0.818) 0%, rgba(33,83,131,1) 58%);
        }
        .mask{
            position: absolute;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            background: rgba(72, 161, 243, 1);
            z-index: -1;
        }
    }
}