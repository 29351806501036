.latest-posts{
    position: relative;
    .latest-posts-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 40px;
        width: 100%;
        @include max-screen($xsmall){
            grid-template-columns: 1fr;
        }  

        .latest-post-wrapper{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            .zoom-img{                
                overflow: inherit;
                div{
                    border-radius: 6px;
                }
                
            }            
            img{
                width: 100%;
                height: 270px;
                object-fit: cover;                
                border-radius: 6px;   
            }
            span.date{
                margin-top: 10px;
                display: block;
                @include font-size(14px);
            }
            span.author{
                margin-top: 10px;
                display: block;
                @include font-size(14px);
            }
            h3{
                font-family: 'Poppins', sans-serif;
                font-weight: 700;
                @include font-size(24px);
                color: #215383;
                margin: 20px 0;
            }
            p{
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                @include font-size(18px);
                color: #8896AB;
            }
        }
    }
}