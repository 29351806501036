.page-template-page-help,
.tax-help_categories,
.search,
.single-help{
    .content-wrapper{
        position: relative;
        img{
            margin: 20px 0;
        }
        .aligncenter{
            width: 100%;
            height: auto;
            object-fit: contain;
            object-position: center;
        }
        .alignleft{
            width: 100%;
            height: auto;
            object-fit: contain;
            object-position: left;
        }
        .alignright{
            width: 100%;
            height: auto;
            object-fit: contain;
            object-position: right;
        }
        span{
            color: #000;
            font-weight: inherit;
        }
        
        ul{
            li{
                list-style: disc;
                display: list-item;
                margin-left: 2em;       
                font-family: 'Nunito', sans-serif;
                font-weight: 400;
                line-height: 1.7em;
                text-align: left;
            }
        }
        ol{                    
            li{
                list-style: decimal;
                display: list-item;
                margin-left: 2em;
                font-family: 'Nunito', sans-serif;
                font-weight: 400;
                line-height: 1.7em;
                text-align: left;
            }
        }
        a,
        a em,
        a em span,
        a span,
        span a{
            text-decoration: none;
            color: #0000FF!important;
            font-size: inherit;
        }
        blockquote{            
            position: relative;
            padding-left: 40px;
            margin: 50px 0;            
            &:before{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 2px;
                height: 100%;
                background-color: #2177AB;
            }

            p{
                font-family: 'Nunito', sans-serif;
                @include font-size(18px);
                line-height: 1.3em;
                font-weight: 400;
                color: #333F51;
            }            
            .source{
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                @include font-size(18px);
                color: #8896AB;
                margin-top: 20px;
                display: block;
            }
        }
    
        .mask{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            background-color: rgba(255,255,255,.8);
            opacity: 0;
            visibility: hidden;
            .lds-ring{
                display: inline-block;
                position: relative;
                width: 80px;
                height: 80px;
                
                div {
                    box-sizing: border-box;
                    display: block;
                    position: absolute;
                    width: 64px;
                    height: 64px;
                    margin: 8px;
                    border: 8px solid #09427A;
                    border-radius: 50%;
                    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                    border-color: #09427A transparent transparent transparent;
                }
                &:nth-child(1) {
                    animation-delay: -0.45s;
                }
                div:nth-child(2) {
                    animation-delay: -0.3s;
                }
                div:nth-child(3) {
                    animation-delay: -0.15s;
                }                                                    
            }
        }
    }
}