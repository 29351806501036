.page-template-page-help,
.tax-help_categories,
.search,
.single-help{
    .search-container{
        width: 100%;
        .search-bar{
            width: 100%;
            display: block;
            .search-form{
                width: 100%;
                max-width: 100%;

                form{
                    display: inline-flex;
                    width: 100%;
                    label.search{
                        width: auto;
                        visibility: visible;
                        opacity: 1;
                        width: 100%;
                    }
                }
            }
        }        
    }
}