.page-template-page-help,
.tax-help_categories,
.search,
.single-help{
    .sidebar{
        h2{
            position: relative;
            margin-bottom: 20px;
            // font-family: "Nunito", sans-serif;
            // @include font-size(25px);
            // font-weight: 600;
            // color: #fff;
            @media (max-width: $small) {
                text-align: left;
            }
            span{
                position: absolute;
                bottom: -5px;
                left: 0;
                width: 20%;
                height: 5px;
                background:#215383;                
            }
        }
        h5{            
            @include font-size(14px);
            // margin-right: 30px;
        }
        .cats-wrapper{
            margin-top: 50px;
            // display: none;
            // @media (min-width: $small) {
            //     display: block;
            // }
            .cat-wrapper{
                margin-bottom: 50px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                @media (max-width: $small) {
                    justify-content: start;
                    display: inline-flex;
                    background: #f5f5f5;
                    border-radius: 20px;
                    padding: 10px 20px;
                    margin: 0px 5px;
                    margin-bottom: 15px;
                    &:first-of-type{
                        margin-left: 0;
                    }
                    &:last-of-type{
                        margin-right: 0;
                    }
                }
                .arrow{
                    // position: absolute;
                    // right: 0;
                    margin-right: 30px;
                    pointer-events: none;
                    @media (max-width: $small) {
                        display: none;
                    }
                    svg{                        
                        transform: rotate(0deg);
                        transition: all .3s $ease1;                        
                        pointer-events: none;
                        @media (max-width: $small) {
                            transform: rotate(-90deg);
                        }
                        path{
                            fill: #000;
                        }
                    }
                }
                h5{
                    color: #000;                
                    @include font-size(22px);
                    padding-right: 20px;
                    width: 100%;
                    pointer-events: none;
                }
                &.active{
                                        
                    h5{
                        color: #215383;     
                        @media (max-width: $small) {
                            color: #fff;
                        }                   
                    }
                    svg{
                        transform: rotate(-90deg);
                        @media (max-width: $small) {
                            transform: rotate(0deg);
                        }
                        path{                            
                            fill: #215383;
                        }
                    }
                    @media (max-width: $small) {
                        background-color: #215383;
                    }
                    
                }
            }
            // &.mobile{
            //     display: none;
            //     @media (max-width: $small) {
            //         display: flex;
            //         position: absolute;
            //     }
            // }
        }        
        
    }
}