.page-template-page-blog,
.tax-categories{
    .posts-sec{
        position: relative;
        .cats{
            margin-bottom: 50px;     
            .cats-wrapper{
                position: relative;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 40px;
                margin-top: -10%;       
                
                @include max-screen($xsmall){
                    grid-template-columns: 1fr;
                }
        
                .cat-wrapper{
                    position: relative;
                    background-color: #EEEEEE;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;                
                    border-radius: 15px;
                    padding: 50px;
                    overflow: hidden;
                    cursor: pointer;
                    @include max-screen($xsmall){
                        padding: 10px;
                    }
                    &:before{                
                        content: ' ';
                        display: block;
                        width: 100%;
                        padding-top: 25%;
                        @include max-screen($xsmall){
                            padding-top: 0;
                        }
                    }
                    .mask{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: #09427A;
                        z-index: 0;
                        transform: translateY(-100%);
                    }
    
                    .content{
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: flex-end;
                        flex-direction: column; 
                        span{
                            font-family: 'Poppins', sans-serif;
                            font-weight: 900;
                            @include font-size(96px);
                            color: #09427A;
                            text-align: right;
                        }
                        h5{
                            font-weight: 900;
                            @include font-size(32px);
                            color: #09427A;
                            text-align: right;
                        }
                    }
    
                    &.active{
                        background-color: #09427A;
                        cursor: inherit;
                        .content{
                            h5{
                                color: #FFF;
                            }
                            span{
                                color: #fff;
                            }
                        }                    
                    }
                    
                }
            }
        }
        
        .posts{
            .posts-wrapper{
                position: relative;
                background-color: #09427A;
                border-radius: 15px;                
                padding: 25px 30px;                
                .mask{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 1;
                    background-color: rgba(255,255,255,.5);
                    opacity: 0;
                    visibility: hidden;
                    .lds-ring{
                        display: inline-block;
                        position: relative;
                        width: 80px;
                        height: 80px;
                        
                        div {
                            box-sizing: border-box;
                            display: block;
                            position: absolute;
                            width: 64px;
                            height: 64px;
                            margin: 8px;
                            border: 8px solid #09427A;
                            border-radius: 50%;
                            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                            border-color: #09427A transparent transparent transparent;
                        }
                        &:nth-child(1) {
                            animation-delay: -0.45s;
                        }
                        div:nth-child(2) {
                            animation-delay: -0.3s;
                        }
                        div:nth-child(3) {
                            animation-delay: -0.15s;
                        }                                                    
                    }
                }
                h3{
                    margin-bottom: 20px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 900;
                    @include font-size(20px);
                    color: #FFF;
                    text-transform: uppercase;
                }
                .posts-wrapper-grid{
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    gap: 40px;
                    width: 100%;      
                    @include max-screen($xsmall){
                        grid-template-columns: 1fr 1fr;
                    }  
                    @include max-screen(430px){
                        grid-template-columns: 1fr;
                    }  
                    @include max-screen($xxsmall){
                        grid-template-columns: 1fr;
                    }              
                    
                    .post-wrapper{
                        display: inline-grid;        
                        width: 100%;           
                        cursor: pointer;     
                        .zoom-img{
                            div{
                                border-radius: 6px;
                            }
                            
                        }
                        h5{
                            color: #fff;
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            @include font-size(20px);
                            margin-top: 20px;
                        }
                        img{
                            width: 100%;                                                     
                            height: 170px;
                            object-fit: cover;
                            border-radius: 6px;   
                        }
                    }
                }                
            }
        }
        .btns{
            button{
                margin: 20px 0 auto auto;
                cursor: pointer;
                .load-more.hide{
                    display: none;
                }
            }
        }        
    }
}