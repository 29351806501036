.breadcrumbs{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    margin-top: 30px;
    
    a,
    span{
        &:first-child{
            width: auto;
        }
        color: #000;                
        font-weight: 400;        
        height: 100%;
        line-height: 1.1em;
        @include max-screen($xsmall){
            text-overflow: ellipsis;
            overflow: hidden; 
            width: auto;         
            white-space: nowrap;
            width: 160px;
            &:first-child{
                min-width: 40px;
            }
        }
    }                     
    svg{
        margin: 0 10px;
        height: 9px;
        path{
            fill: #000;
        }
    }
}