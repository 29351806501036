@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Nunito:wght@400;600&family=Poppins:wght@400;500;600;700;900&family=Righteous&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.row {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
@media only screen and (min-width: 1919px) {
  .row {
    max-width: 103.75rem;
  }
}
@media only screen and (max-width: 1370px) {
  .row {
    padding-left: calc(1.375rem + 1.5vw);
    padding-right: calc(1.375rem + 1.5vw);
  }
}
@media only screen and (max-width: 1370px) and (min-width: 1200px) {
  .row {
    padding-left: 2.5rem;
  }
}
@media only screen and (max-width: 1370px) and (min-width: 1200px) {
  .row {
    padding-right: 2.5rem;
  }
}
@media only screen and (max-width: 767px) {
  .row {
    padding-left: 0.8333333333rem;
    padding-right: 0.8333333333rem;
  }
}

.row .row {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  padding-left: 0;
  padding-right: 0;
}

.row.expanded {
  max-width: none;
}

.row.collapse > .column,
.row.collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}

.row.is-collapse-child,
.row.collapse > .column > .row,
.row.collapse > .columns > .row {
  margin-left: 0;
  margin-right: 0;
}

.column,
.columns {
  -webkit-flex: 1 1 0px;
  -ms-flex: 1 1 0px;
  flex: 1 1 0px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  min-width: initial;
}

.column.row.row,
.row.row.columns {
  float: none;
  display: block;
}

.row .column.row.row,
.row .row.row.columns {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.shrink {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
}

.column.align-top,
.align-top.columns {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.column.align-bottom,
.align-bottom.columns {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.column.align-middle,
.align-middle.columns {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.column.align-stretch,
.align-stretch.columns {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.align-right {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.align-center {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.align-justify {
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.align-spaced {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.align-top {
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.align-self-top {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.align-bottom {
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.align-self-bottom {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.align-middle {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.align-self-middle {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.align-stretch {
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.align-self-stretch {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.xxlarge-1 {
  -webkit-flex: 0 0 6.25%;
  -ms-flex: 0 0 6.25%;
  flex: 0 0 6.25%;
  max-width: 6.25%;
}

.xxlarge-offset-0 {
  margin-left: 0%;
}

.xxlarge-order-1 {
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.xxlarge-up-1 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-1 > .column,
.xxlarge-up-1 > .columns {
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.xxlarge-2 {
  -webkit-flex: 0 0 12.5%;
  -ms-flex: 0 0 12.5%;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.xxlarge-offset-1 {
  margin-left: 6.25%;
}

.xxlarge-order-2 {
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}

.xxlarge-up-2 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-2 > .column,
.xxlarge-up-2 > .columns {
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.xxlarge-3 {
  -webkit-flex: 0 0 18.75%;
  -ms-flex: 0 0 18.75%;
  flex: 0 0 18.75%;
  max-width: 18.75%;
}

.xxlarge-offset-2 {
  margin-left: 12.5%;
}

.xxlarge-order-3 {
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
}

.xxlarge-up-3 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-3 > .column,
.xxlarge-up-3 > .columns {
  -webkit-flex: 0 0 33.3333333333%;
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.xxlarge-4 {
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.xxlarge-offset-3 {
  margin-left: 18.75%;
}

.xxlarge-order-4 {
  -webkit-order: 4;
  -ms-flex-order: 4;
  order: 4;
}

.xxlarge-up-4 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-4 > .column,
.xxlarge-up-4 > .columns {
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.xxlarge-5 {
  -webkit-flex: 0 0 31.25%;
  -ms-flex: 0 0 31.25%;
  flex: 0 0 31.25%;
  max-width: 31.25%;
}

.xxlarge-offset-4 {
  margin-left: 25%;
}

.xxlarge-order-5 {
  -webkit-order: 5;
  -ms-flex-order: 5;
  order: 5;
}

.xxlarge-up-5 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-5 > .column,
.xxlarge-up-5 > .columns {
  -webkit-flex: 0 0 20%;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.xxlarge-6 {
  -webkit-flex: 0 0 37.5%;
  -ms-flex: 0 0 37.5%;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}

.xxlarge-offset-5 {
  margin-left: 31.25%;
}

.xxlarge-order-6 {
  -webkit-order: 6;
  -ms-flex-order: 6;
  order: 6;
}

.xxlarge-up-6 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-6 > .column,
.xxlarge-up-6 > .columns {
  -webkit-flex: 0 0 16.6666666667%;
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.xxlarge-7 {
  -webkit-flex: 0 0 43.75%;
  -ms-flex: 0 0 43.75%;
  flex: 0 0 43.75%;
  max-width: 43.75%;
}

.xxlarge-offset-6 {
  margin-left: 37.5%;
}

.xxlarge-order-7 {
  -webkit-order: 7;
  -ms-flex-order: 7;
  order: 7;
}

.xxlarge-up-7 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-7 > .column,
.xxlarge-up-7 > .columns {
  -webkit-flex: 0 0 14.2857142857%;
  -ms-flex: 0 0 14.2857142857%;
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.xxlarge-8 {
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.xxlarge-offset-7 {
  margin-left: 43.75%;
}

.xxlarge-order-8 {
  -webkit-order: 8;
  -ms-flex-order: 8;
  order: 8;
}

.xxlarge-up-8 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-8 > .column,
.xxlarge-up-8 > .columns {
  -webkit-flex: 0 0 12.5%;
  -ms-flex: 0 0 12.5%;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.xxlarge-9 {
  -webkit-flex: 0 0 56.25%;
  -ms-flex: 0 0 56.25%;
  flex: 0 0 56.25%;
  max-width: 56.25%;
}

.xxlarge-offset-8 {
  margin-left: 50%;
}

.xxlarge-order-9 {
  -webkit-order: 9;
  -ms-flex-order: 9;
  order: 9;
}

.xxlarge-up-9 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-9 > .column,
.xxlarge-up-9 > .columns {
  -webkit-flex: 0 0 11.1111111111%;
  -ms-flex: 0 0 11.1111111111%;
  flex: 0 0 11.1111111111%;
  max-width: 11.1111111111%;
}

.xxlarge-10 {
  -webkit-flex: 0 0 62.5%;
  -ms-flex: 0 0 62.5%;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}

.xxlarge-offset-9 {
  margin-left: 56.25%;
}

.xxlarge-order-10 {
  -webkit-order: 10;
  -ms-flex-order: 10;
  order: 10;
}

.xxlarge-up-10 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-10 > .column,
.xxlarge-up-10 > .columns {
  -webkit-flex: 0 0 10%;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
  max-width: 10%;
}

.xxlarge-11 {
  -webkit-flex: 0 0 68.75%;
  -ms-flex: 0 0 68.75%;
  flex: 0 0 68.75%;
  max-width: 68.75%;
}

.xxlarge-offset-10 {
  margin-left: 62.5%;
}

.xxlarge-order-11 {
  -webkit-order: 11;
  -ms-flex-order: 11;
  order: 11;
}

.xxlarge-up-11 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-11 > .column,
.xxlarge-up-11 > .columns {
  -webkit-flex: 0 0 9.0909090909%;
  -ms-flex: 0 0 9.0909090909%;
  flex: 0 0 9.0909090909%;
  max-width: 9.0909090909%;
}

.xxlarge-12 {
  -webkit-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.xxlarge-offset-11 {
  margin-left: 68.75%;
}

.xxlarge-order-12 {
  -webkit-order: 12;
  -ms-flex-order: 12;
  order: 12;
}

.xxlarge-up-12 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-12 > .column,
.xxlarge-up-12 > .columns {
  -webkit-flex: 0 0 8.3333333333%;
  -ms-flex: 0 0 8.3333333333%;
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.xxlarge-13 {
  -webkit-flex: 0 0 81.25%;
  -ms-flex: 0 0 81.25%;
  flex: 0 0 81.25%;
  max-width: 81.25%;
}

.xxlarge-offset-12 {
  margin-left: 75%;
}

.xxlarge-order-13 {
  -webkit-order: 13;
  -ms-flex-order: 13;
  order: 13;
}

.xxlarge-up-13 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-13 > .column,
.xxlarge-up-13 > .columns {
  -webkit-flex: 0 0 7.6923076923%;
  -ms-flex: 0 0 7.6923076923%;
  flex: 0 0 7.6923076923%;
  max-width: 7.6923076923%;
}

.xxlarge-14 {
  -webkit-flex: 0 0 87.5%;
  -ms-flex: 0 0 87.5%;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}

.xxlarge-offset-13 {
  margin-left: 81.25%;
}

.xxlarge-order-14 {
  -webkit-order: 14;
  -ms-flex-order: 14;
  order: 14;
}

.xxlarge-up-14 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-14 > .column,
.xxlarge-up-14 > .columns {
  -webkit-flex: 0 0 7.1428571429%;
  -ms-flex: 0 0 7.1428571429%;
  flex: 0 0 7.1428571429%;
  max-width: 7.1428571429%;
}

.xxlarge-15 {
  -webkit-flex: 0 0 93.75%;
  -ms-flex: 0 0 93.75%;
  flex: 0 0 93.75%;
  max-width: 93.75%;
}

.xxlarge-offset-14 {
  margin-left: 87.5%;
}

.xxlarge-order-15 {
  -webkit-order: 15;
  -ms-flex-order: 15;
  order: 15;
}

.xxlarge-up-15 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-15 > .column,
.xxlarge-up-15 > .columns {
  -webkit-flex: 0 0 6.6666666667%;
  -ms-flex: 0 0 6.6666666667%;
  flex: 0 0 6.6666666667%;
  max-width: 6.6666666667%;
}

.xxlarge-16 {
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.xxlarge-offset-15 {
  margin-left: 93.75%;
}

.xxlarge-order-16 {
  -webkit-order: 16;
  -ms-flex-order: 16;
  order: 16;
}

.xxlarge-up-16 {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.xxlarge-up-16 > .column,
.xxlarge-up-16 > .columns {
  -webkit-flex: 0 0 6.25%;
  -ms-flex: 0 0 6.25%;
  flex: 0 0 6.25%;
  max-width: 6.25%;
}

.xxlarge-collapse {
  padding-left: 0;
  padding-right: 0;
}

.xxlarge-collapse > .column,
.xxlarge-collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}

.xxlarge-uncollapse > .column,
.xxlarge-uncollapse > .columns {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

@media screen and (max-width: 1919px) {
  .xlarge-1 {
    -webkit-flex: 0 0 6.25%;
    -ms-flex: 0 0 6.25%;
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }

  .xlarge-offset-0 {
    margin-left: 0%;
  }

  .xlarge-order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .xlarge-up-1 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-1 > .column,
.xlarge-up-1 > .columns {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .xlarge-2 {
    -webkit-flex: 0 0 12.5%;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .xlarge-offset-1 {
    margin-left: 6.25%;
  }

  .xlarge-order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .xlarge-up-2 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-2 > .column,
.xlarge-up-2 > .columns {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .xlarge-3 {
    -webkit-flex: 0 0 18.75%;
    -ms-flex: 0 0 18.75%;
    flex: 0 0 18.75%;
    max-width: 18.75%;
  }

  .xlarge-offset-2 {
    margin-left: 12.5%;
  }

  .xlarge-order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .xlarge-up-3 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-3 > .column,
.xlarge-up-3 > .columns {
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .xlarge-4 {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .xlarge-offset-3 {
    margin-left: 18.75%;
  }

  .xlarge-order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }

  .xlarge-up-4 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-4 > .column,
.xlarge-up-4 > .columns {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .xlarge-5 {
    -webkit-flex: 0 0 31.25%;
    -ms-flex: 0 0 31.25%;
    flex: 0 0 31.25%;
    max-width: 31.25%;
  }

  .xlarge-offset-4 {
    margin-left: 25%;
  }

  .xlarge-order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }

  .xlarge-up-5 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-5 > .column,
.xlarge-up-5 > .columns {
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .xlarge-6 {
    -webkit-flex: 0 0 37.5%;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .xlarge-offset-5 {
    margin-left: 31.25%;
  }

  .xlarge-order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }

  .xlarge-up-6 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-6 > .column,
.xlarge-up-6 > .columns {
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .xlarge-7 {
    -webkit-flex: 0 0 43.75%;
    -ms-flex: 0 0 43.75%;
    flex: 0 0 43.75%;
    max-width: 43.75%;
  }

  .xlarge-offset-6 {
    margin-left: 37.5%;
  }

  .xlarge-order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }

  .xlarge-up-7 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-7 > .column,
.xlarge-up-7 > .columns {
    -webkit-flex: 0 0 14.2857142857%;
    -ms-flex: 0 0 14.2857142857%;
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .xlarge-8 {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .xlarge-offset-7 {
    margin-left: 43.75%;
  }

  .xlarge-order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }

  .xlarge-up-8 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-8 > .column,
.xlarge-up-8 > .columns {
    -webkit-flex: 0 0 12.5%;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .xlarge-9 {
    -webkit-flex: 0 0 56.25%;
    -ms-flex: 0 0 56.25%;
    flex: 0 0 56.25%;
    max-width: 56.25%;
  }

  .xlarge-offset-8 {
    margin-left: 50%;
  }

  .xlarge-order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }

  .xlarge-up-9 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-9 > .column,
.xlarge-up-9 > .columns {
    -webkit-flex: 0 0 11.1111111111%;
    -ms-flex: 0 0 11.1111111111%;
    flex: 0 0 11.1111111111%;
    max-width: 11.1111111111%;
  }

  .xlarge-10 {
    -webkit-flex: 0 0 62.5%;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .xlarge-offset-9 {
    margin-left: 56.25%;
  }

  .xlarge-order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }

  .xlarge-up-10 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-10 > .column,
.xlarge-up-10 > .columns {
    -webkit-flex: 0 0 10%;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10%;
  }

  .xlarge-11 {
    -webkit-flex: 0 0 68.75%;
    -ms-flex: 0 0 68.75%;
    flex: 0 0 68.75%;
    max-width: 68.75%;
  }

  .xlarge-offset-10 {
    margin-left: 62.5%;
  }

  .xlarge-order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }

  .xlarge-up-11 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-11 > .column,
.xlarge-up-11 > .columns {
    -webkit-flex: 0 0 9.0909090909%;
    -ms-flex: 0 0 9.0909090909%;
    flex: 0 0 9.0909090909%;
    max-width: 9.0909090909%;
  }

  .xlarge-12 {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .xlarge-offset-11 {
    margin-left: 68.75%;
  }

  .xlarge-order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }

  .xlarge-up-12 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-12 > .column,
.xlarge-up-12 > .columns {
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .xlarge-13 {
    -webkit-flex: 0 0 81.25%;
    -ms-flex: 0 0 81.25%;
    flex: 0 0 81.25%;
    max-width: 81.25%;
  }

  .xlarge-offset-12 {
    margin-left: 75%;
  }

  .xlarge-order-13 {
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13;
  }

  .xlarge-up-13 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-13 > .column,
.xlarge-up-13 > .columns {
    -webkit-flex: 0 0 7.6923076923%;
    -ms-flex: 0 0 7.6923076923%;
    flex: 0 0 7.6923076923%;
    max-width: 7.6923076923%;
  }

  .xlarge-14 {
    -webkit-flex: 0 0 87.5%;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .xlarge-offset-13 {
    margin-left: 81.25%;
  }

  .xlarge-order-14 {
    -webkit-order: 14;
    -ms-flex-order: 14;
    order: 14;
  }

  .xlarge-up-14 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-14 > .column,
.xlarge-up-14 > .columns {
    -webkit-flex: 0 0 7.1428571429%;
    -ms-flex: 0 0 7.1428571429%;
    flex: 0 0 7.1428571429%;
    max-width: 7.1428571429%;
  }

  .xlarge-15 {
    -webkit-flex: 0 0 93.75%;
    -ms-flex: 0 0 93.75%;
    flex: 0 0 93.75%;
    max-width: 93.75%;
  }

  .xlarge-offset-14 {
    margin-left: 87.5%;
  }

  .xlarge-order-15 {
    -webkit-order: 15;
    -ms-flex-order: 15;
    order: 15;
  }

  .xlarge-up-15 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-15 > .column,
.xlarge-up-15 > .columns {
    -webkit-flex: 0 0 6.6666666667%;
    -ms-flex: 0 0 6.6666666667%;
    flex: 0 0 6.6666666667%;
    max-width: 6.6666666667%;
  }

  .xlarge-16 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .xlarge-offset-15 {
    margin-left: 93.75%;
  }

  .xlarge-order-16 {
    -webkit-order: 16;
    -ms-flex-order: 16;
    order: 16;
  }

  .xlarge-up-16 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xlarge-up-16 > .column,
.xlarge-up-16 > .columns {
    -webkit-flex: 0 0 6.25%;
    -ms-flex: 0 0 6.25%;
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }

  .xlarge-collapse {
    padding-left: 0;
    padding-right: 0;
  }

  .xlarge-collapse > .column,
.xlarge-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }

  .xlarge-uncollapse > .column,
.xlarge-uncollapse > .columns {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
@media screen and (max-width: 1439px) {
  .large-1 {
    -webkit-flex: 0 0 6.25%;
    -ms-flex: 0 0 6.25%;
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }

  .large-offset-0 {
    margin-left: 0%;
  }

  .large-order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .large-up-1 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-1 > .column,
.large-up-1 > .columns {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .large-2 {
    -webkit-flex: 0 0 12.5%;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .large-offset-1 {
    margin-left: 6.25%;
  }

  .large-order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .large-up-2 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-2 > .column,
.large-up-2 > .columns {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .large-3 {
    -webkit-flex: 0 0 18.75%;
    -ms-flex: 0 0 18.75%;
    flex: 0 0 18.75%;
    max-width: 18.75%;
  }

  .large-offset-2 {
    margin-left: 12.5%;
  }

  .large-order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .large-up-3 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-3 > .column,
.large-up-3 > .columns {
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .large-4 {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .large-offset-3 {
    margin-left: 18.75%;
  }

  .large-order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }

  .large-up-4 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-4 > .column,
.large-up-4 > .columns {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .large-5 {
    -webkit-flex: 0 0 31.25%;
    -ms-flex: 0 0 31.25%;
    flex: 0 0 31.25%;
    max-width: 31.25%;
  }

  .large-offset-4 {
    margin-left: 25%;
  }

  .large-order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }

  .large-up-5 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-5 > .column,
.large-up-5 > .columns {
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .large-6 {
    -webkit-flex: 0 0 37.5%;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .large-offset-5 {
    margin-left: 31.25%;
  }

  .large-order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }

  .large-up-6 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-6 > .column,
.large-up-6 > .columns {
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .large-7 {
    -webkit-flex: 0 0 43.75%;
    -ms-flex: 0 0 43.75%;
    flex: 0 0 43.75%;
    max-width: 43.75%;
  }

  .large-offset-6 {
    margin-left: 37.5%;
  }

  .large-order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }

  .large-up-7 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-7 > .column,
.large-up-7 > .columns {
    -webkit-flex: 0 0 14.2857142857%;
    -ms-flex: 0 0 14.2857142857%;
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .large-8 {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .large-offset-7 {
    margin-left: 43.75%;
  }

  .large-order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }

  .large-up-8 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-8 > .column,
.large-up-8 > .columns {
    -webkit-flex: 0 0 12.5%;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .large-9 {
    -webkit-flex: 0 0 56.25%;
    -ms-flex: 0 0 56.25%;
    flex: 0 0 56.25%;
    max-width: 56.25%;
  }

  .large-offset-8 {
    margin-left: 50%;
  }

  .large-order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }

  .large-up-9 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-9 > .column,
.large-up-9 > .columns {
    -webkit-flex: 0 0 11.1111111111%;
    -ms-flex: 0 0 11.1111111111%;
    flex: 0 0 11.1111111111%;
    max-width: 11.1111111111%;
  }

  .large-10 {
    -webkit-flex: 0 0 62.5%;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .large-offset-9 {
    margin-left: 56.25%;
  }

  .large-order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }

  .large-up-10 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-10 > .column,
.large-up-10 > .columns {
    -webkit-flex: 0 0 10%;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10%;
  }

  .large-11 {
    -webkit-flex: 0 0 68.75%;
    -ms-flex: 0 0 68.75%;
    flex: 0 0 68.75%;
    max-width: 68.75%;
  }

  .large-offset-10 {
    margin-left: 62.5%;
  }

  .large-order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }

  .large-up-11 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-11 > .column,
.large-up-11 > .columns {
    -webkit-flex: 0 0 9.0909090909%;
    -ms-flex: 0 0 9.0909090909%;
    flex: 0 0 9.0909090909%;
    max-width: 9.0909090909%;
  }

  .large-12 {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .large-offset-11 {
    margin-left: 68.75%;
  }

  .large-order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }

  .large-up-12 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-12 > .column,
.large-up-12 > .columns {
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .large-13 {
    -webkit-flex: 0 0 81.25%;
    -ms-flex: 0 0 81.25%;
    flex: 0 0 81.25%;
    max-width: 81.25%;
  }

  .large-offset-12 {
    margin-left: 75%;
  }

  .large-order-13 {
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13;
  }

  .large-up-13 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-13 > .column,
.large-up-13 > .columns {
    -webkit-flex: 0 0 7.6923076923%;
    -ms-flex: 0 0 7.6923076923%;
    flex: 0 0 7.6923076923%;
    max-width: 7.6923076923%;
  }

  .large-14 {
    -webkit-flex: 0 0 87.5%;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .large-offset-13 {
    margin-left: 81.25%;
  }

  .large-order-14 {
    -webkit-order: 14;
    -ms-flex-order: 14;
    order: 14;
  }

  .large-up-14 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-14 > .column,
.large-up-14 > .columns {
    -webkit-flex: 0 0 7.1428571429%;
    -ms-flex: 0 0 7.1428571429%;
    flex: 0 0 7.1428571429%;
    max-width: 7.1428571429%;
  }

  .large-15 {
    -webkit-flex: 0 0 93.75%;
    -ms-flex: 0 0 93.75%;
    flex: 0 0 93.75%;
    max-width: 93.75%;
  }

  .large-offset-14 {
    margin-left: 87.5%;
  }

  .large-order-15 {
    -webkit-order: 15;
    -ms-flex-order: 15;
    order: 15;
  }

  .large-up-15 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-15 > .column,
.large-up-15 > .columns {
    -webkit-flex: 0 0 6.6666666667%;
    -ms-flex: 0 0 6.6666666667%;
    flex: 0 0 6.6666666667%;
    max-width: 6.6666666667%;
  }

  .large-16 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .large-offset-15 {
    margin-left: 93.75%;
  }

  .large-order-16 {
    -webkit-order: 16;
    -ms-flex-order: 16;
    order: 16;
  }

  .large-up-16 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .large-up-16 > .column,
.large-up-16 > .columns {
    -webkit-flex: 0 0 6.25%;
    -ms-flex: 0 0 6.25%;
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }

  .large-collapse {
    padding-left: 0;
    padding-right: 0;
  }

  .large-collapse > .column,
.large-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }

  .large-uncollapse > .column,
.large-uncollapse > .columns {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
@media screen and (max-width: 1370px) {
  .medium-1 {
    -webkit-flex: 0 0 6.25%;
    -ms-flex: 0 0 6.25%;
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }

  .medium-offset-0 {
    margin-left: 0%;
  }

  .medium-order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .medium-up-1 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-1 > .column,
.medium-up-1 > .columns {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .medium-2 {
    -webkit-flex: 0 0 12.5%;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .medium-offset-1 {
    margin-left: 6.25%;
  }

  .medium-order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .medium-up-2 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-2 > .column,
.medium-up-2 > .columns {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .medium-3 {
    -webkit-flex: 0 0 18.75%;
    -ms-flex: 0 0 18.75%;
    flex: 0 0 18.75%;
    max-width: 18.75%;
  }

  .medium-offset-2 {
    margin-left: 12.5%;
  }

  .medium-order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .medium-up-3 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-3 > .column,
.medium-up-3 > .columns {
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .medium-4 {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .medium-offset-3 {
    margin-left: 18.75%;
  }

  .medium-order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }

  .medium-up-4 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-4 > .column,
.medium-up-4 > .columns {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .medium-5 {
    -webkit-flex: 0 0 31.25%;
    -ms-flex: 0 0 31.25%;
    flex: 0 0 31.25%;
    max-width: 31.25%;
  }

  .medium-offset-4 {
    margin-left: 25%;
  }

  .medium-order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }

  .medium-up-5 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-5 > .column,
.medium-up-5 > .columns {
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .medium-6 {
    -webkit-flex: 0 0 37.5%;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .medium-offset-5 {
    margin-left: 31.25%;
  }

  .medium-order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }

  .medium-up-6 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-6 > .column,
.medium-up-6 > .columns {
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .medium-7 {
    -webkit-flex: 0 0 43.75%;
    -ms-flex: 0 0 43.75%;
    flex: 0 0 43.75%;
    max-width: 43.75%;
  }

  .medium-offset-6 {
    margin-left: 37.5%;
  }

  .medium-order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }

  .medium-up-7 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-7 > .column,
.medium-up-7 > .columns {
    -webkit-flex: 0 0 14.2857142857%;
    -ms-flex: 0 0 14.2857142857%;
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .medium-8 {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .medium-offset-7 {
    margin-left: 43.75%;
  }

  .medium-order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }

  .medium-up-8 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-8 > .column,
.medium-up-8 > .columns {
    -webkit-flex: 0 0 12.5%;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .medium-9 {
    -webkit-flex: 0 0 56.25%;
    -ms-flex: 0 0 56.25%;
    flex: 0 0 56.25%;
    max-width: 56.25%;
  }

  .medium-offset-8 {
    margin-left: 50%;
  }

  .medium-order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }

  .medium-up-9 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-9 > .column,
.medium-up-9 > .columns {
    -webkit-flex: 0 0 11.1111111111%;
    -ms-flex: 0 0 11.1111111111%;
    flex: 0 0 11.1111111111%;
    max-width: 11.1111111111%;
  }

  .medium-10 {
    -webkit-flex: 0 0 62.5%;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .medium-offset-9 {
    margin-left: 56.25%;
  }

  .medium-order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }

  .medium-up-10 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-10 > .column,
.medium-up-10 > .columns {
    -webkit-flex: 0 0 10%;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10%;
  }

  .medium-11 {
    -webkit-flex: 0 0 68.75%;
    -ms-flex: 0 0 68.75%;
    flex: 0 0 68.75%;
    max-width: 68.75%;
  }

  .medium-offset-10 {
    margin-left: 62.5%;
  }

  .medium-order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }

  .medium-up-11 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-11 > .column,
.medium-up-11 > .columns {
    -webkit-flex: 0 0 9.0909090909%;
    -ms-flex: 0 0 9.0909090909%;
    flex: 0 0 9.0909090909%;
    max-width: 9.0909090909%;
  }

  .medium-12 {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .medium-offset-11 {
    margin-left: 68.75%;
  }

  .medium-order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }

  .medium-up-12 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-12 > .column,
.medium-up-12 > .columns {
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .medium-13 {
    -webkit-flex: 0 0 81.25%;
    -ms-flex: 0 0 81.25%;
    flex: 0 0 81.25%;
    max-width: 81.25%;
  }

  .medium-offset-12 {
    margin-left: 75%;
  }

  .medium-order-13 {
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13;
  }

  .medium-up-13 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-13 > .column,
.medium-up-13 > .columns {
    -webkit-flex: 0 0 7.6923076923%;
    -ms-flex: 0 0 7.6923076923%;
    flex: 0 0 7.6923076923%;
    max-width: 7.6923076923%;
  }

  .medium-14 {
    -webkit-flex: 0 0 87.5%;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .medium-offset-13 {
    margin-left: 81.25%;
  }

  .medium-order-14 {
    -webkit-order: 14;
    -ms-flex-order: 14;
    order: 14;
  }

  .medium-up-14 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-14 > .column,
.medium-up-14 > .columns {
    -webkit-flex: 0 0 7.1428571429%;
    -ms-flex: 0 0 7.1428571429%;
    flex: 0 0 7.1428571429%;
    max-width: 7.1428571429%;
  }

  .medium-15 {
    -webkit-flex: 0 0 93.75%;
    -ms-flex: 0 0 93.75%;
    flex: 0 0 93.75%;
    max-width: 93.75%;
  }

  .medium-offset-14 {
    margin-left: 87.5%;
  }

  .medium-order-15 {
    -webkit-order: 15;
    -ms-flex-order: 15;
    order: 15;
  }

  .medium-up-15 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-15 > .column,
.medium-up-15 > .columns {
    -webkit-flex: 0 0 6.6666666667%;
    -ms-flex: 0 0 6.6666666667%;
    flex: 0 0 6.6666666667%;
    max-width: 6.6666666667%;
  }

  .medium-16 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .medium-offset-15 {
    margin-left: 93.75%;
  }

  .medium-order-16 {
    -webkit-order: 16;
    -ms-flex-order: 16;
    order: 16;
  }

  .medium-up-16 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .medium-up-16 > .column,
.medium-up-16 > .columns {
    -webkit-flex: 0 0 6.25%;
    -ms-flex: 0 0 6.25%;
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }

  .medium-collapse {
    padding-left: 0;
    padding-right: 0;
  }

  .medium-collapse > .column,
.medium-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }

  .medium-uncollapse > .column,
.medium-uncollapse > .columns {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
@media screen and (max-width: 1023px) {
  .small-1 {
    -webkit-flex: 0 0 6.25%;
    -ms-flex: 0 0 6.25%;
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }

  .small-offset-0 {
    margin-left: 0%;
  }

  .small-order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .small-up-1 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-1 > .column,
.small-up-1 > .columns {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .small-2 {
    -webkit-flex: 0 0 12.5%;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .small-offset-1 {
    margin-left: 6.25%;
  }

  .small-order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .small-up-2 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-2 > .column,
.small-up-2 > .columns {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .small-3 {
    -webkit-flex: 0 0 18.75%;
    -ms-flex: 0 0 18.75%;
    flex: 0 0 18.75%;
    max-width: 18.75%;
  }

  .small-offset-2 {
    margin-left: 12.5%;
  }

  .small-order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .small-up-3 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-3 > .column,
.small-up-3 > .columns {
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .small-4 {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .small-offset-3 {
    margin-left: 18.75%;
  }

  .small-order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }

  .small-up-4 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-4 > .column,
.small-up-4 > .columns {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .small-5 {
    -webkit-flex: 0 0 31.25%;
    -ms-flex: 0 0 31.25%;
    flex: 0 0 31.25%;
    max-width: 31.25%;
  }

  .small-offset-4 {
    margin-left: 25%;
  }

  .small-order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }

  .small-up-5 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-5 > .column,
.small-up-5 > .columns {
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .small-6 {
    -webkit-flex: 0 0 37.5%;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .small-offset-5 {
    margin-left: 31.25%;
  }

  .small-order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }

  .small-up-6 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-6 > .column,
.small-up-6 > .columns {
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .small-7 {
    -webkit-flex: 0 0 43.75%;
    -ms-flex: 0 0 43.75%;
    flex: 0 0 43.75%;
    max-width: 43.75%;
  }

  .small-offset-6 {
    margin-left: 37.5%;
  }

  .small-order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }

  .small-up-7 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-7 > .column,
.small-up-7 > .columns {
    -webkit-flex: 0 0 14.2857142857%;
    -ms-flex: 0 0 14.2857142857%;
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .small-8 {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .small-offset-7 {
    margin-left: 43.75%;
  }

  .small-order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }

  .small-up-8 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-8 > .column,
.small-up-8 > .columns {
    -webkit-flex: 0 0 12.5%;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .small-9 {
    -webkit-flex: 0 0 56.25%;
    -ms-flex: 0 0 56.25%;
    flex: 0 0 56.25%;
    max-width: 56.25%;
  }

  .small-offset-8 {
    margin-left: 50%;
  }

  .small-order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }

  .small-up-9 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-9 > .column,
.small-up-9 > .columns {
    -webkit-flex: 0 0 11.1111111111%;
    -ms-flex: 0 0 11.1111111111%;
    flex: 0 0 11.1111111111%;
    max-width: 11.1111111111%;
  }

  .small-10 {
    -webkit-flex: 0 0 62.5%;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .small-offset-9 {
    margin-left: 56.25%;
  }

  .small-order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }

  .small-up-10 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-10 > .column,
.small-up-10 > .columns {
    -webkit-flex: 0 0 10%;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10%;
  }

  .small-11 {
    -webkit-flex: 0 0 68.75%;
    -ms-flex: 0 0 68.75%;
    flex: 0 0 68.75%;
    max-width: 68.75%;
  }

  .small-offset-10 {
    margin-left: 62.5%;
  }

  .small-order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }

  .small-up-11 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-11 > .column,
.small-up-11 > .columns {
    -webkit-flex: 0 0 9.0909090909%;
    -ms-flex: 0 0 9.0909090909%;
    flex: 0 0 9.0909090909%;
    max-width: 9.0909090909%;
  }

  .small-12 {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .small-offset-11 {
    margin-left: 68.75%;
  }

  .small-order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }

  .small-up-12 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-12 > .column,
.small-up-12 > .columns {
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .small-13 {
    -webkit-flex: 0 0 81.25%;
    -ms-flex: 0 0 81.25%;
    flex: 0 0 81.25%;
    max-width: 81.25%;
  }

  .small-offset-12 {
    margin-left: 75%;
  }

  .small-order-13 {
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13;
  }

  .small-up-13 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-13 > .column,
.small-up-13 > .columns {
    -webkit-flex: 0 0 7.6923076923%;
    -ms-flex: 0 0 7.6923076923%;
    flex: 0 0 7.6923076923%;
    max-width: 7.6923076923%;
  }

  .small-14 {
    -webkit-flex: 0 0 87.5%;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .small-offset-13 {
    margin-left: 81.25%;
  }

  .small-order-14 {
    -webkit-order: 14;
    -ms-flex-order: 14;
    order: 14;
  }

  .small-up-14 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-14 > .column,
.small-up-14 > .columns {
    -webkit-flex: 0 0 7.1428571429%;
    -ms-flex: 0 0 7.1428571429%;
    flex: 0 0 7.1428571429%;
    max-width: 7.1428571429%;
  }

  .small-15 {
    -webkit-flex: 0 0 93.75%;
    -ms-flex: 0 0 93.75%;
    flex: 0 0 93.75%;
    max-width: 93.75%;
  }

  .small-offset-14 {
    margin-left: 87.5%;
  }

  .small-order-15 {
    -webkit-order: 15;
    -ms-flex-order: 15;
    order: 15;
  }

  .small-up-15 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-15 > .column,
.small-up-15 > .columns {
    -webkit-flex: 0 0 6.6666666667%;
    -ms-flex: 0 0 6.6666666667%;
    flex: 0 0 6.6666666667%;
    max-width: 6.6666666667%;
  }

  .small-16 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .small-offset-15 {
    margin-left: 93.75%;
  }

  .small-order-16 {
    -webkit-order: 16;
    -ms-flex-order: 16;
    order: 16;
  }

  .small-up-16 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .small-up-16 > .column,
.small-up-16 > .columns {
    -webkit-flex: 0 0 6.25%;
    -ms-flex: 0 0 6.25%;
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }

  .small-collapse {
    padding-left: 0;
    padding-right: 0;
  }

  .small-collapse > .column,
.small-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }

  .small-uncollapse > .column,
.small-uncollapse > .columns {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
@media screen and (max-width: 767px) {
  .xsmall-1 {
    -webkit-flex: 0 0 6.25%;
    -ms-flex: 0 0 6.25%;
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }

  .xsmall-offset-0 {
    margin-left: 0%;
  }

  .xsmall-order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .xsmall-up-1 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-1 > .column,
.xsmall-up-1 > .columns {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .xsmall-2 {
    -webkit-flex: 0 0 12.5%;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .xsmall-offset-1 {
    margin-left: 6.25%;
  }

  .xsmall-order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .xsmall-up-2 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-2 > .column,
.xsmall-up-2 > .columns {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .xsmall-3 {
    -webkit-flex: 0 0 18.75%;
    -ms-flex: 0 0 18.75%;
    flex: 0 0 18.75%;
    max-width: 18.75%;
  }

  .xsmall-offset-2 {
    margin-left: 12.5%;
  }

  .xsmall-order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .xsmall-up-3 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-3 > .column,
.xsmall-up-3 > .columns {
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .xsmall-4 {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .xsmall-offset-3 {
    margin-left: 18.75%;
  }

  .xsmall-order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }

  .xsmall-up-4 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-4 > .column,
.xsmall-up-4 > .columns {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .xsmall-5 {
    -webkit-flex: 0 0 31.25%;
    -ms-flex: 0 0 31.25%;
    flex: 0 0 31.25%;
    max-width: 31.25%;
  }

  .xsmall-offset-4 {
    margin-left: 25%;
  }

  .xsmall-order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }

  .xsmall-up-5 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-5 > .column,
.xsmall-up-5 > .columns {
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .xsmall-6 {
    -webkit-flex: 0 0 37.5%;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .xsmall-offset-5 {
    margin-left: 31.25%;
  }

  .xsmall-order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }

  .xsmall-up-6 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-6 > .column,
.xsmall-up-6 > .columns {
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .xsmall-7 {
    -webkit-flex: 0 0 43.75%;
    -ms-flex: 0 0 43.75%;
    flex: 0 0 43.75%;
    max-width: 43.75%;
  }

  .xsmall-offset-6 {
    margin-left: 37.5%;
  }

  .xsmall-order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }

  .xsmall-up-7 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-7 > .column,
.xsmall-up-7 > .columns {
    -webkit-flex: 0 0 14.2857142857%;
    -ms-flex: 0 0 14.2857142857%;
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .xsmall-8 {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .xsmall-offset-7 {
    margin-left: 43.75%;
  }

  .xsmall-order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }

  .xsmall-up-8 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-8 > .column,
.xsmall-up-8 > .columns {
    -webkit-flex: 0 0 12.5%;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .xsmall-9 {
    -webkit-flex: 0 0 56.25%;
    -ms-flex: 0 0 56.25%;
    flex: 0 0 56.25%;
    max-width: 56.25%;
  }

  .xsmall-offset-8 {
    margin-left: 50%;
  }

  .xsmall-order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }

  .xsmall-up-9 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-9 > .column,
.xsmall-up-9 > .columns {
    -webkit-flex: 0 0 11.1111111111%;
    -ms-flex: 0 0 11.1111111111%;
    flex: 0 0 11.1111111111%;
    max-width: 11.1111111111%;
  }

  .xsmall-10 {
    -webkit-flex: 0 0 62.5%;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .xsmall-offset-9 {
    margin-left: 56.25%;
  }

  .xsmall-order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }

  .xsmall-up-10 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-10 > .column,
.xsmall-up-10 > .columns {
    -webkit-flex: 0 0 10%;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10%;
  }

  .xsmall-11 {
    -webkit-flex: 0 0 68.75%;
    -ms-flex: 0 0 68.75%;
    flex: 0 0 68.75%;
    max-width: 68.75%;
  }

  .xsmall-offset-10 {
    margin-left: 62.5%;
  }

  .xsmall-order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }

  .xsmall-up-11 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-11 > .column,
.xsmall-up-11 > .columns {
    -webkit-flex: 0 0 9.0909090909%;
    -ms-flex: 0 0 9.0909090909%;
    flex: 0 0 9.0909090909%;
    max-width: 9.0909090909%;
  }

  .xsmall-12 {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .xsmall-offset-11 {
    margin-left: 68.75%;
  }

  .xsmall-order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }

  .xsmall-up-12 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-12 > .column,
.xsmall-up-12 > .columns {
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .xsmall-13 {
    -webkit-flex: 0 0 81.25%;
    -ms-flex: 0 0 81.25%;
    flex: 0 0 81.25%;
    max-width: 81.25%;
  }

  .xsmall-offset-12 {
    margin-left: 75%;
  }

  .xsmall-order-13 {
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13;
  }

  .xsmall-up-13 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-13 > .column,
.xsmall-up-13 > .columns {
    -webkit-flex: 0 0 7.6923076923%;
    -ms-flex: 0 0 7.6923076923%;
    flex: 0 0 7.6923076923%;
    max-width: 7.6923076923%;
  }

  .xsmall-14 {
    -webkit-flex: 0 0 87.5%;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .xsmall-offset-13 {
    margin-left: 81.25%;
  }

  .xsmall-order-14 {
    -webkit-order: 14;
    -ms-flex-order: 14;
    order: 14;
  }

  .xsmall-up-14 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-14 > .column,
.xsmall-up-14 > .columns {
    -webkit-flex: 0 0 7.1428571429%;
    -ms-flex: 0 0 7.1428571429%;
    flex: 0 0 7.1428571429%;
    max-width: 7.1428571429%;
  }

  .xsmall-15 {
    -webkit-flex: 0 0 93.75%;
    -ms-flex: 0 0 93.75%;
    flex: 0 0 93.75%;
    max-width: 93.75%;
  }

  .xsmall-offset-14 {
    margin-left: 87.5%;
  }

  .xsmall-order-15 {
    -webkit-order: 15;
    -ms-flex-order: 15;
    order: 15;
  }

  .xsmall-up-15 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-15 > .column,
.xsmall-up-15 > .columns {
    -webkit-flex: 0 0 6.6666666667%;
    -ms-flex: 0 0 6.6666666667%;
    flex: 0 0 6.6666666667%;
    max-width: 6.6666666667%;
  }

  .xsmall-16 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .xsmall-offset-15 {
    margin-left: 93.75%;
  }

  .xsmall-order-16 {
    -webkit-order: 16;
    -ms-flex-order: 16;
    order: 16;
  }

  .xsmall-up-16 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xsmall-up-16 > .column,
.xsmall-up-16 > .columns {
    -webkit-flex: 0 0 6.25%;
    -ms-flex: 0 0 6.25%;
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }

  .xsmall-collapse {
    padding-left: 0;
    padding-right: 0;
  }

  .xsmall-collapse > .column,
.xsmall-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }

  .xsmall-uncollapse > .column,
.xsmall-uncollapse > .columns {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
@media screen and (max-width: 361px) {
  .xxsmall-1 {
    -webkit-flex: 0 0 6.25%;
    -ms-flex: 0 0 6.25%;
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }

  .xxsmall-offset-0 {
    margin-left: 0%;
  }

  .xxsmall-order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .xxsmall-up-1 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-1 > .column,
.xxsmall-up-1 > .columns {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .xxsmall-2 {
    -webkit-flex: 0 0 12.5%;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .xxsmall-offset-1 {
    margin-left: 6.25%;
  }

  .xxsmall-order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .xxsmall-up-2 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-2 > .column,
.xxsmall-up-2 > .columns {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .xxsmall-3 {
    -webkit-flex: 0 0 18.75%;
    -ms-flex: 0 0 18.75%;
    flex: 0 0 18.75%;
    max-width: 18.75%;
  }

  .xxsmall-offset-2 {
    margin-left: 12.5%;
  }

  .xxsmall-order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }

  .xxsmall-up-3 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-3 > .column,
.xxsmall-up-3 > .columns {
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .xxsmall-4 {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .xxsmall-offset-3 {
    margin-left: 18.75%;
  }

  .xxsmall-order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }

  .xxsmall-up-4 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-4 > .column,
.xxsmall-up-4 > .columns {
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .xxsmall-5 {
    -webkit-flex: 0 0 31.25%;
    -ms-flex: 0 0 31.25%;
    flex: 0 0 31.25%;
    max-width: 31.25%;
  }

  .xxsmall-offset-4 {
    margin-left: 25%;
  }

  .xxsmall-order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }

  .xxsmall-up-5 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-5 > .column,
.xxsmall-up-5 > .columns {
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .xxsmall-6 {
    -webkit-flex: 0 0 37.5%;
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }

  .xxsmall-offset-5 {
    margin-left: 31.25%;
  }

  .xxsmall-order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }

  .xxsmall-up-6 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-6 > .column,
.xxsmall-up-6 > .columns {
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .xxsmall-7 {
    -webkit-flex: 0 0 43.75%;
    -ms-flex: 0 0 43.75%;
    flex: 0 0 43.75%;
    max-width: 43.75%;
  }

  .xxsmall-offset-6 {
    margin-left: 37.5%;
  }

  .xxsmall-order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }

  .xxsmall-up-7 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-7 > .column,
.xxsmall-up-7 > .columns {
    -webkit-flex: 0 0 14.2857142857%;
    -ms-flex: 0 0 14.2857142857%;
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .xxsmall-8 {
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .xxsmall-offset-7 {
    margin-left: 43.75%;
  }

  .xxsmall-order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }

  .xxsmall-up-8 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-8 > .column,
.xxsmall-up-8 > .columns {
    -webkit-flex: 0 0 12.5%;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  .xxsmall-9 {
    -webkit-flex: 0 0 56.25%;
    -ms-flex: 0 0 56.25%;
    flex: 0 0 56.25%;
    max-width: 56.25%;
  }

  .xxsmall-offset-8 {
    margin-left: 50%;
  }

  .xxsmall-order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }

  .xxsmall-up-9 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-9 > .column,
.xxsmall-up-9 > .columns {
    -webkit-flex: 0 0 11.1111111111%;
    -ms-flex: 0 0 11.1111111111%;
    flex: 0 0 11.1111111111%;
    max-width: 11.1111111111%;
  }

  .xxsmall-10 {
    -webkit-flex: 0 0 62.5%;
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }

  .xxsmall-offset-9 {
    margin-left: 56.25%;
  }

  .xxsmall-order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }

  .xxsmall-up-10 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-10 > .column,
.xxsmall-up-10 > .columns {
    -webkit-flex: 0 0 10%;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10%;
  }

  .xxsmall-11 {
    -webkit-flex: 0 0 68.75%;
    -ms-flex: 0 0 68.75%;
    flex: 0 0 68.75%;
    max-width: 68.75%;
  }

  .xxsmall-offset-10 {
    margin-left: 62.5%;
  }

  .xxsmall-order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }

  .xxsmall-up-11 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-11 > .column,
.xxsmall-up-11 > .columns {
    -webkit-flex: 0 0 9.0909090909%;
    -ms-flex: 0 0 9.0909090909%;
    flex: 0 0 9.0909090909%;
    max-width: 9.0909090909%;
  }

  .xxsmall-12 {
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .xxsmall-offset-11 {
    margin-left: 68.75%;
  }

  .xxsmall-order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }

  .xxsmall-up-12 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-12 > .column,
.xxsmall-up-12 > .columns {
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .xxsmall-13 {
    -webkit-flex: 0 0 81.25%;
    -ms-flex: 0 0 81.25%;
    flex: 0 0 81.25%;
    max-width: 81.25%;
  }

  .xxsmall-offset-12 {
    margin-left: 75%;
  }

  .xxsmall-order-13 {
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13;
  }

  .xxsmall-up-13 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-13 > .column,
.xxsmall-up-13 > .columns {
    -webkit-flex: 0 0 7.6923076923%;
    -ms-flex: 0 0 7.6923076923%;
    flex: 0 0 7.6923076923%;
    max-width: 7.6923076923%;
  }

  .xxsmall-14 {
    -webkit-flex: 0 0 87.5%;
    -ms-flex: 0 0 87.5%;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }

  .xxsmall-offset-13 {
    margin-left: 81.25%;
  }

  .xxsmall-order-14 {
    -webkit-order: 14;
    -ms-flex-order: 14;
    order: 14;
  }

  .xxsmall-up-14 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-14 > .column,
.xxsmall-up-14 > .columns {
    -webkit-flex: 0 0 7.1428571429%;
    -ms-flex: 0 0 7.1428571429%;
    flex: 0 0 7.1428571429%;
    max-width: 7.1428571429%;
  }

  .xxsmall-15 {
    -webkit-flex: 0 0 93.75%;
    -ms-flex: 0 0 93.75%;
    flex: 0 0 93.75%;
    max-width: 93.75%;
  }

  .xxsmall-offset-14 {
    margin-left: 87.5%;
  }

  .xxsmall-order-15 {
    -webkit-order: 15;
    -ms-flex-order: 15;
    order: 15;
  }

  .xxsmall-up-15 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-15 > .column,
.xxsmall-up-15 > .columns {
    -webkit-flex: 0 0 6.6666666667%;
    -ms-flex: 0 0 6.6666666667%;
    flex: 0 0 6.6666666667%;
    max-width: 6.6666666667%;
  }

  .xxsmall-16 {
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .xxsmall-offset-15 {
    margin-left: 93.75%;
  }

  .xxsmall-order-16 {
    -webkit-order: 16;
    -ms-flex-order: 16;
    order: 16;
  }

  .xxsmall-up-16 {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .xxsmall-up-16 > .column,
.xxsmall-up-16 > .columns {
    -webkit-flex: 0 0 6.25%;
    -ms-flex: 0 0 6.25%;
    flex: 0 0 6.25%;
    max-width: 6.25%;
  }

  .xxsmall-collapse {
    padding-left: 0;
    padding-right: 0;
  }

  .xxsmall-collapse > .column,
.xxsmall-collapse > .columns {
    padding-left: 0;
    padding-right: 0;
  }

  .xxsmall-uncollapse > .column,
.xxsmall-uncollapse > .columns {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
.page-template-page-about-us .about {
  position: relative;
  margin-top: 300px;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .about {
    margin-top: 280px;
  }
}
@media (min-width: 1919px) {
  .page-template-page-about-us .about {
    margin-top: 350px;
  }
}
.page-template-page-about-us .about h2 {
  text-transform: uppercase;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .about h2 {
    text-align: center;
  }
}
@media (max-width: 1023px) {
  .page-template-page-about-us .about p {
    text-align: center;
  }
}
.page-template-page-about-us .about .first-content {
  margin-bottom: 50px;
}
.page-template-page-about-us .about img {
  max-height: 400px;
  height: auto;
}
.page-template-page-about-us .about .two-cols {
  display: flex;
  align-items: center;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .about .two-cols .columns {
    text-align: center;
  }
}
.page-template-page-about-us .about .two-cols p {
  margin-top: -50px;
}
.page-template-page-about-us .about .watermark {
  right: -30%;
  left: auto;
}
.page-template-page-about-us .about .watermark img {
  max-height: inherit;
}

.page-template-page-about-us .hero,
.page-template-page-disclosures .hero,
.page-template-default .hero,
.page-template-page-signup .hero {
  width: 100%;
  position: relative;
  text-align: center;
  margin-bottom: 250px;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .hero,
.page-template-page-disclosures .hero,
.page-template-default .hero,
.page-template-page-signup .hero {
    margin-bottom: 100px;
  }
}
.page-template-page-about-us .hero .row,
.page-template-page-disclosures .hero .row,
.page-template-default .hero .row,
.page-template-page-signup .hero .row {
  position: relative;
  justify-content: center;
}
.page-template-page-about-us .hero .row .columns,
.page-template-page-disclosures .hero .row .columns,
.page-template-default .hero .row .columns,
.page-template-page-signup .hero .row .columns {
  width: 100%;
}
.page-template-page-about-us .hero h1,
.page-template-page-disclosures .hero h1,
.page-template-default .hero h1,
.page-template-page-signup .hero h1 {
  font-family: "Nunito", sans-serif;
  color: #fff;
  font-weight: 600;
  font-size: 1.125rem;
  margin-top: 175px;
  margin-bottom: 5px;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .hero h1,
.page-template-page-disclosures .hero h1,
.page-template-default .hero h1,
.page-template-page-signup .hero h1 {
    margin-top: 140px;
  }
}
.page-template-page-about-us .hero h2,
.page-template-page-disclosures .hero h2,
.page-template-default .hero h2,
.page-template-page-signup .hero h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: calc(1.325rem + 0.9vw);
  line-height: 45px;
  color: #fff;
}
@media (min-width: 1200px) {
  .page-template-page-about-us .hero h2,
.page-template-page-disclosures .hero h2,
.page-template-default .hero h2,
.page-template-page-signup .hero h2 {
    font-size: 2rem;
  }
}
.page-template-page-about-us .hero .sub-title,
.page-template-page-disclosures .hero .sub-title,
.page-template-default .hero .sub-title,
.page-template-page-signup .hero .sub-title {
  text-align: center;
}
.page-template-page-about-us .hero .social,
.page-template-page-disclosures .hero .social,
.page-template-default .hero .social,
.page-template-page-signup .hero .social {
  top: 150px;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .hero .social,
.page-template-page-disclosures .hero .social,
.page-template-default .hero .social,
.page-template-page-signup .hero .social {
    display: none;
  }
}
.page-template-page-about-us .hero .bg,
.page-template-page-disclosures .hero .bg,
.page-template-default .hero .bg,
.page-template-page-signup .hero .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.page-template-page-about-us .hero .bg img,
.page-template-page-disclosures .hero .bg img,
.page-template-default .hero .bg img,
.page-template-page-signup .hero .bg img {
  width: 100%;
  height: auto;
  height: 600px;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .hero .bg img,
.page-template-page-disclosures .hero .bg img,
.page-template-default .hero .bg img,
.page-template-page-signup .hero .bg img {
    height: 595px;
    object-fit: cover;
  }
}
.page-template-page-about-us .images-container,
.page-template-page-disclosures .images-container,
.page-template-default .images-container,
.page-template-page-signup .images-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .images-container img,
.page-template-page-disclosures .images-container img,
.page-template-default .images-container img,
.page-template-page-signup .images-container img {
    height: 600px;
  }
}
@media (max-width: 767px) {
  .page-template-page-about-us .images-container img,
.page-template-page-disclosures .images-container img,
.page-template-default .images-container img,
.page-template-page-signup .images-container img {
    height: 500px;
  }
}
.page-template-page-about-us .images-container img:nth-child(2),
.page-template-page-disclosures .images-container img:nth-child(2),
.page-template-default .images-container img:nth-child(2),
.page-template-page-signup .images-container img:nth-child(2) {
  margin-right: -435px;
  margin-left: -390px;
  margin-top: -110px;
  z-index: 1;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .images-container img:nth-child(2),
.page-template-page-disclosures .images-container img:nth-child(2),
.page-template-default .images-container img:nth-child(2),
.page-template-page-signup .images-container img:nth-child(2) {
    margin-right: -300px;
    margin-left: -275px;
  }
}

.page-template-page-about-us .how h2 {
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .how h2 {
    text-align: center;
  }
}
.page-template-page-about-us .how p {
  margin-bottom: 40px;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .how p {
    text-align: center;
  }
}
.page-template-page-about-us .how .column {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.page-template-page-about-us .how .column .col {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .page-template-page-about-us .how .column .col {
    justify-content: center;
  }
}
.page-template-page-about-us .how .column .col .nr h2 {
  border: solid 1px #215383;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-template-page-about-us .how .column .col h5 {
  color: #215383;
  margin-left: 10px;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .how .column .description p {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .page-template-page-about-us .how .column .description p {
    text-align: center;
  }
}

.page-template-page-about-us .locations h2 {
  color: #215383;
  text-align: left;
  text-transform: uppercase;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .locations h2 {
    text-align: center;
  }
}
.page-template-page-about-us .locations #map {
  width: 100%;
  height: 350px;
  border-radius: 16px;
}
.page-template-page-about-us .locations .mapouter .gmap_canvas iframe {
  width: 100%;
  height: 300px;
  border-radius: 16px;
}

.page-template-page-about-us .team .short-grid {
  position: relative;
}
.page-template-page-about-us .team h2 {
  text-align: left;
  text-transform: uppercase;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .team h2 {
    text-align: center;
  }
}
.page-template-page-about-us .team h3 {
  font-size: 1.125rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #215383;
  margin-top: 10px;
  text-align: center;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .team h3 {
    text-align: center;
  }
}
.page-template-page-about-us .team img.avatar {
  width: 100px;
  border-radius: 50%;
  text-align: center;
}
.page-template-page-about-us .team .column {
  margin-bottom: 50px;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .team .column {
    text-align: center;
  }
}
.page-template-page-about-us .team img.broker {
  width: 100px;
}
.page-template-page-about-us .team .swiper .avatar-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-template-page-about-us .team .swiper .content-column {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.page-template-page-about-us .team .swiper .content-column h5 {
  font-weight: bold;
  color: #215383;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .team .swiper .content-column h5 {
    text-align: center;
    display: block;
    width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .page-template-page-about-us .team .swiper .content-column h5 {
    text-align: center;
    padding: 0 20px;
  }
}
.page-template-page-about-us .team .swiper .content-column p {
  margin-top: 10px;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .team .swiper .content-column p {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .page-template-page-about-us .team .swiper .content-column p {
    text-align: center;
    padding: 0 20px;
  }
}
.page-template-page-about-us .team .swiper .content-column a {
  margin-top: 10px;
}
@media (max-width: 1023px) {
  .page-template-page-about-us .team .swiper .content-column a {
    text-align: center;
    display: block;
    width: 100%;
  }
}
.page-template-page-about-us .team .swiper .swiper-button-prev,
.page-template-page-about-us .team .swiper .swiper-button-next {
  color: #215383;
}
.page-template-page-about-us .team .swiper .swiper-button-prev:after,
.page-template-page-about-us .team .swiper .swiper-button-next:after {
  font-size: 20px;
}
.page-template-page-about-us .team .swiper .swiper-button-prev {
  left: 0px;
}
.page-template-page-about-us .team .swiper .swiper-button-next {
  right: 0px;
}

.page-template-page-blog .hero,
.page-template-page-help .hero,
.tax-categories .hero,
.tax-help_categories .hero,
.search .hero,
.single-help .hero {
  min-height: 460px;
  background: white;
  background: linear-gradient(260deg, rgba(72, 161, 243, 0.818) 0%, #215383 58%);
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .page-template-page-blog .hero,
.page-template-page-help .hero,
.tax-categories .hero,
.tax-help_categories .hero,
.search .hero,
.single-help .hero {
    min-height: 260px;
  }
}
.page-template-page-blog .hero .watermark,
.page-template-page-help .hero .watermark,
.tax-categories .hero .watermark,
.tax-help_categories .hero .watermark,
.search .hero .watermark,
.single-help .hero .watermark {
  top: 400px;
  left: auto;
  right: -30%;
}
.page-template-page-blog .hero .row,
.page-template-page-help .hero .row,
.tax-categories .hero .row,
.tax-help_categories .hero .row,
.search .hero .row,
.single-help .hero .row {
  width: 100%;
}
.page-template-page-blog .hero .row h1,
.page-template-page-help .hero .row h1,
.tax-categories .hero .row h1,
.tax-help_categories .hero .row h1,
.search .hero .row h1,
.single-help .hero .row h1 {
  font-family: "Nunito", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  margin-top: 50px;
}
.page-template-page-blog .hero .row h2,
.page-template-page-help .hero .row h2,
.tax-categories .hero .row h2,
.tax-help_categories .hero .row h2,
.search .hero .row h2,
.single-help .hero .row h2 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .page-template-page-blog .hero .row h2,
.page-template-page-help .hero .row h2,
.tax-categories .hero .row h2,
.tax-help_categories .hero .row h2,
.search .hero .row h2,
.single-help .hero .row h2 {
    font-size: 2rem;
  }
}

.latest-posts {
  position: relative;
}
.latest-posts .latest-posts-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .latest-posts .latest-posts-wrapper {
    grid-template-columns: 1fr;
  }
}
.latest-posts .latest-posts-wrapper .latest-post-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.latest-posts .latest-posts-wrapper .latest-post-wrapper .zoom-img {
  overflow: inherit;
}
.latest-posts .latest-posts-wrapper .latest-post-wrapper .zoom-img div {
  border-radius: 6px;
}
.latest-posts .latest-posts-wrapper .latest-post-wrapper img {
  width: 100%;
  height: 270px;
  object-fit: cover;
  border-radius: 6px;
}
.latest-posts .latest-posts-wrapper .latest-post-wrapper span.date {
  margin-top: 10px;
  display: block;
  font-size: 0.875rem;
}
.latest-posts .latest-posts-wrapper .latest-post-wrapper span.author {
  margin-top: 10px;
  display: block;
  font-size: 0.875rem;
}
.latest-posts .latest-posts-wrapper .latest-post-wrapper h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: calc(1.275rem + 0.3vw);
  color: #215383;
  margin: 20px 0;
}
@media (min-width: 1200px) {
  .latest-posts .latest-posts-wrapper .latest-post-wrapper h3 {
    font-size: 1.5rem;
  }
}
.latest-posts .latest-posts-wrapper .latest-post-wrapper p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  color: #8896AB;
}

.page-template-page-blog .posts-sec,
.tax-categories .posts-sec {
  position: relative;
}
.page-template-page-blog .posts-sec .cats,
.tax-categories .posts-sec .cats {
  margin-bottom: 50px;
}
.page-template-page-blog .posts-sec .cats .cats-wrapper,
.tax-categories .posts-sec .cats .cats-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  margin-top: -10%;
}
@media only screen and (max-width: 767px) {
  .page-template-page-blog .posts-sec .cats .cats-wrapper,
.tax-categories .posts-sec .cats .cats-wrapper {
    grid-template-columns: 1fr;
  }
}
.page-template-page-blog .posts-sec .cats .cats-wrapper .cat-wrapper,
.tax-categories .posts-sec .cats .cats-wrapper .cat-wrapper {
  position: relative;
  background-color: #EEEEEE;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 15px;
  padding: 50px;
  overflow: hidden;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .page-template-page-blog .posts-sec .cats .cats-wrapper .cat-wrapper,
.tax-categories .posts-sec .cats .cats-wrapper .cat-wrapper {
    padding: 10px;
  }
}
.page-template-page-blog .posts-sec .cats .cats-wrapper .cat-wrapper:before,
.tax-categories .posts-sec .cats .cats-wrapper .cat-wrapper:before {
  content: " ";
  display: block;
  width: 100%;
  padding-top: 25%;
}
@media only screen and (max-width: 767px) {
  .page-template-page-blog .posts-sec .cats .cats-wrapper .cat-wrapper:before,
.tax-categories .posts-sec .cats .cats-wrapper .cat-wrapper:before {
    padding-top: 0;
  }
}
.page-template-page-blog .posts-sec .cats .cats-wrapper .cat-wrapper .mask,
.tax-categories .posts-sec .cats .cats-wrapper .cat-wrapper .mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #09427A;
  z-index: 0;
  transform: translateY(-100%);
}
.page-template-page-blog .posts-sec .cats .cats-wrapper .cat-wrapper .content,
.tax-categories .posts-sec .cats .cats-wrapper .cat-wrapper .content {
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.page-template-page-blog .posts-sec .cats .cats-wrapper .cat-wrapper .content span,
.tax-categories .posts-sec .cats .cats-wrapper .cat-wrapper .content span {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: calc(1.725rem + 5.7vw);
  color: #09427A;
  text-align: right;
}
@media (min-width: 1200px) {
  .page-template-page-blog .posts-sec .cats .cats-wrapper .cat-wrapper .content span,
.tax-categories .posts-sec .cats .cats-wrapper .cat-wrapper .content span {
    font-size: 6rem;
  }
}
.page-template-page-blog .posts-sec .cats .cats-wrapper .cat-wrapper .content h5,
.tax-categories .posts-sec .cats .cats-wrapper .cat-wrapper .content h5 {
  font-weight: 900;
  font-size: calc(1.325rem + 0.9vw);
  color: #09427A;
  text-align: right;
}
@media (min-width: 1200px) {
  .page-template-page-blog .posts-sec .cats .cats-wrapper .cat-wrapper .content h5,
.tax-categories .posts-sec .cats .cats-wrapper .cat-wrapper .content h5 {
    font-size: 2rem;
  }
}
.page-template-page-blog .posts-sec .cats .cats-wrapper .cat-wrapper.active,
.tax-categories .posts-sec .cats .cats-wrapper .cat-wrapper.active {
  background-color: #09427A;
  cursor: inherit;
}
.page-template-page-blog .posts-sec .cats .cats-wrapper .cat-wrapper.active .content h5,
.tax-categories .posts-sec .cats .cats-wrapper .cat-wrapper.active .content h5 {
  color: #FFF;
}
.page-template-page-blog .posts-sec .cats .cats-wrapper .cat-wrapper.active .content span,
.tax-categories .posts-sec .cats .cats-wrapper .cat-wrapper.active .content span {
  color: #fff;
}
.page-template-page-blog .posts-sec .posts .posts-wrapper,
.tax-categories .posts-sec .posts .posts-wrapper {
  position: relative;
  background-color: #09427A;
  border-radius: 15px;
  padding: 25px 30px;
}
.page-template-page-blog .posts-sec .posts .posts-wrapper .mask,
.tax-categories .posts-sec .posts .posts-wrapper .mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  visibility: hidden;
}
.page-template-page-blog .posts-sec .posts .posts-wrapper .mask .lds-ring,
.tax-categories .posts-sec .posts .posts-wrapper .mask .lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.page-template-page-blog .posts-sec .posts .posts-wrapper .mask .lds-ring div,
.tax-categories .posts-sec .posts .posts-wrapper .mask .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #09427A;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #09427A transparent transparent transparent;
}
.page-template-page-blog .posts-sec .posts .posts-wrapper .mask .lds-ring:nth-child(1),
.tax-categories .posts-sec .posts .posts-wrapper .mask .lds-ring:nth-child(1) {
  animation-delay: -0.45s;
}
.page-template-page-blog .posts-sec .posts .posts-wrapper .mask .lds-ring div:nth-child(2),
.tax-categories .posts-sec .posts .posts-wrapper .mask .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.page-template-page-blog .posts-sec .posts .posts-wrapper .mask .lds-ring div:nth-child(3),
.tax-categories .posts-sec .posts .posts-wrapper .mask .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
.page-template-page-blog .posts-sec .posts .posts-wrapper h3,
.tax-categories .posts-sec .posts .posts-wrapper h3 {
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: 1.25rem;
  color: #FFF;
  text-transform: uppercase;
}
.page-template-page-blog .posts-sec .posts .posts-wrapper .posts-wrapper-grid,
.tax-categories .posts-sec .posts .posts-wrapper .posts-wrapper-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .page-template-page-blog .posts-sec .posts .posts-wrapper .posts-wrapper-grid,
.tax-categories .posts-sec .posts .posts-wrapper .posts-wrapper-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 430px) {
  .page-template-page-blog .posts-sec .posts .posts-wrapper .posts-wrapper-grid,
.tax-categories .posts-sec .posts .posts-wrapper .posts-wrapper-grid {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 361px) {
  .page-template-page-blog .posts-sec .posts .posts-wrapper .posts-wrapper-grid,
.tax-categories .posts-sec .posts .posts-wrapper .posts-wrapper-grid {
    grid-template-columns: 1fr;
  }
}
.page-template-page-blog .posts-sec .posts .posts-wrapper .posts-wrapper-grid .post-wrapper,
.tax-categories .posts-sec .posts .posts-wrapper .posts-wrapper-grid .post-wrapper {
  display: inline-grid;
  width: 100%;
  cursor: pointer;
}
.page-template-page-blog .posts-sec .posts .posts-wrapper .posts-wrapper-grid .post-wrapper .zoom-img div,
.tax-categories .posts-sec .posts .posts-wrapper .posts-wrapper-grid .post-wrapper .zoom-img div {
  border-radius: 6px;
}
.page-template-page-blog .posts-sec .posts .posts-wrapper .posts-wrapper-grid .post-wrapper h5,
.tax-categories .posts-sec .posts .posts-wrapper .posts-wrapper-grid .post-wrapper h5 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  margin-top: 20px;
}
.page-template-page-blog .posts-sec .posts .posts-wrapper .posts-wrapper-grid .post-wrapper img,
.tax-categories .posts-sec .posts .posts-wrapper .posts-wrapper-grid .post-wrapper img {
  width: 100%;
  height: 170px;
  object-fit: cover;
  border-radius: 6px;
}
.page-template-page-blog .posts-sec .btns button,
.tax-categories .posts-sec .btns button {
  margin: 20px 0 auto auto;
  cursor: pointer;
}
.page-template-page-blog .posts-sec .btns button .load-more.hide,
.tax-categories .posts-sec .btns button .load-more.hide {
  display: none;
}

.page-template-page-blog .video,
.tax-categories .video {
  display: none;
}
.page-template-page-blog .video .videos-wrapper,
.tax-categories .video .videos-wrapper {
  display: grid;
  grid-template-columns: 65% 35%;
  grid-template-areas: "video video-link1" "video video-link2";
}
@media only screen and (max-width: 767px) {
  .page-template-page-blog .video .videos-wrapper,
.tax-categories .video .videos-wrapper {
    grid-template-areas: "video" "video-link1" " video-link2";
    grid-template-columns: 100%;
  }
}
.page-template-page-blog .video .videos-wrapper .video,
.tax-categories .video .videos-wrapper .video {
  grid-area: video;
  margin-right: 60px;
}
@media only screen and (max-width: 1023px) {
  .page-template-page-blog .video .videos-wrapper .video,
.tax-categories .video .videos-wrapper .video {
    margin-right: 20px;
  }
}
.page-template-page-blog .video .videos-wrapper .video iframe,
.tax-categories .video .videos-wrapper .video iframe {
  width: 100%;
  height: 470px;
  object-fit: cover;
  border-radius: 30px;
}
@media only screen and (max-width: 1023px) {
  .page-template-page-blog .video .videos-wrapper .video iframe,
.tax-categories .video .videos-wrapper .video iframe {
    height: 300px;
  }
}
.page-template-page-blog .video .videos-wrapper .video-link1,
.tax-categories .video .videos-wrapper .video-link1 {
  grid-area: video-link1;
  margin-left: 20px;
}
.page-template-page-blog .video .videos-wrapper .video-link2,
.tax-categories .video .videos-wrapper .video-link2 {
  grid-area: video-link2;
  margin-left: 20px;
}
.page-template-page-blog .video .videos-wrapper .grid-element,
.tax-categories .video .videos-wrapper .grid-element {
  position: relative;
  background-color: transparent;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .page-template-page-blog .video .videos-wrapper .grid-element,
.tax-categories .video .videos-wrapper .grid-element {
    margin: 20px 0;
  }
}
.page-template-page-blog .video .videos-wrapper .grid-element .video-link-wrapper,
.tax-categories .video .videos-wrapper .grid-element .video-link-wrapper {
  position: relative;
}
.page-template-page-blog .video .videos-wrapper .grid-element .video-link-wrapper:hover h3 span,
.tax-categories .video .videos-wrapper .grid-element .video-link-wrapper:hover h3 span {
  transform: translateX(10px);
}
.page-template-page-blog .video .videos-wrapper .grid-element .video-link-wrapper .img-wrapper,
.tax-categories .video .videos-wrapper .grid-element .video-link-wrapper .img-wrapper {
  position: relative;
  height: 145px;
  width: 100%;
  border-radius: 19px;
  overflow: hidden;
}
.page-template-page-blog .video .videos-wrapper .grid-element .video-link-wrapper .img-wrapper img,
.tax-categories .video .videos-wrapper .grid-element .video-link-wrapper .img-wrapper img {
  object-fit: cover;
  width: 100%;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
}
.page-template-page-blog .video .videos-wrapper .grid-element .video-link-wrapper h3,
.tax-categories .video .videos-wrapper .grid-element .video-link-wrapper h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  color: #215383;
  margin-top: 20px;
}
.page-template-page-blog .video .videos-wrapper .grid-element .video-link-wrapper h3 span,
.tax-categories .video .videos-wrapper .grid-element .video-link-wrapper h3 span {
  margin-left: 10px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative;
  display: inline-block;
}

.page-template-page-contact .main-footer {
  background-image: none !important;
}
.page-template-page-contact .main-footer .newsletter {
  display: none;
}

.page-template-page-contact main {
  background: white;
  background: linear-gradient(60deg, rgba(72, 161, 243, 0.818) 0%, #215383 58%);
  min-height: 100vh;
}
.page-template-page-contact .contacts {
  padding-top: 200px;
}
@media (max-width: 767px) {
  .page-template-page-contact .contacts {
    padding-top: 150px;
  }
}
.page-template-page-contact .contacts .column {
  margin-bottom: 120px;
}
@media (max-width: 1023px) {
  .page-template-page-contact .contacts .column {
    text-align: center;
    margin-bottom: 70px;
  }
}
.page-template-page-contact .contacts h2,
.page-template-page-contact .contacts h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  color: #fff;
  margin-bottom: 60px;
  line-height: 1.2;
  text-transform: uppercase;
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .page-template-page-contact .contacts h2,
.page-template-page-contact .contacts h1 {
    font-size: 2rem;
  }
}
@media (max-width: 1023px) {
  .page-template-page-contact .contacts h2,
.page-template-page-contact .contacts h1 {
    text-align: center;
  }
}
.page-template-page-contact .contacts h5 {
  color: #fff;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1200px) {
  .page-template-page-contact .contacts h5 {
    font-size: 1.875rem;
  }
}
.page-template-page-contact .contacts p, .page-template-page-contact .contacts a {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #fff;
  font-size: 1.25rem;
  line-height: 45px;
}
.page-template-page-contact .contacts .form-section-wrapper {
  position: relative;
}
@media (max-width: 1023px) {
  .page-template-page-contact .contacts .form-section-wrapper {
    width: 80%;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .page-template-page-contact .contacts .form-section-wrapper {
    width: 100%;
  }
}
.page-template-page-contact .contacts .form-section-wrapper .form-wrapper form {
  display: flex;
  flex-direction: column;
  position: relative;
}
.page-template-page-contact .contacts .form-section-wrapper .form-wrapper form .input-field {
  border-radius: 10px;
  border: none;
  margin-bottom: 20px;
  outline: none;
  padding: 14px;
  font-size: 0.75rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #000;
}
.page-template-page-contact .contacts .form-section-wrapper .form-wrapper form textarea {
  height: 80px;
  resize: none;
}
.page-template-page-contact .contacts .form-section-wrapper .form-wrapper form button[type=submit] {
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 0px 0;
  cursor: pointer;
  width: 60%;
  margin: 0 auto;
  border-radius: 4px;
  border: none;
}
.page-template-page-contact .contacts .form-section-wrapper .form-wrapper form button[type=submit] span {
  pointer-events: none;
}
.page-template-page-contact .contacts .form-section-wrapper .form-wrapper .response {
  opacity: 0;
  position: absolute;
  font-size: 1.125rem;
  font-weight: 500;
  color: #fff;
  margin-top: 20px;
}
.page-template-page-contact .contacts .social {
  position: relative;
  right: inherit;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
@media (max-width: 1023px) {
  .page-template-page-contact .contacts .social {
    justify-content: center;
    top: auto;
  }
}
.page-template-page-contact .contacts .social .social-wrapper {
  margin-bottom: 0;
  margin: 0 10px;
}
.page-template-page-contact .contacts .social .social-wrapper:first-of-type {
  margin-left: 0;
}
.page-template-page-contact .main-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: auto;
}
@media (max-height: 825px) {
  .page-template-page-contact .main-footer {
    position: relative;
  }
}
@media (max-width: 1023px) {
  .page-template-page-contact .main-footer {
    position: relative;
  }
}

.page-template-default .main-info,
.page-template-page-signup .main-info {
  margin-top: 300px;
}
@media (max-width: 1919px) {
  .page-template-default .main-info,
.page-template-page-signup .main-info {
    margin-top: 350px;
  }
}
@media (max-width: 1023px) {
  .page-template-default .main-info,
.page-template-page-signup .main-info {
    text-align: center;
  }
}
.page-template-default .main-info ul,
.page-template-page-signup .main-info ul {
  max-width: 90%;
}
@media (max-width: 1370px) {
  .page-template-default .main-info ul,
.page-template-page-signup .main-info ul {
    max-width: 100%;
  }
}
.page-template-default .main-info p, .page-template-default .main-info li,
.page-template-page-signup .main-info p,
.page-template-page-signup .main-info li {
  font-family: "Nunito", sans-serif !important;
  font-weight: 400;
  line-height: 1.7em;
  text-align: left;
  color: #09427A;
}
.page-template-default .main-info li,
.page-template-page-signup .main-info li {
  list-style-type: disc;
  list-style-position: outside;
  color: #09427A;
  line-height: 1.4;
}
.page-template-default .main-info h2,
.page-template-page-signup .main-info h2 {
  text-transform: uppercase;
  margin: 20px 0 10px;
}
.page-template-default .main-info h3,
.page-template-page-signup .main-info h3 {
  font-family: "Nunito", sans-serif;
  color: #09427A;
  font-weight: 600;
  font-size: calc(1.3rem + 0.6vw);
  margin: 20px 0 10px;
}
@media (min-width: 1200px) {
  .page-template-default .main-info h3,
.page-template-page-signup .main-info h3 {
    font-size: 1.75rem;
  }
}
.page-template-default .main-info h4,
.page-template-page-signup .main-info h4 {
  font-family: "Nunito", sans-serif;
  color: #09427A;
  font-weight: 600;
  font-size: 1.125rem;
}
.page-template-default .main-info quote,
.page-template-page-signup .main-info quote {
  font-family: "Nunito", sans-serif;
  color: #000;
  font-weight: 600;
  font-size: 1.125rem;
  font-style: italic;
  display: block;
  margin-top: 20px;
}
.page-template-default .main-info .flex,
.page-template-page-signup .main-info .flex {
  display: inline-flex;
}
.page-template-default .main-info .flex-row,
.page-template-page-signup .main-info .flex-row {
  flex-direction: row;
}
.page-template-default .main-info .flex-col,
.page-template-page-signup .main-info .flex-col {
  flex-direction: column;
}
.page-template-default .main-info .justify-center,
.page-template-page-signup .main-info .justify-center {
  justify-content: center;
}
.page-template-default .main-info .justify-start,
.page-template-page-signup .main-info .justify-start {
  justify-content: start;
}
.page-template-default .main-info .items-center,
.page-template-page-signup .main-info .items-center {
  align-items: center;
}
.page-template-default .main-info .items-start,
.page-template-page-signup .main-info .items-start {
  align-items: start;
}
.page-template-default .main-info img,
.page-template-page-signup .main-info img {
  border-radius: 50%;
  max-width: 155px;
}
.page-template-default .main-info .text-center,
.page-template-page-signup .main-info .text-center {
  text-align: center;
  width: 100%;
  margin-top: 10px;
}
.page-template-default .main-info .form-wrapper form,
.page-template-page-signup .main-info .form-wrapper form {
  display: flex;
  flex-direction: column;
  position: relative;
}
.page-template-default .main-info .form-wrapper form .input-field,
.page-template-page-signup .main-info .form-wrapper form .input-field {
  border-radius: 10px;
  border: none;
  margin-bottom: 20px;
  outline: none;
  padding: 14px;
  font-size: 0.75rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #000;
  border: 1px solid rgba(9, 66, 122, 0.5);
}
.page-template-default .main-info .form-wrapper form textarea,
.page-template-page-signup .main-info .form-wrapper form textarea {
  height: 80px;
  resize: none;
}
.page-template-default .main-info .form-wrapper form button[type=submit],
.page-template-page-signup .main-info .form-wrapper form button[type=submit] {
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 0px 0;
  cursor: pointer;
  width: 60%;
  margin: 0 auto;
  border-radius: 4px;
  border: none;
  border: 1px solid rgba(9, 66, 122, 0.5);
  margin-top: 20px;
}
.page-template-default .main-info .form-wrapper form button[type=submit] span,
.page-template-page-signup .main-info .form-wrapper form button[type=submit] span {
  pointer-events: none;
}
.page-template-default .main-info .form-wrapper form label,
.page-template-page-signup .main-info .form-wrapper form label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.2;
  display: flex;
  justify-content: flex-start;
}
.page-template-default .main-info .form-wrapper form p.label,
.page-template-page-signup .main-info .form-wrapper form p.label {
  color: #000;
  font-size: 0.75rem;
  line-height: 1.2;
}
.page-template-default .main-info .form-wrapper form p.label a,
.page-template-page-signup .main-info .form-wrapper form p.label a {
  font-size: 0.75rem;
  display: inline-block;
}
.page-template-default .main-info .form-wrapper form input[type=checkbox],
.page-template-page-signup .main-info .form-wrapper form input[type=checkbox] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border: 1px solid rgba(9, 66, 122, 0.5);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin-right: 10px;
}
.page-template-default .main-info .form-wrapper form input[type=checkbox]::before,
.page-template-page-signup .main-info .form-wrapper form input[type=checkbox]::before {
  content: "";
  width: 10px;
  height: 10px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #09427A;
}
.page-template-default .main-info .form-wrapper form input[type=checkbox]:checked::before,
.page-template-page-signup .main-info .form-wrapper form input[type=checkbox]:checked::before {
  transform: scale(1);
}
.page-template-default .main-info .form-wrapper .response,
.page-template-page-signup .main-info .form-wrapper .response {
  opacity: 0;
  display: block;
  font-size: 1.125rem;
  font-weight: 500;
  color: #09427A;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.page-template-page-disclosures .hero {
  margin-bottom: 100px;
}
.page-template-page-disclosures .hero h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: calc(1.325rem + 0.9vw);
  line-height: 45px;
  color: #fff;
}
@media (min-width: 1200px) {
  .page-template-page-disclosures .hero h1 {
    font-size: 2rem;
  }
}
.page-template-page-disclosures .disclosures a {
  display: block;
  margin: 20px 0;
  width: 100%;
}
.page-template-page-disclosures .disclosures a:hover {
  font-weight: 600;
}
a {
  display: block;
  color: #215383;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  font-weight: 600;
}
a:hover h4 {
  transform: translateX(10px) !important;
}

.disc-group {
  background: #FAFAFA;
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 24px 30px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  color: #215383;
  box-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.12);
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}
.disc-group .files-list {
  overflow: hidden;
}
.disc-group h4 {
  color: #215383;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.app .app-wrapper {
  display: flex;
  align-items: center;
}
@media (max-width: 1023px) {
  .app .app-wrapper {
    text-align: center;
  }
}
.app .app-wrapper h2 {
  margin-bottom: 30px;
}
.app .app-wrapper p {
  margin-bottom: 40px;
}
@media (max-width: 1023px) {
  .app .app-wrapper p {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.app .app-wrapper .donwload-image-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
.app .app-wrapper .donwload-image-wrapper:first-of-type {
  margin-bottom: 20px;
  margin-right: 20px;
}
@media (max-width: 1023px) {
  .app .app-wrapper .donwload-image-wrapper {
    margin-top: 50px;
  }
}
.app .app-wrapper .donwload-image-wrapper img {
  height: 60px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.app .app-wrapper .donwload-image-wrapper img:hover {
  transform: scale3d(1.01, 1.01, 1.01);
}
.app .app-wrapper .image-container img {
  max-height: 535px;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 1023px) {
  .app .app-wrapper .image-container img {
    width: 100%;
  }
}

.main-footer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px 0;
  height: 700px;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1023px) {
  .main-footer {
    background-position: center;
    height: 900px;
  }
}
@media (max-width: 767px) {
  .main-footer {
    background-position: center;
    height: 900px;
  }
}
@media (max-width: 361px) {
  .main-footer {
    background-position: center;
    height: 800px;
  }
}
@media (min-width: 1919px) {
  .main-footer {
    background-size: 100% 120%;
  }
}
.main-footer .footer-wrapper {
  width: 100%;
}
.main-footer .newsletter {
  width: 100%;
  text-align: center;
  margin: 100px 0;
}
@media (max-width: 767px) {
  .main-footer .newsletter {
    margin: 20px 0;
  }
}
.main-footer .newsletter h2 {
  color: #FFF;
  font-weight: 700;
  font-size: calc(1.35rem + 1.2vw);
  margin-bottom: 25px;
}
@media (min-width: 1200px) {
  .main-footer .newsletter h2 {
    font-size: 2.25rem;
  }
}
.main-footer .newsletter p {
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin-bottom: 25px;
}
.main-footer .newsletter form {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (max-width: 767px) {
  .main-footer .newsletter form {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
.main-footer .newsletter form input {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border: 1px solid #D5DAE1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  min-width: 330px;
  background-color: #fff;
  outline: none;
}
@media (max-width: 767px) {
  .main-footer .newsletter form input {
    min-width: auto;
    width: 100%;
  }
}
.main-footer .newsletter form input[type=submit] {
  width: auto;
  min-width: auto;
  margin-left: 25px;
  color: #216282;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
@media (max-width: 1023px) {
  .main-footer .newsletter form input[type=submit] {
    margin-left: 0;
    margin-top: 10px;
  }
}
.main-footer .newsletter form input[type=submit]:hover {
  transform: scale(1.02) !important;
}
.main-footer .newsletter form span {
  margin-top: 10px;
  color: #FFF;
  font-size: 0.75rem;
}
@media (max-width: 1023px) {
  .main-footer .logo-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}
.main-footer .logo-wrapper .logo {
  max-width: 200px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
@media (max-width: 767px) {
  .main-footer .logo-wrapper .logo {
    max-width: 150px;
  }
}
.main-footer .logo-wrapper .logo:hover {
  transform: scale(1.05);
}
.main-footer .row {
  align-items: flex-start;
}
.main-footer .row .row {
  margin: 0;
}
@media (max-width: 1023px) {
  .main-footer nav {
    margin-bottom: 20px;
  }
}
@media (max-width: 361px) {
  .main-footer nav #menu-footer {
    flex-direction: column;
  }
}
@media (max-width: 361px) {
  .main-footer nav #menu-footer li {
    margin-top: 10px;
  }
}
@media (max-width: 361px) {
  .main-footer nav #menu-footer li:first-child, .main-footer nav #menu-footer li:last-child {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.main-footer nav #menu-footer li a {
  position: relative;
  font-size: 0.875rem;
}
.main-footer .copyright-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main-footer .copyright-container .copyright {
  color: #FFF;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  position: relative;
  font-size: 0.9375rem;
}
@media (max-width: 1023px) {
  .main-footer .copyright-container .copyright {
    text-align: center;
  }
}
.main-footer .copyright-container .social {
  position: relative;
  right: auto;
  display: flex;
  justify-content: center;
}
@media (max-width: 1023px) {
  .main-footer .copyright-container .social {
    justify-content: center;
  }
}
.main-footer .copyright-container .social .social-wrapper {
  margin: 20px 10px 0;
}
.main-footer .copyright-container .social .social-wrapper:first-of-type {
  margin-left: 0;
}
.main-footer .disclaimer {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  color: #fff;
  font-size: 0.9375rem;
  line-height: 15px;
  margin-top: 50px;
}

.mobile-app {
  z-index: 999999;
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.mobile-app .container {
  position: relative;
  width: 100%;
  height: 100px;
  background: #fff;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;
  opacity: 0;
  visibility: hidden;
}
.mobile-app .container img.app-logo {
  width: auto;
  height: 50px;
  border-radius: 15px;
  margin: 0 10px;
}
.mobile-app .container img.app-download-img {
  height: 40%;
}
.mobile-app .container .content {
  padding: 0 30px 0 0;
}
.mobile-app .container .content h6 {
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  line-height: 1.4em;
  font-weight: 700;
  color: #215383;
}
.mobile-app .container .content p {
  color: #000;
  line-height: 1em;
  font-size: 0.625rem;
}
.mobile-app .container a {
  padding-left: 5px;
  font-size: 0.875rem;
}
.mobile-app .container .close {
  position: relative;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-app .container .close span {
  width: 2px;
  background: #215383;
  height: 100%;
  position: relative;
  display: block;
  pointer-events: none;
}
.mobile-app .container .close span:first-of-type {
  opacity: 0;
  top: 0px;
  right: 0px;
}
.mobile-app .container .close span:last-of-type {
  opacity: 0;
  top: 0px;
  right: 2px;
}

.main-header {
  position: absolute;
  top: 0;
  width: 100%;
  margin: 20px 0;
  z-index: 99;
}
@media (max-width: 1023px) {
  .main-header {
    margin-bottom: 150px;
  }
}
.main-header .row {
  align-items: center;
}
.main-header .row .logo-wrapper img {
  max-width: 200px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
@media (max-width: 767px) {
  .main-header .row .logo-wrapper img {
    max-width: 150px;
  }
}
.main-header .row .logo-wrapper img:hover {
  transform: scale(1.05) !important;
}
@media (max-width: 1023px) {
  .main-header nav.desktop-menu {
    display: none;
  }
}
.main-header .burguer-wrapper {
  display: none;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
@media (max-width: 1023px) {
  .main-header .burguer-wrapper {
    display: inline-flex;
  }
}
.main-header .burguer-wrapper .menu-button-container {
  height: 25px;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main-header .burguer-wrapper .menu-button-container .menu-button {
  margin-top: 8px;
}
.main-header .burguer-wrapper .menu-button-container .menu-button,
.main-header .burguer-wrapper .menu-button-container .menu-button::before,
.main-header .burguer-wrapper .menu-button-container .menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}
.main-header .burguer-wrapper .menu-button-container .menu-button::before {
  content: "";
  margin-top: -8px;
}
.main-header .burguer-wrapper .menu-button-container .menu-button::after {
  content: "";
  margin-top: 8px;
}
.main-header .burguer-wrapper .menu-button-container.open .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}
.main-header .burguer-wrapper .menu-button-container.open .menu-button {
  background: rgba(255, 255, 255, 0);
}
.main-header .burguer-wrapper .menu-button-container.open .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

.mobile-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #215383;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  z-index: 9;
  padding: 100px 0;
  height: 100vh;
}
.mobile-menu.open {
  opacity: 1;
  visibility: visible;
  overflow: visible;
}
@media only screen and (max-width: 1023px) {
  .mobile-menu .search-bar {
    display: flex;
    max-width: 90%;
    justify-content: center;
    margin-top: 50px;
    display: none;
  }
}
.mobile-menu .search-bar label.search {
  width: auto !important;
  visibility: visible !important;
  margin-right: 10px;
}
.mobile-menu .first-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #216282;
  opacity: 0.5;
}
.mobile-menu nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-height: 515px) {
  .mobile-menu nav {
    padding-top: 100px;
  }
}
.mobile-menu nav .menu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mobile-menu nav .menu li {
  margin: 5px 0;
}
.mobile-menu nav .menu li a {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .mobile-menu nav .menu li a {
    font-size: 2.5rem;
  }
}
.mobile-menu nav .menu li.log-in a {
  background: #fff;
  color: #216282;
  padding: 5px 15px;
  border-radius: 25px;
  display: block;
  border: 2px solid transparent;
}
.mobile-menu nav .menu li.log-in.sign-up a {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
}
.mobile-menu .social {
  top: auto !important;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
body {
  overflow-x: hidden;
  font-size: 16px;
}

h1, h2, h3, h4, h5, p {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: auto;
}

h1 {
  font-family: "Righteous", cursive;
  font-weight: 400;
  text-transform: uppercase;
  color: #FFF;
  font-size: calc(1.525rem + 3.3vw);
  line-height: 1.2em;
  margin-bottom: 20px;
}
@media (min-width: 1200px) {
  h1 {
    font-size: 4rem;
  }
}
@media only screen and (max-width: 1023px) {
  h1 {
    text-align: center;
  }
}

h2 {
  font-family: "Poppins", sans-serif;
  font-size: calc(1.325rem + 0.9vw);
  line-height: 1.4em;
  font-weight: 900;
  color: #215383;
  margin-bottom: 40px;
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 1023px) {
  h2 {
    text-align: center;
  }
}

h3 {
  font-size: calc(1.325rem + 0.9vw);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
}
@media (min-width: 1200px) {
  h3 {
    font-size: 2rem;
  }
}
@media (max-width: 1023px) {
  h3 {
    text-align: center;
  }
}
@media (max-width: 1370px) {
  h3 {
    font-size: calc(1.275rem + 0.3vw);
  }
}
@media (max-width: 1370px) and (min-width: 1200px) {
  h3 {
    font-size: 1.5rem;
  }
}

h4 {
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
}
@media only screen and (max-width: 2560px) {
  h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
}
@media only screen and (max-width: 2560px) and (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 1919px) {
  h4 {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 1439px) {
  h4 {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1023px) {
  h4 {
    font-size: 1rem;
  }
}

h5 {
  font-size: 1.25rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #000;
}
@media (max-width: 1370px) {
  h5 {
    font-size: 1.25rem;
  }
}

strong {
  font-weight: bold;
}

.footer-menus {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.flex-row-end {
  display: flex;
  justify-content: flex-end;
}

main {
  overflow-x: hidden;
}

.header-menu {
  /*width: 100%;*/
}

.search-bar {
  display: flex;
  order: 2;
}
@media only screen and (max-width: 1023px) {
  .search-bar {
    display: none;
  }
}
.search-bar .search-form {
  max-width: 90%;
}
.search-bar .search-form form {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.search-bar .search-form form label.search-link {
  transform: translateY(2px);
  cursor: pointer;
}
.search-bar .search-form form label.search-link input {
  display: none;
}
.search-bar .search-form form label.search-link svg {
  height: 16px;
  margin-left: 10px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.search-bar .search-form form label.search-link svg:hover {
  transform: scale(1.1);
}
.search-bar .search-form form label.search-link svg path {
  fill: #ffffff;
}
.search-bar .search-form form label.search {
  width: 0;
  visibility: hidden;
  overflow: hidden;
  margin-left: 5px;
}
.search-bar .search-form form label.search input {
  font-family: "Poppins", sans-serif;
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  padding: 9px 20px;
  border: none;
  border-radius: 25px;
  background-color: #fff;
  outline: none;
}
.search-bar .search-btn {
  position: relative;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
nav.desktop-menu {
  justify-content: center;
}
nav #menu-header {
  flex-direction: row;
}
nav #menu-header li {
  padding: 0 10px;
}
nav #menu-header li.log-in {
  background: #fff;
  padding: 0px;
  border-radius: 25px;
  border: 2px solid transparent;
  color: #000;
  margin: 0 5px;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
nav #menu-header li.log-in:hover {
  border: 2px solid #fff;
  background-color: transparent;
}
nav #menu-header li.log-in:hover a {
  color: #fff;
}
nav #menu-header li.log-in a {
  color: #215383;
  display: block;
  padding: 8px 15px;
}
nav #menu-header li.log-in.sign-up {
  background: transparent;
  border: 2px solid #fff;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
nav #menu-header li.log-in.sign-up:hover {
  background-color: #fff;
  border: 2px solid transparent;
}
nav #menu-header li.log-in.sign-up:hover a {
  color: #215383;
}
nav #menu-header li.log-in.sign-up a {
  color: #fff;
}
nav h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  position: relative;
  font-size: 1.25rem;
  color: #FFF;
  margin-bottom: 10px;
}
nav ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  font: normal 16px Arial;
}
nav ul li {
  padding: 5px 0px;
  text-align: center;
  display: inline-block;
}
nav ul li:last-child {
  padding-right: 0rem;
}
nav ul li:first-child {
  padding-left: 0rem;
}
nav ul li a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  position: relative;
  font-size: 0.9375rem;
  color: #FFF;
  text-align: center;
}
nav ul li a:after {
  content: attr(title);
  display: block;
  font-weight: 600;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  color: transparent;
}
nav ul li a:hover {
  font-weight: 600;
}
nav ul li.current-menu-item a {
  font-weight: 600;
}

span {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #215383;
}

a {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-decoration: none;
  color: #215383;
}

p, td, span {
  font-family: "Nunito", sans-serif !important;
  font-weight: 400;
  line-height: 1.7em;
  text-align: left;
}
p.sub-title, td.sub-title, span.sub-title {
  font-weight: 600;
  color: #fff;
  font-size: 1.125rem;
}
@media only screen and (max-width: 1023px) {
  p.sub-title, td.sub-title, span.sub-title {
    text-align: center;
  }
}

section {
  margin-bottom: 130px;
}
@media (max-width: 1023px) {
  section {
    margin-bottom: 150px;
  }
}
@media (max-width: 767px) {
  section {
    margin-bottom: 100px;
  }
}

@media (max-width: 1023px) {
  .btns {
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 361px) {
  .btns {
    flex-direction: column;
  }
}
.btns button.default {
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  border: none;
  margin-top: 40px;
  padding: 0;
  overflow: hidden;
  font-size: 1.125rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #09427A;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
@media (max-width: 1023px) {
  .btns button.default {
    margin-right: 0;
    margin: 40px 10px auto 10px;
  }
}
.btns button.default .mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #09427A;
  z-index: 0;
}
.btns button.default a,
.btns button.default span {
  position: relative;
  font-weight: 400;
  color: #fff;
  font-size: 1.25rem;
  padding: 0.8125rem calc(1.34375rem + 1.125vw);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 2;
}
@media (min-width: 1200px) {
  .btns button.default a,
.btns button.default span {
    padding: 0.8125rem 2.1875rem;
  }
}
@media (max-width: 767px) {
  .btns button.default a,
.btns button.default span {
    font-size: 1rem;
  }
}
.btns button.default.white {
  background: #fff;
  border: none;
}
.btns button.default.white a,
.btns button.default.white span {
  color: #09427A;
}
.btns button.default.white .mask {
  background: #09427A;
  top: -100%;
}
.btns button.default.border {
  border: 1px solid #09427A;
}
.btns button.default.border .mask {
  border: 1px solid transparent;
}

.short-grid {
  margin: 0 200px !important;
}
@media only screen and (max-width: 1280px) {
  .short-grid {
    margin: 0 !important;
  }
}

.hide-small {
  display: inherit;
}
@media (max-width: 1023px) {
  .hide-small {
    display: none;
  }
}

.show-small {
  display: none;
}
@media (max-width: 1023px) {
  .show-small {
    display: block;
  }
}

.scroll-wrapper {
  position: absolute;
  bottom: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  font-weight: normal;
  color: #fff;
}
.scroll-wrapper .scroll {
  position: relative;
  margin-top: 5px;
  border: 2px solid #fff;
  width: 23px;
  height: 36px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.scroll-wrapper .scroll .dot {
  margin-bottom: 3px;
  opacity: 0;
}
.scroll-wrapper .scroll .arrow {
  opacity: 0;
  overflow: hidden;
}

.zoom-img {
  overflow: hidden;
  height: auto;
  margin-bottom: 20px;
  position: relative;
}
.zoom-img > div {
  overflow: hidden;
}
.zoom-img img {
  position: relative;
  display: block;
  margin-bottom: 0;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.zoom-img:hover img {
  transform: scale(1.1);
}

.cookies-warning {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  margin: 0 auto;
  width: 90vw;
  max-width: 1024px;
  z-index: 999999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  border-radius: 25px;
  height: 850px;
  max-height: 95%;
}
@media (max-width: 767px) {
  .cookies-warning {
    width: 100%;
    padding: 0 10px;
    left: 0;
    transform: translate(0%, -50%);
  }
}
.cookies-warning .cookies-content {
  background-color: #FFF;
  width: 100%;
  height: 100%;
  padding: 50px;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  position: relative;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.26);
  overflow: scroll;
}
.cookies-warning .cookies-content .popup-logo {
  width: 200px;
  height: auto;
}
@media (max-width: 1023px) {
  .cookies-warning .cookies-content .popup-logo {
    margin: 0 auto;
  }
}
.cookies-warning .cookies-content .popup-logo img {
  width: 100%;
}
.cookies-warning .cookies-content .content-wrapper {
  margin-top: 40px;
}
.cookies-warning .cookies-content .content-wrapper .speaker-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 1023px) {
  .cookies-warning .cookies-content .content-wrapper .speaker-wrapper {
    margin-top: 10px;
    justify-content: center;
    align-items: center;
  }
}
.cookies-warning .cookies-content .content-wrapper .speaker-wrapper h3 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .cookies-warning .cookies-content .content-wrapper .speaker-wrapper h3 {
    font-size: 1.5rem;
  }
}
.cookies-warning .cookies-content .content-wrapper .speaker-wrapper img {
  width: 100%;
  max-width: 150px;
  border-radius: 50%;
}
.cookies-warning .cookies-content .content-wrapper .speaker-wrapper > div {
  margin-right: 20px;
}
@media (max-width: 1370px) {
  .cookies-warning .cookies-content .content-wrapper .speaker-wrapper > div {
    margin-right: 0px;
  }
}
.cookies-warning .cookies-content .content-wrapper .speaker-wrapper .speaker-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1370px) {
  .cookies-warning .cookies-content .content-wrapper .speaker-wrapper .speaker-content {
    flex-direction: column;
  }
}
.cookies-warning .cookies-content .content-wrapper .speaker-wrapper .speaker-content .speaker-img {
  margin-top: 30px;
  margin-right: 20px;
}
@media (max-width: 1370px) {
  .cookies-warning .cookies-content .content-wrapper .speaker-wrapper .speaker-content .speaker-img {
    margin: 30px auto 0;
  }
}
.cookies-warning .main-img {
  width: 100%;
}
.cookies-warning h3 {
  color: #215383;
  font-family: "Poppins", sans-serif;
  font-size: calc(1.3rem + 0.6vw);
  text-transform: none;
  display: inline-block;
}
@media (min-width: 1200px) {
  .cookies-warning h3 {
    font-size: 1.75rem;
  }
}
.cookies-warning h4 {
  color: #215383;
  font-family: "Poppins", sans-serif;
  font-size: calc(1.3125rem + 0.75vw);
  text-transform: none;
  display: inline-block;
}
@media (min-width: 1200px) {
  .cookies-warning h4 {
    font-size: 1.875rem;
  }
}
@media (max-width: 1023px) {
  .cookies-warning h4 {
    text-align: center;
  }
}
.cookies-warning h2 {
  margin: 0;
}
.cookies-warning .divider {
  width: 100%;
  height: 10px;
  border-radius: 20px;
  background: #eff0f2;
  margin-top: 20px;
}
.cookies-warning .schedule {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1023px) {
  .cookies-warning .schedule {
    flex-direction: column;
  }
}
.cookies-warning .schedule .schedule-dates {
  display: flex;
  margin-top: 30px;
  align-items: center;
}
.cookies-warning .schedule .schedule-dates h3 {
  margin-left: 30px;
  font-size: 20px;
}
.cookies-warning .schedule .schedule-dates img {
  max-width: 50px;
}
.cookies-warning .schedule .link {
  margin-top: 20px;
}
.cookies-warning .schedule .link img {
  max-height: 60px;
}
.cookies-warning .schedule a {
  background: #215383;
  color: #fff;
  border-radius: 20px;
  padding: 10px 20px;
  margin-top: 30px;
}
@media (max-width: 1023px) {
  .cookies-warning .schedule .register-now {
    margin-top: 20px;
  }
}
.cookies-warning p {
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  line-height: 1.4em;
  color: #215383;
}
.cookies-warning .close {
  position: absolute;
  right: -10px;
  top: -15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #215383;
  border-radius: 50%;
  cursor: pointer;
}
@media (max-width: 767px) {
  .cookies-warning .close {
    right: 10px;
  }
}
.cookies-warning .close span {
  width: 2px;
  background: #fff;
  height: 15px;
  position: relative;
  display: block;
  pointer-events: none;
}
.cookies-warning .close span:first-of-type {
  transform: rotate(-45deg);
  top: 0px;
  right: -1px;
}
.cookies-warning .close span:last-of-type {
  transform: rotate(45deg);
  top: 0px;
  right: 1px;
}

.upper-notation {
  bottom: 5px;
  font-size: 10px;
  display: inline-flex;
  position: absolute;
  margin-left: 5px;
}

.info {
  width: 20px;
  height: 20px;
  position: absolute;
  margin-left: 10px;
  bottom: 6px;
  cursor: pointer;
}
.info svg {
  position: absolute;
  width: 100%;
  height: 100%;
}
.info svg path {
  fill: #09427A;
}

table {
  width: 100%;
  overflow: hidden;
}
table tbody {
  overflow: auto;
}
table tr {
  padding: 10px 0;
  position: relative;
}
table td {
  padding: 10px 0;
}
table span {
  display: block;
  text-align: center !important;
  line-height: 1;
}

.bg {
  width: 100%;
  top: 0;
  position: absolute;
  z-index: -1;
}
.bg img {
  width: 100%;
  height: auto;
  max-height: 915px;
}
@media (max-width: 1023px) {
  .bg img {
    width: auto;
    max-height: inherit;
  }
}

.social {
  position: absolute;
  right: -50px;
}
@media (max-width: 1370px) {
  .social {
    right: 20px;
  }
}
@media (max-width: 1023px) {
  .social {
    top: 100%;
  }
}
.social .social-wrapper {
  margin-bottom: 30px;
}
.social .social-wrapper:last-of-type {
  margin-bottom: 0;
}
.social .social-wrapper img {
  width: 22px;
  height: auto;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.social .social-wrapper img:hover {
  transform: scale(1.1);
}

.watermark {
  position: fixed;
  left: 0;
  z-index: -1;
}

.page-template-page-help .content-wrapper,
.tax-help_categories .content-wrapper,
.search .content-wrapper,
.single-help .content-wrapper {
  position: relative;
}
.page-template-page-help .content-wrapper img,
.tax-help_categories .content-wrapper img,
.search .content-wrapper img,
.single-help .content-wrapper img {
  margin: 20px 0;
}
.page-template-page-help .content-wrapper .aligncenter,
.tax-help_categories .content-wrapper .aligncenter,
.search .content-wrapper .aligncenter,
.single-help .content-wrapper .aligncenter {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
}
.page-template-page-help .content-wrapper .alignleft,
.tax-help_categories .content-wrapper .alignleft,
.search .content-wrapper .alignleft,
.single-help .content-wrapper .alignleft {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: left;
}
.page-template-page-help .content-wrapper .alignright,
.tax-help_categories .content-wrapper .alignright,
.search .content-wrapper .alignright,
.single-help .content-wrapper .alignright {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: right;
}
.page-template-page-help .content-wrapper span,
.tax-help_categories .content-wrapper span,
.search .content-wrapper span,
.single-help .content-wrapper span {
  color: #000;
  font-weight: inherit;
}
.page-template-page-help .content-wrapper ul li,
.tax-help_categories .content-wrapper ul li,
.search .content-wrapper ul li,
.single-help .content-wrapper ul li {
  list-style: disc;
  display: list-item;
  margin-left: 2em;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  line-height: 1.7em;
  text-align: left;
}
.page-template-page-help .content-wrapper ol li,
.tax-help_categories .content-wrapper ol li,
.search .content-wrapper ol li,
.single-help .content-wrapper ol li {
  list-style: decimal;
  display: list-item;
  margin-left: 2em;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  line-height: 1.7em;
  text-align: left;
}
.page-template-page-help .content-wrapper a,
.page-template-page-help .content-wrapper a em,
.page-template-page-help .content-wrapper a em span,
.page-template-page-help .content-wrapper a span,
.page-template-page-help .content-wrapper span a,
.tax-help_categories .content-wrapper a,
.tax-help_categories .content-wrapper a em,
.tax-help_categories .content-wrapper a em span,
.tax-help_categories .content-wrapper a span,
.tax-help_categories .content-wrapper span a,
.search .content-wrapper a,
.search .content-wrapper a em,
.search .content-wrapper a em span,
.search .content-wrapper a span,
.search .content-wrapper span a,
.single-help .content-wrapper a,
.single-help .content-wrapper a em,
.single-help .content-wrapper a em span,
.single-help .content-wrapper a span,
.single-help .content-wrapper span a {
  text-decoration: none;
  color: #0000FF !important;
  font-size: inherit;
}
.page-template-page-help .content-wrapper blockquote,
.tax-help_categories .content-wrapper blockquote,
.search .content-wrapper blockquote,
.single-help .content-wrapper blockquote {
  position: relative;
  padding-left: 40px;
  margin: 50px 0;
}
.page-template-page-help .content-wrapper blockquote:before,
.tax-help_categories .content-wrapper blockquote:before,
.search .content-wrapper blockquote:before,
.single-help .content-wrapper blockquote:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #2177AB;
}
.page-template-page-help .content-wrapper blockquote p,
.tax-help_categories .content-wrapper blockquote p,
.search .content-wrapper blockquote p,
.single-help .content-wrapper blockquote p {
  font-family: "Nunito", sans-serif;
  font-size: 1.125rem;
  line-height: 1.3em;
  font-weight: 400;
  color: #333F51;
}
.page-template-page-help .content-wrapper blockquote .source,
.tax-help_categories .content-wrapper blockquote .source,
.search .content-wrapper blockquote .source,
.single-help .content-wrapper blockquote .source {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  color: #8896AB;
  margin-top: 20px;
  display: block;
}
.page-template-page-help .content-wrapper .mask,
.tax-help_categories .content-wrapper .mask,
.search .content-wrapper .mask,
.single-help .content-wrapper .mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0;
  visibility: hidden;
}
.page-template-page-help .content-wrapper .mask .lds-ring,
.tax-help_categories .content-wrapper .mask .lds-ring,
.search .content-wrapper .mask .lds-ring,
.single-help .content-wrapper .mask .lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.page-template-page-help .content-wrapper .mask .lds-ring div,
.tax-help_categories .content-wrapper .mask .lds-ring div,
.search .content-wrapper .mask .lds-ring div,
.single-help .content-wrapper .mask .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #09427A;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #09427A transparent transparent transparent;
}
.page-template-page-help .content-wrapper .mask .lds-ring:nth-child(1),
.tax-help_categories .content-wrapper .mask .lds-ring:nth-child(1),
.search .content-wrapper .mask .lds-ring:nth-child(1),
.single-help .content-wrapper .mask .lds-ring:nth-child(1) {
  animation-delay: -0.45s;
}
.page-template-page-help .content-wrapper .mask .lds-ring div:nth-child(2),
.tax-help_categories .content-wrapper .mask .lds-ring div:nth-child(2),
.search .content-wrapper .mask .lds-ring div:nth-child(2),
.single-help .content-wrapper .mask .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.page-template-page-help .content-wrapper .mask .lds-ring div:nth-child(3),
.tax-help_categories .content-wrapper .mask .lds-ring div:nth-child(3),
.search .content-wrapper .mask .lds-ring div:nth-child(3),
.single-help .content-wrapper .mask .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.page-template-page-help .help-wrapper,
.tax-help_categories .help-wrapper,
.search .help-wrapper,
.single-help .help-wrapper {
  padding-top: 100px;
}
@media (max-width: 1023px) {
  .page-template-page-help .help-wrapper .content#help-content,
.tax-help_categories .help-wrapper .content#help-content,
.search .help-wrapper .content#help-content,
.single-help .help-wrapper .content#help-content {
    margin-top: 50px;
  }
}
.page-template-page-help .help-wrapper .content#help-content h2,
.tax-help_categories .help-wrapper .content#help-content h2,
.search .help-wrapper .content#help-content h2,
.single-help .help-wrapper .content#help-content h2 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .page-template-page-help .help-wrapper .content#help-content h2,
.tax-help_categories .help-wrapper .content#help-content h2,
.search .help-wrapper .content#help-content h2,
.single-help .help-wrapper .content#help-content h2 {
    font-size: 1.75rem;
  }
}
.page-template-page-help .help-wrapper .content#help-content .pricing-blocks .title-wrapper h3,
.tax-help_categories .help-wrapper .content#help-content .pricing-blocks .title-wrapper h3,
.search .help-wrapper .content#help-content .pricing-blocks .title-wrapper h3,
.single-help .help-wrapper .content#help-content .pricing-blocks .title-wrapper h3 {
  font-size: 1.25rem;
}

.page-template-page-help .search-container,
.tax-help_categories .search-container,
.search .search-container,
.single-help .search-container {
  width: 100%;
}
.page-template-page-help .search-container .search-bar,
.tax-help_categories .search-container .search-bar,
.search .search-container .search-bar,
.single-help .search-container .search-bar {
  width: 100%;
  display: block;
}
.page-template-page-help .search-container .search-bar .search-form,
.tax-help_categories .search-container .search-bar .search-form,
.search .search-container .search-bar .search-form,
.single-help .search-container .search-bar .search-form {
  width: 100%;
  max-width: 100%;
}
.page-template-page-help .search-container .search-bar .search-form form,
.tax-help_categories .search-container .search-bar .search-form form,
.search .search-container .search-bar .search-form form,
.single-help .search-container .search-bar .search-form form {
  display: inline-flex;
  width: 100%;
}
.page-template-page-help .search-container .search-bar .search-form form label.search,
.tax-help_categories .search-container .search-bar .search-form form label.search,
.search .search-container .search-bar .search-form form label.search,
.single-help .search-container .search-bar .search-form form label.search {
  width: auto;
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.page-template-page-help .sidebar h2,
.tax-help_categories .sidebar h2,
.search .sidebar h2,
.single-help .sidebar h2 {
  position: relative;
  margin-bottom: 20px;
}
@media (max-width: 1023px) {
  .page-template-page-help .sidebar h2,
.tax-help_categories .sidebar h2,
.search .sidebar h2,
.single-help .sidebar h2 {
    text-align: left;
  }
}
.page-template-page-help .sidebar h2 span,
.tax-help_categories .sidebar h2 span,
.search .sidebar h2 span,
.single-help .sidebar h2 span {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 20%;
  height: 5px;
  background: #215383;
}
.page-template-page-help .sidebar h5,
.tax-help_categories .sidebar h5,
.search .sidebar h5,
.single-help .sidebar h5 {
  font-size: 0.875rem;
}
.page-template-page-help .sidebar .cats-wrapper,
.tax-help_categories .sidebar .cats-wrapper,
.search .sidebar .cats-wrapper,
.single-help .sidebar .cats-wrapper {
  margin-top: 50px;
}
.page-template-page-help .sidebar .cats-wrapper .cat-wrapper,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper,
.search .sidebar .cats-wrapper .cat-wrapper,
.single-help .sidebar .cats-wrapper .cat-wrapper {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
@media (max-width: 1023px) {
  .page-template-page-help .sidebar .cats-wrapper .cat-wrapper,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper,
.search .sidebar .cats-wrapper .cat-wrapper,
.single-help .sidebar .cats-wrapper .cat-wrapper {
    justify-content: start;
    display: inline-flex;
    background: #f5f5f5;
    border-radius: 20px;
    padding: 10px 20px;
    margin: 0px 5px;
    margin-bottom: 15px;
  }
  .page-template-page-help .sidebar .cats-wrapper .cat-wrapper:first-of-type,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper:first-of-type,
.search .sidebar .cats-wrapper .cat-wrapper:first-of-type,
.single-help .sidebar .cats-wrapper .cat-wrapper:first-of-type {
    margin-left: 0;
  }
  .page-template-page-help .sidebar .cats-wrapper .cat-wrapper:last-of-type,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper:last-of-type,
.search .sidebar .cats-wrapper .cat-wrapper:last-of-type,
.single-help .sidebar .cats-wrapper .cat-wrapper:last-of-type {
    margin-right: 0;
  }
}
.page-template-page-help .sidebar .cats-wrapper .cat-wrapper .arrow,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper .arrow,
.search .sidebar .cats-wrapper .cat-wrapper .arrow,
.single-help .sidebar .cats-wrapper .cat-wrapper .arrow {
  margin-right: 30px;
  pointer-events: none;
}
@media (max-width: 1023px) {
  .page-template-page-help .sidebar .cats-wrapper .cat-wrapper .arrow,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper .arrow,
.search .sidebar .cats-wrapper .cat-wrapper .arrow,
.single-help .sidebar .cats-wrapper .cat-wrapper .arrow {
    display: none;
  }
}
.page-template-page-help .sidebar .cats-wrapper .cat-wrapper .arrow svg,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper .arrow svg,
.search .sidebar .cats-wrapper .cat-wrapper .arrow svg,
.single-help .sidebar .cats-wrapper .cat-wrapper .arrow svg {
  transform: rotate(0deg);
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  pointer-events: none;
}
@media (max-width: 1023px) {
  .page-template-page-help .sidebar .cats-wrapper .cat-wrapper .arrow svg,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper .arrow svg,
.search .sidebar .cats-wrapper .cat-wrapper .arrow svg,
.single-help .sidebar .cats-wrapper .cat-wrapper .arrow svg {
    transform: rotate(-90deg);
  }
}
.page-template-page-help .sidebar .cats-wrapper .cat-wrapper .arrow svg path,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper .arrow svg path,
.search .sidebar .cats-wrapper .cat-wrapper .arrow svg path,
.single-help .sidebar .cats-wrapper .cat-wrapper .arrow svg path {
  fill: #000;
}
.page-template-page-help .sidebar .cats-wrapper .cat-wrapper h5,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper h5,
.search .sidebar .cats-wrapper .cat-wrapper h5,
.single-help .sidebar .cats-wrapper .cat-wrapper h5 {
  color: #000;
  font-size: calc(1.2625rem + 0.15vw);
  padding-right: 20px;
  width: 100%;
  pointer-events: none;
}
@media (min-width: 1200px) {
  .page-template-page-help .sidebar .cats-wrapper .cat-wrapper h5,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper h5,
.search .sidebar .cats-wrapper .cat-wrapper h5,
.single-help .sidebar .cats-wrapper .cat-wrapper h5 {
    font-size: 1.375rem;
  }
}
.page-template-page-help .sidebar .cats-wrapper .cat-wrapper.active h5,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper.active h5,
.search .sidebar .cats-wrapper .cat-wrapper.active h5,
.single-help .sidebar .cats-wrapper .cat-wrapper.active h5 {
  color: #215383;
}
@media (max-width: 1023px) {
  .page-template-page-help .sidebar .cats-wrapper .cat-wrapper.active h5,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper.active h5,
.search .sidebar .cats-wrapper .cat-wrapper.active h5,
.single-help .sidebar .cats-wrapper .cat-wrapper.active h5 {
    color: #fff;
  }
}
.page-template-page-help .sidebar .cats-wrapper .cat-wrapper.active svg,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper.active svg,
.search .sidebar .cats-wrapper .cat-wrapper.active svg,
.single-help .sidebar .cats-wrapper .cat-wrapper.active svg {
  transform: rotate(-90deg);
}
@media (max-width: 1023px) {
  .page-template-page-help .sidebar .cats-wrapper .cat-wrapper.active svg,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper.active svg,
.search .sidebar .cats-wrapper .cat-wrapper.active svg,
.single-help .sidebar .cats-wrapper .cat-wrapper.active svg {
    transform: rotate(0deg);
  }
}
.page-template-page-help .sidebar .cats-wrapper .cat-wrapper.active svg path,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper.active svg path,
.search .sidebar .cats-wrapper .cat-wrapper.active svg path,
.single-help .sidebar .cats-wrapper .cat-wrapper.active svg path {
  fill: #215383;
}
@media (max-width: 1023px) {
  .page-template-page-help .sidebar .cats-wrapper .cat-wrapper.active,
.tax-help_categories .sidebar .cats-wrapper .cat-wrapper.active,
.search .sidebar .cats-wrapper .cat-wrapper.active,
.single-help .sidebar .cats-wrapper .cat-wrapper.active {
    background-color: #215383;
  }
}

.home .hero {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 915px;
}
@media (max-width: 1023px) {
  .home .hero {
    align-items: flex-start;
  }
}
@media (max-width: 1023px) {
  .home .hero .social {
    display: none;
  }
}
.home .hero .row {
  position: relative;
  display: flex;
  align-items: center;
}
.home .hero h1 {
  margin-top: -110px;
}
@media (max-width: 1023px) {
  .home .hero h1 {
    margin-top: 180px;
  }
}
@media (max-width: 767px) {
  .home .hero h1 {
    margin-top: 130px;
  }
}
.home .hero .row {
  width: 100%;
}
.home .hero .btns button {
  display: inline-flex;
}
@media (max-width: 1023px) {
  .home .hero .btns button {
    text-align: center;
    margin-right: 0;
  }
}
.home .hero .btns button:last-of-type a {
  font-size: 1rem;
}
.home .hero .images-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
}
@media (max-width: 1023px) {
  .home .hero .images-container {
    margin-top: 0;
  }
}
.home .hero .images-container img {
  position: absolute;
}
@media (max-width: 1023px) {
  .home .hero .images-container img {
    top: 0;
    height: 600px;
  }
}
@media (max-width: 767px) {
  .home .hero .images-container img {
    height: 500px;
  }
}
.home .hero .images-container img:nth-child(1) {
  left: -50px;
  right: auto;
}
@media (max-width: 1023px) {
  .home .hero .images-container img:nth-child(1) {
    left: auto;
    margin-left: -150px;
  }
}
.home .hero .images-container img:nth-child(2) {
  right: auto;
  left: 100px;
}
@media (max-width: 1023px) {
  .home .hero .images-container img:nth-child(2) {
    left: auto;
  }
}

.home .how-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.home .how-popup .popup-content {
  position: relative;
  background: #FFF;
  padding: 50px;
  border-radius: 15px;
  max-width: 50%;
  overflow: hidden;
}
@media (max-width: 1370px) {
  .home .how-popup .popup-content {
    max-width: 90%;
  }
}
@media (max-width: 767px) {
  .home .how-popup .popup-content {
    padding: 20px;
  }
}
.home .how-popup .popup-content .close {
  position: absolute;
  right: 0;
  top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.home .how-popup .popup-content .close span {
  background: #215383;
  width: 2px;
  height: 100%;
  display: block;
  position: absolute;
}
.home .how-popup .popup-content .close span:first-of-type {
  transform: rotate(45deg);
}
.home .how-popup .popup-content .close span:last-of-type {
  transform: rotate(-45deg);
}

.how-it-works .title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
.how-it-works .title h2 {
  text-align: center;
}
.how-it-works .title p {
  text-align: center;
}
.how-it-works .img-layout h2 {
  text-align: left;
}
.how-it-works .img-layout p {
  text-align: left;
}
@media (max-width: 1023px) {
  .how-it-works .img-layout h2, .how-it-works .img-layout p {
    text-align: center;
  }
  .how-it-works .img-layout p {
    margin-bottom: 40px;
  }
}
.how-it-works .img-layout .side-img {
  text-align: center;
}
.how-it-works .img-layout .side-img img {
  width: auto;
  object-fit: cover;
  height: 100%;
  max-height: 600px;
}
.how-it-works .cols {
  margin-bottom: 40px;
}
.how-it-works .cols img {
  height: 110px;
  width: auto;
}
@media (max-width: 1023px) {
  .how-it-works .cols .column:last-of-type .content-wrapper {
    margin-bottom: 0px;
  }
}
.how-it-works .cols .content-wrapper {
  padding: 0 10px;
}
@media (max-width: 1023px) {
  .how-it-works .cols .content-wrapper {
    margin-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .how-it-works .cols .content-wrapper {
    padding: 0;
  }
}
.how-it-works .cols .content-wrapper .content {
  background: rgba(250, 250, 250, 0.55);
  border-radius: 15px;
  padding: 10px 30px;
}
.how-it-works .cols .content-wrapper .content img {
  margin-top: -65px;
  margin-bottom: 10px;
}
.how-it-works .cols .content-wrapper .content h5 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: 10px;
}
.how-it-works .cols .content-wrapper .content p {
  position: relative;
}
.how-it-works .btns button {
  margin: 0 auto;
}

.home .investing {
  position: relative;
}
.home .investing .watermark {
  left: -30%;
}
.home .investing .calculator {
  margin-bottom: 130px;
}
@media (max-width: 1370px) {
  .home .investing .calculator {
    margin-bottom: 55px;
  }
}
.home .investing .calculator .calculator-wrapper {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home .investing .calculator .calculator-wrapper #chart {
  display: none;
  margin: 0 auto;
  min-height: 400px;
}
.home .investing .calculator .calculator-wrapper #chart div {
  margin: 0 auto;
}
@media only screen and (max-width: 1023px) {
  .home .investing .calculator .calculator-wrapper .sliders {
    margin-bottom: 50px;
  }
}
.home .investing .calculator .calculator-wrapper h2 {
  color: #215383;
}
@media only screen and (max-width: 1023px) {
  .home .investing .calculator .calculator-wrapper h2 {
    text-align: center;
  }
}
.home .investing .calculator .calculator-wrapper .slider-wrapper {
  margin-top: 25px;
}
.home .investing .calculator .calculator-wrapper .slider-wrapper h5 {
  color: #215383;
}
.home .investing .calculator .calculator-wrapper .slider-wrapper h5 .sign, .home .investing .calculator .calculator-wrapper .slider-wrapper h5 .value {
  font-weight: 900;
}
.home .investing .calculator .calculator-wrapper .slider-wrapper .slider {
  height: 6px;
  margin-top: 20px;
  overflow: visible;
  padding: 0;
  position: relative;
  width: 100%;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.home .investing .calculator .calculator-wrapper .slider-wrapper .slider .knob {
  background-color: #215383;
  text-align: center;
  width: 26px;
  height: 26px;
  position: absolute;
  z-index: 2;
  border: none;
  border-radius: 50%;
  top: -9px;
}
.home .investing .calculator .calculator-wrapper .slider-wrapper .slider .range {
  background-color: #C4C4C4;
  height: 6px;
  border-radius: 10px;
}
.home .investing .calculator .calculator-wrapper .return {
  text-align: center;
}
.home .investing .calculator .calculator-wrapper .return h5 {
  color: #215383;
  font-size: calc(1.325rem + 0.9vw);
  margin-bottom: 15px;
}
@media (min-width: 1200px) {
  .home .investing .calculator .calculator-wrapper .return h5 {
    font-size: 2rem;
  }
}
.home .investing .calculator .calculator-wrapper .return span {
  font-size: calc(1.325rem + 0.9vw);
  font-weight: 900;
}
@media (min-width: 1200px) {
  .home .investing .calculator .calculator-wrapper .return span {
    font-size: 2rem;
  }
}
.home .investing .calculator .calculator-wrapper .return .value {
  color: #215383;
  display: inline-block;
  margin-bottom: 40px;
}
.home .investing .calculator .calculator-wrapper .return .sign {
  color: #215383;
  display: inline-block;
}
.home .investing .calculator .calculator-wrapper .return.percent .value {
  margin-bottom: 0;
}
.home .investing .calculator button.default {
  margin: 100px auto;
}
.home .cta-finra a {
  font-weight: 400;
  color: #000;
  font-family: "Nunito", sans-serif;
  text-align: right;
}
@media (max-width: 1023px) {
  .home .cta-finra a {
    text-align: center;
  }
}

.join {
  position: relative;
}
.join .row {
  display: flex;
  align-items: center;
}
@media (max-width: 1023px) {
  .join .row {
    text-align: center;
  }
}
.join .row .columns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.join .row img {
  max-width: 300px;
}
@media (max-width: 1023px) {
  .join .row img {
    max-width: 200px;
    margin-bottom: 20px;
  }
}
.join .row p {
  width: 100%;
}
@media (max-width: 1023px) {
  .join .row p {
    text-align: center;
  }
}
.join .row .btns {
  width: 100%;
}
.join .row .btns button {
  display: inline-block;
}

.learn {
  position: relative;
  padding: 50px 0;
}
.learn .bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.learn .bg img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.learn h2 {
  text-align: center;
  color: #FFF;
}
.learn .categories-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-template-columns: 65% 35%;
}
@media only screen and (max-width: 1200px) {
  .learn .categories-wrapper {
    grid-template-columns: 60% 40%;
  }
}
@media only screen and (max-width: 767px) {
  .learn .categories-wrapper {
    grid-template-columns: 100%;
  }
}
.learn .categories-wrapper .cat-wrapper {
  background-color: #fff;
  border-radius: 16px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
@media only screen and (max-width: 767px) {
  .learn .categories-wrapper .cat-wrapper {
    margin: 20px 0;
  }
}
.learn .categories-wrapper .cat-wrapper:hover {
  transform: scale(1.01) !important;
}
.learn .categories-wrapper .cat-wrapper a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  height: 100%;
}
@media only screen and (max-width: 1200px) {
  .learn .categories-wrapper .cat-wrapper a {
    padding: 20px;
  }
}
@media only screen and (max-width: 450px) {
  .learn .categories-wrapper .cat-wrapper a {
    flex-direction: column;
  }
}
.learn .categories-wrapper .cat-wrapper a:hover .cat-content span {
  transform: translateX(10px);
}
.learn .categories-wrapper .cat-wrapper a .cat-content {
  width: 50%;
}
@media only screen and (max-width: 1200px) {
  .learn .categories-wrapper .cat-wrapper a .cat-content {
    width: auto;
  }
}
.learn .categories-wrapper .cat-wrapper a .cat-content.img {
  width: 30%;
}
@media only screen and (max-width: 1200px) {
  .learn .categories-wrapper .cat-wrapper a .cat-content.img {
    width: auto;
  }
}
.learn .categories-wrapper .cat-wrapper a .cat-content img {
  width: 140px;
}
@media only screen and (max-width: 450px) {
  .learn .categories-wrapper .cat-wrapper a .cat-content img {
    margin-top: 20px;
  }
}
.learn .categories-wrapper .cat-wrapper a .cat-content h5 {
  margin-bottom: 20px;
  color: #216282;
  font-weight: 700;
}
.learn .categories-wrapper .cat-wrapper a .cat-content h5 span {
  margin-left: 10px;
  display: inline-flex;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.learn .categories-wrapper .cat-wrapper:nth-of-type(1) {
  margin-right: 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .learn .categories-wrapper .cat-wrapper:nth-of-type(1) {
    margin-right: auto;
    margin-bottom: auto;
  }
}
.learn .categories-wrapper .cat-wrapper:nth-of-type(2) {
  grid-row: 3/1;
  grid-column: 2/2;
  margin-left: 20px;
}
@media only screen and (max-width: 767px) {
  .learn .categories-wrapper .cat-wrapper:nth-of-type(2) {
    grid-row: inherit;
    grid-column: inherit;
    margin-left: auto;
  }
}
.learn .categories-wrapper .cat-wrapper:nth-of-type(2) a {
  flex-direction: column;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .learn .categories-wrapper .cat-wrapper:nth-of-type(2) a {
    flex-direction: row;
  }
}
@media only screen and (max-width: 450px) {
  .learn .categories-wrapper .cat-wrapper:nth-of-type(2) a {
    flex-direction: column;
  }
}
.learn .categories-wrapper .cat-wrapper:nth-of-type(2) .cat-content {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .learn .categories-wrapper .cat-wrapper:nth-of-type(2) .cat-content {
    width: auto;
  }
}
.learn .categories-wrapper .cat-wrapper:nth-of-type(2) .cat-content.img {
  width: 100%;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .learn .categories-wrapper .cat-wrapper:nth-of-type(2) .cat-content.img {
    text-align: left;
    width: auto;
  }
}
.learn .categories-wrapper .cat-wrapper:nth-of-type(2) .cat-content:first-of-type {
  order: 2;
}
@media only screen and (max-width: 767px) {
  .learn .categories-wrapper .cat-wrapper:nth-of-type(2) .cat-content:first-of-type {
    order: inherit;
  }
}
.learn .categories-wrapper .cat-wrapper:nth-of-type(2) .cat-content img {
  margin-bottom: 50px;
}
@media only screen and (max-width: 767px) {
  .learn .categories-wrapper .cat-wrapper:nth-of-type(2) .cat-content img {
    margin-bottom: 0;
  }
}
.learn .categories-wrapper .cat-wrapper:nth-of-type(3) {
  margin-top: 20px;
  margin-right: 20px;
}
@media only screen and (max-width: 767px) {
  .learn .categories-wrapper .cat-wrapper:nth-of-type(3) {
    margin-top: auto;
    margin-right: auto;
  }
}
.learn .btns button {
  margin: 40px auto 0 auto;
}

.page-template-page-investors .investors-load-screen {
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  visibility: visible;
  z-index: 999;
}
.page-template-page-investors .investors-load-screen svg {
  width: 200px;
}
.page-template-page-investors .investors-load-screen svg path {
  stroke-width: 0.06px;
  stroke: white;
  fill: transparent;
}
.page-template-page-investors .investors-load-screen h1 {
  margin-top: 50px;
}
.page-template-page-investors .investors-load-screen .screen {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  background: linear-gradient(260deg, rgba(72, 161, 243, 0.818) 0%, #215383 58%);
}
.page-template-page-investors .investors-load-screen .mask {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #48a1f3;
  z-index: -1;
}

.page-template-page-investors h2, .page-template-page-investors h3, .page-template-page-investors h4, .page-template-page-investors p {
  mix-blend-mode: plus-lighter;
}
.page-template-page-investors .text-center {
  text-align: center;
}
.page-template-page-investors .bg-parallax {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}
.page-template-page-investors section {
  position: relative;
  height: auto;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 200px;
}
@media only screen and (max-width: 1023px) {
  .page-template-page-investors section {
    justify-content: flex-start;
    min-height: auto;
  }
}
.page-template-page-investors section.product-top-align {
  justify-content: flex-start;
  min-height: auto;
}
.page-template-page-investors section.product-top-align h3 {
  margin: 50px 0;
}
.page-template-page-investors section.product-top-align h3.centered-title {
  text-align: center;
}
.page-template-page-investors section .row {
  width: 100%;
  position: relative;
}
.page-template-page-investors section h2 {
  position: relative;
  margin: 150px 0 50px;
  top: 0;
  font-size: calc(1.375rem + 1.5vw);
  z-index: 9;
  display: block;
}
@media (min-width: 1200px) {
  .page-template-page-investors section h2 {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 1023px) {
  .page-template-page-investors section h2 {
    position: relative;
    text-align: center;
  }
}
.page-template-page-investors section h3 {
  font-family: "Poppins", sans-serif;
  font-size: calc(1.3375rem + 1.05vw);
  color: #215383;
  font-weight: 500;
  margin-bottom: 20px;
  overflow: hidden;
  line-height: 1em;
}
@media (min-width: 1200px) {
  .page-template-page-investors section h3 {
    font-size: 2.125rem;
  }
}
.page-template-page-investors section h3 div {
  overflow: hidden;
}
.page-template-page-investors section h3 div div {
  padding-bottom: 5px;
}
.page-template-page-investors section h3.margin-top {
  margin-top: 150px;
}
.page-template-page-investors section h4 {
  font-family: "Poppins", sans-serif;
  font-size: calc(1.3rem + 0.6vw);
  color: #215383;
  font-weight: 400;
  margin-bottom: 20px;
}
@media (min-width: 1200px) {
  .page-template-page-investors section h4 {
    font-size: 1.75rem;
  }
}
.page-template-page-investors section p {
  color: #959595;
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .page-template-page-investors section p {
    font-size: 1.5rem;
  }
}
.page-template-page-investors footer {
  background-image: none !important;
}
.page-template-page-investors #launcher {
  display: none;
}
.page-template-page-investors .back-splash {
  position: absolute;
  width: 120%;
  height: 150%;
  left: -10%;
  top: -25%;
  border-radius: 20px;
  z-index: -1;
  display: none;
}
.page-template-page-investors .back-splash.color-one {
  background-color: #55acff;
}
.page-template-page-investors .back-splash.color-two {
  background-color: #708dff;
}
.page-template-page-investors .back-splash.pad-left {
  left: -5%;
}
.page-template-page-investors .back-splash.pad-right {
  left: -15%;
}
.page-template-page-investors section.product {
  position: relative;
}
.page-template-page-investors section.product.slide {
  min-height: 100vh;
}
.page-template-page-investors section.product h2 {
  text-align: left;
  position: relative;
}
@media only screen and (max-width: 1023px) {
  .page-template-page-investors section.product h2 {
    position: relative;
    text-align: center;
  }
}
.page-template-page-investors section.product .img-wrapper {
  width: 100%;
  position: absolute;
  right: -15px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  max-height: 100vh;
  overflow: hidden;
  z-index: -1;
}
@media only screen and (max-width: 1023px) {
  .page-template-page-investors section.product .img-wrapper {
    position: relative;
    align-items: center;
    justify-content: center;
    right: inherit;
    top: inherit;
    display: flex;
    width: 100vw;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-page-investors section.product .img-wrapper {
    margin-top: 50px;
    height: 500px;
  }
}
.page-template-page-investors section.product .img-wrapper .section ul img {
  width: 220px;
  margin: 10px;
}
@media only screen and (min-width: 1439px) {
  .page-template-page-investors section.product .img-wrapper .section ul img {
    width: 250px;
  }
}
@media only screen and (max-width: 767px) {
  .page-template-page-investors section.product .img-wrapper .section ul img {
    width: 200px;
  }
}
@media only screen and (max-width: 361px) {
  .page-template-page-investors section.product .img-wrapper .section ul img {
    width: 150px;
  }
}
.page-template-page-investors section.product.video-section .row {
  justify-content: center;
  align-items: center;
}
.page-template-page-investors section.product.video-section .row h3 {
  margin-top: 0;
}
.page-template-page-investors section.product.video-section .row .video-container {
  width: 120%;
  position: relative;
  left: -20%;
}
@media only screen and (max-width: 1023px) {
  .page-template-page-investors section.product.video-section .row .video-container {
    left: 0;
    width: 100%;
  }
}
.page-template-page-investors section.product.video-section .row .video-container img {
  width: 100%;
}
.page-template-page-investors section.product.video-section .row .video-container video {
  position: absolute;
  left: 12%;
  top: 10%;
  width: 76%;
  height: 78%;
  object-fit: cover;
}
.page-template-page-investors section.income h2 {
  margin-top: 0;
}
.page-template-page-investors section.income h3 {
  margin: 50px 0 0;
  text-align: center;
}
.page-template-page-investors section.income h4 {
  text-align: center;
  margin-bottom: 50px;
}
.page-template-page-investors section.income .corp {
  display: flex;
  align-items: flex-start;
}
.page-template-page-investors section.income .corp h4 {
  line-height: 1.3;
  font-size: 1.25rem;
}
.page-template-page-investors section.income .corp .corporate-graph {
  position: relative;
  width: 100%;
  height: 500px;
}
.page-template-page-investors section.income .corp .corporate-graph .corporate-graph-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.page-template-page-investors section.income .corp .corporate-graph .corporate-graph-content #pie {
  height: 500px;
}
.page-template-page-investors section.income .corp .corporate-graph .corporate-graph-content #pie circle {
  stroke: #215383;
  stroke-linecap: round;
}
.page-template-page-investors section.income .corp .corporate-graph .corporate-graph-content .counter {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
}
.page-template-page-investors section.income .corp .corporate-graph .corporate-graph-content .counter span {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .page-template-page-investors section.income .corp .corporate-graph .corporate-graph-content .counter span {
    font-size: 1.75rem;
  }
}
.page-template-page-investors section.income .corp .corporate-graph .corporate-graph-content .counter span.value {
  font-size: calc(1.4625rem + 2.55vw);
}
@media (min-width: 1200px) {
  .page-template-page-investors section.income .corp .corporate-graph .corporate-graph-content .counter span.value {
    font-size: 3.375rem;
  }
}
.page-template-page-investors section.income .corp .corporate-graph .corporate-graph-content .counter span.sign {
  margin-top: 10px;
}
.page-template-page-investors section.income .ret {
  margin-top: 100px;
  align-items: center;
}
.page-template-page-investors section.income .ret .retail-funds-graph {
  margin: 50px 0 10px;
  width: 100%;
  height: 500px;
}
.page-template-page-investors section.income .ret h4 {
  margin-top: 20px;
  line-height: 1.3;
  font-size: 1.25rem;
}
.page-template-page-investors section.banking {
  justify-content: flex-start;
}
.page-template-page-investors section.banking .row {
  margin: 50px 0;
}
.page-template-page-investors section.banking h2 {
  margin-top: 0;
}
.page-template-page-investors section.banking h3 {
  text-align: center;
}
@media only screen and (max-width: 1023px) {
  .page-template-page-investors section.banking .column {
    margin: 50px 0;
  }
}
.page-template-page-investors section.banking .column h4, .page-template-page-investors section.banking .column p {
  mix-blend-mode: normal;
}
.page-template-page-investors section.banking .col-wrapper {
  position: relative;
  padding: 20px;
  height: 100%;
  background-color: #f1f5f7;
  border-radius: 5px;
}
.page-template-page-investors section.banking .col-wrapper h4 {
  margin-top: 20px;
  text-align: center;
}
.page-template-page-investors section.banking .col-wrapper p {
  text-align: center;
}
.page-template-page-investors section.banking .col-wrapper svg,
.page-template-page-investors section.banking .col-wrapper rect {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  fill: transparent;
  border-radius: 5px;
}
.page-template-page-investors section.banking .col-wrapper rect {
  stroke: #215383;
  stroke-width: 2;
}
.page-template-page-investors section.banking .counter-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-template-page-investors section.banking .counter-wrapper p {
  font-size: calc(1.275rem + 0.3vw);
  line-height: 22px;
}
@media (min-width: 1200px) {
  .page-template-page-investors section.banking .counter-wrapper p {
    font-size: 1.5rem;
  }
}
.page-template-page-investors section.banking .counter-wrapper span {
  font-size: calc(1.3375rem + 1.05vw);
}
@media (min-width: 1200px) {
  .page-template-page-investors section.banking .counter-wrapper span {
    font-size: 2.125rem;
  }
}
.page-template-page-investors section.banking .counter-wrapper span.sign {
  margin-right: 10px;
}
.page-template-page-investors section.banking .counter-wrapper .counter {
  width: 26%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.page-template-page-investors section.banking .counter-wrapper .counter-content {
  width: 65%;
}
.page-template-page-investors section.banking .col-content {
  margin-bottom: 20px;
}
.page-template-page-investors section.banking .col-content p {
  line-height: 1.4;
}
.page-template-page-investors section.banking .image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-template-page-investors section.banking .image-wrapper img {
  width: 100%;
  height: auto;
  margin: 0 auto;
  max-width: 300px;
  margin-top: 100px;
}
.page-template-page-investors section.team .row {
  align-items: center;
}
.page-template-page-investors section.team img {
  width: 100%;
  height: 120%;
  object-fit: cover;
}
.page-template-page-investors section.team .swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-template-page-investors section.team .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.page-template-page-investors section.team .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 7px;
  height: 7px;
  background: #215383;
}

.page-template-page-press h4 {
  font-family: "Poppins", sans-serif;
  text-transform: none;
}
.page-template-page-press .press-container {
  margin-top: 50px;
}
.page-template-page-press .press-container .disc-group {
  background-color: transparent;
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  padding: 24px 0;
  margin-bottom: 0;
}
.page-template-page-press .press-container .disc-group span {
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px !important;
}

.single-press .press-content {
  margin-top: 150px;
}
.single-press .press-content h4 {
  font-family: "Poppins", sans-serif;
  text-transform: none;
  color: #215383;
  font-size: 1.25rem;
}
.single-press .press-content p {
  font-size: 1.125rem;
}
.single-press .press-content a {
  display: inline-block;
}
.single-press .press-content h2, .single-press .press-content h3, .single-press .press-content h4 {
  margin-top: 50px;
}

.page-template-page-pricing .watermark,
.page-template-page-help .watermark,
.tax-help_categories .watermark,
.search .watermark,
.single-help .watermark {
  right: -30%;
  left: auto;
  top: 0;
}
.page-template-page-pricing .pricing-blocks .accordeon-wrapper,
.page-template-page-help .pricing-blocks .accordeon-wrapper,
.tax-help_categories .pricing-blocks .accordeon-wrapper,
.search .pricing-blocks .accordeon-wrapper,
.single-help .pricing-blocks .accordeon-wrapper {
  width: 100%;
  background: #FAFAFA;
  border-radius: 15px;
  pointer-events: auto;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 5px 30px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.page-template-page-pricing .pricing-blocks .accordeon-wrapper:hover,
.page-template-page-help .pricing-blocks .accordeon-wrapper:hover,
.tax-help_categories .pricing-blocks .accordeon-wrapper:hover,
.search .pricing-blocks .accordeon-wrapper:hover,
.single-help .pricing-blocks .accordeon-wrapper:hover {
  transform: scale(1.01) !important;
}
.page-template-page-pricing .pricing-blocks .accordeon-wrapper .title-wrapper,
.page-template-page-help .pricing-blocks .accordeon-wrapper .title-wrapper,
.tax-help_categories .pricing-blocks .accordeon-wrapper .title-wrapper,
.search .pricing-blocks .accordeon-wrapper .title-wrapper,
.single-help .pricing-blocks .accordeon-wrapper .title-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  pointer-events: all;
}
.page-template-page-pricing .pricing-blocks .accordeon-wrapper .title-wrapper .content,
.page-template-page-help .pricing-blocks .accordeon-wrapper .title-wrapper .content,
.tax-help_categories .pricing-blocks .accordeon-wrapper .title-wrapper .content,
.search .pricing-blocks .accordeon-wrapper .title-wrapper .content,
.single-help .pricing-blocks .accordeon-wrapper .title-wrapper .content {
  pointer-events: none;
}
.page-template-page-pricing .pricing-blocks .accordeon-wrapper .title-wrapper h3,
.page-template-page-help .pricing-blocks .accordeon-wrapper .title-wrapper h3,
.tax-help_categories .pricing-blocks .accordeon-wrapper .title-wrapper h3,
.search .pricing-blocks .accordeon-wrapper .title-wrapper h3,
.single-help .pricing-blocks .accordeon-wrapper .title-wrapper h3 {
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #215383;
  pointer-events: none;
}
@media (max-width: 1370px) {
  .page-template-page-pricing .pricing-blocks .accordeon-wrapper .title-wrapper h3,
.page-template-page-help .pricing-blocks .accordeon-wrapper .title-wrapper h3,
.tax-help_categories .pricing-blocks .accordeon-wrapper .title-wrapper h3,
.search .pricing-blocks .accordeon-wrapper .title-wrapper h3,
.single-help .pricing-blocks .accordeon-wrapper .title-wrapper h3 {
    text-align: left;
  }
}
.page-template-page-pricing .pricing-blocks .accordeon-wrapper .title-wrapper .arrow,
.page-template-page-help .pricing-blocks .accordeon-wrapper .title-wrapper .arrow,
.tax-help_categories .pricing-blocks .accordeon-wrapper .title-wrapper .arrow,
.search .pricing-blocks .accordeon-wrapper .title-wrapper .arrow,
.single-help .pricing-blocks .accordeon-wrapper .title-wrapper .arrow {
  pointer-events: none;
}
.page-template-page-pricing .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper,
.page-template-page-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper,
.tax-help_categories .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper,
.search .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper,
.single-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  cursor: default;
}
.page-template-page-pricing .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper span.note,
.page-template-page-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper span.note,
.tax-help_categories .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper span.note,
.search .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper span.note,
.single-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper span.note {
  padding: 10px;
  background: #eee;
  margin: 10px 0;
}
.page-template-page-pricing .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper span.note p,
.page-template-page-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper span.note p,
.tax-help_categories .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper span.note p,
.search .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper span.note p,
.single-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper span.note p {
  font-family: "Nunito", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  color: #2C2C2C;
}
.page-template-page-pricing .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col,
.page-template-page-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col,
.tax-help_categories .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col,
.search .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col,
.single-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border: none;
  border-bottom: 1px solid #CDCDCD;
}
.page-template-page-pricing .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col:last-of-type,
.page-template-page-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col:last-of-type,
.tax-help_categories .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col:last-of-type,
.search .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col:last-of-type,
.single-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col:last-of-type {
  border: none;
}
.page-template-page-pricing .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title,
.page-template-page-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title,
.tax-help_categories .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title,
.search .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title,
.single-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 50px;
  text-align: center;
  padding: 10px 0;
  background-color: transparent;
  width: auto;
}
.page-template-page-pricing .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title h3, .page-template-page-pricing .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title p,
.page-template-page-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title h3,
.page-template-page-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title p,
.tax-help_categories .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title h3,
.tax-help_categories .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title p,
.search .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title h3,
.search .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title p,
.single-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title h3,
.single-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title p {
  color: #2C2C2C;
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  line-height: 1em;
}
@media (max-width: 361px) {
  .page-template-page-pricing .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title h3, .page-template-page-pricing .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title p,
.page-template-page-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title h3,
.page-template-page-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title p,
.tax-help_categories .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title h3,
.tax-help_categories .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title p,
.search .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title h3,
.search .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title p,
.single-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title h3,
.single-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .title p {
    font-size: 0.75rem;
  }
}
.page-template-page-pricing .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .value,
.page-template-page-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .value,
.tax-help_categories .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .value,
.search .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .value,
.single-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .value {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 0 10px 20px;
  height: 100%;
  flex-direction: column;
}
.page-template-page-pricing .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .value p,
.page-template-page-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .value p,
.tax-help_categories .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .value p,
.search .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .value p,
.single-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .value p {
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  line-height: 1em;
  font-weight: 400;
  color: #2C2C2C;
}
@media (max-width: 361px) {
  .page-template-page-pricing .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .value p,
.page-template-page-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .value p,
.tax-help_categories .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .value p,
.search .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .value p,
.single-help .pricing-blocks .accordeon-wrapper .accordeon-content-wrapper .accordeon-col .value p {
    font-size: 0.75rem;
  }
}

.page-template-page-pricing .bg img {
  height: 915px;
  max-height: 915px;
  width: 100%;
}
@media (max-width: 1023px) {
  .page-template-page-pricing .bg img {
    object-fit: cover;
  }
}
.page-template-page-pricing .hero {
  position: relative;
  height: 100%;
}
.page-template-page-pricing .hero > .row {
  padding-top: 200px !important;
  position: relative;
}
@media (max-width: 1023px) {
  .page-template-page-pricing .hero > .row {
    padding-top: 150px !important;
  }
}
@media (max-width: 1280px) {
  .page-template-page-pricing .hero > .row .short-grid {
    padding: 0 2.5rem !important;
  }
}
@media (max-width: 1023px) {
  .page-template-page-pricing .hero > .row .short-grid {
    padding: 0 !important;
  }
}
@media (max-width: 1023px) {
  .page-template-page-pricing .hero .social {
    display: none;
  }
}
.page-template-page-pricing .hero h1 {
  font-family: "Nunito", sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  text-transform: inherit;
  display: none;
}
@media (max-width: 1023px) {
  .page-template-page-pricing .hero h1 {
    text-align: center;
  }
}
.page-template-page-pricing .hero h2 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: calc(1.325rem + 0.9vw);
  margin-bottom: 20px;
  text-transform: uppercase;
}
@media (min-width: 1200px) {
  .page-template-page-pricing .hero h2 {
    font-size: 2rem;
  }
}
@media (max-width: 1023px) {
  .page-template-page-pricing .hero h2 {
    text-align: center;
  }
}
.page-template-page-pricing .hero p {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
}

.search-hero {
  width: 100%;
  position: relative;
  text-align: center;
  margin-bottom: 100px;
}
@media (max-width: 1023px) {
  .search-hero {
    margin-bottom: 100px;
  }
}
.search-hero .row {
  position: relative;
  justify-content: center;
}
.search-hero .row .columns {
  width: 100%;
}
.search-hero h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: calc(1.325rem + 0.9vw);
  line-height: 45px;
  color: #fff;
  text-transform: none;
  margin-top: 175px;
}
@media (min-width: 1200px) {
  .search-hero h1 {
    font-size: 2rem;
  }
}
.search-hero .sub-title {
  text-align: center;
}
.search-hero .social {
  top: 150px;
}
@media (max-width: 1023px) {
  .search-hero .social {
    display: none;
  }
}
.search-hero .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.search-hero .bg img {
  width: 100%;
  height: auto;
  height: 600px;
}
@media (max-width: 1023px) {
  .search-hero .bg img {
    height: 595px;
    object-fit: cover;
  }
}

.page-search a {
  display: block;
  margin: 20px 0;
}
.page-search a:hover {
  font-weight: 600;
}
.page-search .search-wrapper {
  margin-bottom: 300px;
}
.page-search .search-wrapper a {
  background: #FAFAFA;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 24px 30px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #215383;
  box-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.12);
}
.page-search .search-wrapper a:hover {
  transform: scale(1.01) !important;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: 30px;
}
.breadcrumbs a,
.breadcrumbs span {
  color: #000;
  font-weight: 400;
  height: 100%;
  line-height: 1.1em;
}
.breadcrumbs a:first-child,
.breadcrumbs span:first-child {
  width: auto;
}
@media only screen and (max-width: 767px) {
  .breadcrumbs a,
.breadcrumbs span {
    text-overflow: ellipsis;
    overflow: hidden;
    width: auto;
    white-space: nowrap;
    width: 160px;
  }
  .breadcrumbs a:first-child,
.breadcrumbs span:first-child {
    min-width: 40px;
  }
}
.breadcrumbs svg {
  margin: 0 10px;
  height: 9px;
}
.breadcrumbs svg path {
  fill: #000;
}

.single-blog .post-content button {
  margin: 50px auto;
}

.single-blog .hero,
.single-press .hero,
.page-template-page-press .hero {
  background: white;
  background: linear-gradient(260deg, rgba(72, 161, 243, 0.818) 0%, #215383 58%);
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 120px 0 50px 0;
  min-height: 350px;
}
.single-blog .hero .row,
.single-press .hero .row,
.page-template-page-press .hero .row {
  width: 100%;
}
.single-blog .hero .row h1,
.single-press .hero .row h1,
.page-template-page-press .hero .row h1 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: calc(1.325rem + 0.9vw);
  text-align: left;
}
@media (min-width: 1200px) {
  .single-blog .hero .row h1,
.single-press .hero .row h1,
.page-template-page-press .hero .row h1 {
    font-size: 2rem;
  }
}
.single-blog .hero .row .date,
.single-press .hero .row .date,
.page-template-page-press .hero .row .date {
  color: #fff;
  display: block;
  font-family: "Nunito", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
}
.single-blog .hero .row .author,
.single-press .hero .row .author,
.page-template-page-press .hero .row .author {
  color: #fff;
  display: block;
  font-family: "Nunito", sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
}

.single-blog .post-content .image-block {
  margin: 50px auto;
  max-width: 80%;
}
@media (max-width: 1023px) {
  .single-blog .post-content .image-block {
    max-width: 100%;
  }
}
.single-blog .post-content .image-block img {
  height: auto;
  width: 100%;
  object-fit: contain;
}
.single-blog .post-content .image-block span {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  color: #8896AB;
  display: block;
}

@media (max-width: 767px) {
  .single-blog .post-content {
    margin-bottom: 50px;
  }
}
.single-blog .post-content > .row {
  justify-content: space-between;
}
.single-blog .post-content .featured {
  width: 100%;
  height: auto;
  max-height: 800px;
  object-fit: cover;
}
.single-blog .post-content .watermark {
  left: auto;
  right: -35%;
  z-index: -1;
  top: 200px;
}
.single-blog .post-content .side-bar {
  padding-left: 20px;
  margin: 50px 0;
}
@media (max-width: 1023px) {
  .single-blog .post-content .side-bar {
    padding-left: 0.625rem;
  }
}
.single-blog .post-content .side-bar .latest-posts-wrapper {
  grid-template-columns: 1fr;
}
@media (max-width: 1023px) {
  .single-blog .post-content .side-bar .latest-posts-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 767px) {
  .single-blog .post-content .side-bar .latest-posts-wrapper {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 1023px) {
  .single-blog .post-content .side-bar .latest-posts-wrapper h3 {
    text-align: left;
  }
}
.single-blog .post-content .side-bar .latest-posts-wrapper img {
  height: 150px;
}
.single-blog .post-content .side-bar .latest-posts-wrapper p {
  line-height: 1.2em;
}
.single-blog .post-content .next-post {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
.single-blog .post-content .next-post h2 {
  margin: 0;
}
.single-blog .post-content .next-post .btns button {
  margin: 20px 0;
}

.single-blog .post-content .quote-block {
  position: relative;
  padding-left: 40px;
  margin: 50px 0;
}
.single-blog .post-content .quote-block:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #2177AB;
}
.single-blog .post-content .quote-block p {
  font-family: "Nunito", sans-serif;
  font-size: calc(1.275rem + 0.3vw);
  line-height: 1.3em;
  font-weight: 400;
  color: #333F51;
}
@media (min-width: 1200px) {
  .single-blog .post-content .quote-block p {
    font-size: 1.5rem;
  }
}
.single-blog .post-content .quote-block .source {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  color: #8896AB;
  margin-top: 20px;
  display: block;
}

.single-blog .post-content .copy-feedback {
  text-align: left !important;
}
.single-blog .post-content .social-share {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 55px auto 0;
}
.single-blog .post-content .social-share .social {
  position: relative;
  display: flex;
  flex-direction: row;
  right: auto;
}
.single-blog .post-content .social-share .social .social-wrapper {
  margin-bottom: 0;
  margin: 0 10px;
}
.single-blog .post-content .social-share .social img {
  filter: brightness(0.5) invert(1);
}
.single-blog .post-content .social-share button {
  background-color: transparent;
  border: 1px solid #808080;
  box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
  border-radius: 6px;
  height: 40px;
  width: auto;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: #808080;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.single-blog .post-content .social-share button:first-of-type {
  margin-left: 0;
}
.single-blog .post-content .social-share button a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.single-blog .post-content .social-share button svg {
  height: 20px;
}
.single-blog .post-content .social-share button.socials {
  width: 40px;
}
.single-blog .post-content .social-share button.copy {
  padding: 0 16px;
}
.single-blog .post-content .social-share button.copy:hover {
  transform: scale(1.05) !important;
}
.single-blog .post-content .social-share button.copy svg {
  margin-right: 5px;
}
.single-blog .post-content .copy-feedback {
  display: block;
  color: green;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  text-align: center;
  display: block;
  margin-top: 10px;
}

.single-blog .post-content .text-block {
  margin: 50px 0;
}
.single-blog .post-content .text-block p,
.single-blog .post-content .text-block p span {
  font-family: "Nunito", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  color: #556987;
  margin-bottom: 20px;
  line-height: 1.3em;
}
.single-blog .post-content .text-block p em,
.single-blog .post-content .text-block p span em {
  font-style: italic;
}
.single-blog .post-content .text-block p strong,
.single-blog .post-content .text-block p span strong {
  font-weight: bold;
}
.single-blog .post-content .text-block ul {
  padding-left: 40px;
  margin-bottom: 20px;
}
.single-blog .post-content .text-block ul li {
  font-family: "Nunito", sans-serif;
  font-size: 1.125rem;
  line-height: 1.3em;
  font-weight: 400;
  color: #556987;
  list-style: disc;
  list-style-position: outside;
  margin-bottom: 10px;
}
.single-blog .post-content .text-block blockquote {
  position: relative;
  padding-left: 40px;
}
.single-blog .post-content .text-block blockquote:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #2177AB;
}

.load-screen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.load-screen .mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
}
.load-screen img {
  width: 100px;
}