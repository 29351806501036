.single-blog,
.single-press,
.page-template-page-press{
    .hero{
        // min-height: 460px;
        background: rgb(255,255,255);
        background: linear-gradient(260deg, rgba(72, 161, 243, 0.818) 0%, rgba(33,83,131,1) 58%);
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding: 120px 0 50px 0;
        min-height: 350px;
        @include max-screen($xsmall){
            // min-height: 260px;
        }
        .row{
            width: 100%;

            h1{
                color: #fff;
                font-family: 'Poppins', sans-serif;
                font-weight: 900;
                @include font-size(32px);
                text-align: left;
            }
            .date{
                color: #fff;
                display: block;
                font-family: 'Nunito', sans-serif;
                @include font-size(14px);
                font-weight: 400;
            }
            .author{
                color: #fff;
                display: block;
                font-family: 'Nunito', sans-serif;
                @include font-size(12px);
                font-weight: 400;
            }
        }
    }
}