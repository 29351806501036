.search-hero{
	// margin-bottom: 0;
	width: 100%;
	position: relative;
	// display: flex;
	// justify-content: center;
	// align-items: center;
	// height: 915px;
	text-align: center;
	margin-bottom: 100px;
	@media (max-width: $small) {
		margin-bottom: 100px;
	}

	.row {
		position: relative;
		justify-content: center;

		.columns {
			width: 100%;
		}
	}


	h1 {
		font-family: 'Poppins', sans-serif;
		font-weight: 900;
		@include font-size(32px);
		line-height: 45px;
		color: #fff;
		text-transform: none;
		margin-top: 175px;
	}

	.sub-title {
		text-align: center;
	}

	.social {
		top: 150px;
		@media (max-width: $small) {
			display: none;
		}
	}

	.bg {
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: -1;
		display: flex;
		align-items: flex-start;
		justify-content: center;

		img {
			width: 100%;
			height: auto;
			height: 600px;
			@media (max-width: $small) {
				height: 595px;
				object-fit: cover;

			}
		}
	}
}
.page-search {
	a {
		display: block;
		margin: 20px 0;

		&:hover {
			font-weight: 600;
		}
	}

	.search-wrapper {
		margin-bottom: 300px;
		a {
			background: #FAFAFA;
			border-radius: 15px;
			cursor: pointer;
			margin-bottom: 20px;
			padding: 24px 30px;
			transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
			font-family: "Nunito", sans-serif;
			font-size: 1rem;
			font-weight: 600;
			color: #215383;
			box-shadow: 2px 1px 1px 0px rgba(0, 0, 0, 0.12);

			&:hover {
				transform: scale(1.01) !important;
			}
		}
	}
}

