.page-template-page-about-us{    
    .about{
        position: relative;
        margin-top: 300px;

        
        @media (max-width: $small) {
            margin-top: 280px;
        }
        @media (min-width: $xlarge) {
            margin-top: 350px;
        }
        h2{
            text-transform: uppercase;
            @media (max-width: $small) {
                text-align: center;
            }
        }
        p{
            @media (max-width: $small) {
                text-align: center;
            }
        }
        .first-content{
            margin-bottom: 50px;
        }
        img{
            max-height: 400px;
            height: auto;
        }
        .two-cols{
            display: flex;
            align-items: center;
            .columns{
                @media (max-width: $small) {
                    text-align: center;
                }
            }
            p{
              margin-top: -50px;
            }
        }
        .watermark{            
            // top: -50%;
            right: -30%;
            left: auto;
            img{
                max-height: inherit;
            }
        }       
    }
}
