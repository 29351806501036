
.app{
    .app-wrapper{
        // background-color: #000;            
        display: flex;
        align-items: center;
        // border-radius: 20px;
        // background: rgb(255,255,255);
        // background: radial-gradient(circle at 72% center, white -185%, black 55%);
        @media (max-width: $small) {
            text-align: center;
            // background: radial-gradient(circle at center center, white -100%, black 55%);
        }

        h2{
            // color: #fff;
            margin-bottom: 30px;
        }
        p{
            // color: #fff;
            margin-bottom: 40px;
            @media (max-width: $small) {
                text-align: center;
                margin-top: 0px;
                margin-bottom: 0px;
            }            
        }
        // .app-img-download{
        //     width: 150px;
        //     @media (max-width: $small){
        //         margin-bottom: 40px;
        //     }
        // }
        .donwload-image-wrapper{
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;

            &:first-of-type{
                margin-bottom: 20px;
                margin-right: 20px;
            }

            @media (max-width: $small){
                margin-top: 50px;
            }            

            // @media (max-width: $small) {
            //     height: 500px;
            // }
            // @media (max-width: $xsmall) {
            //     height: 300px;
            // }
            img{
                // height: 100%;
                height: 60px;
                transition: all .5s $ease1;
                &:hover{
                    transform: scale3d(1.01,1.01,1.01);
                }
            }
        }
        .image-container{
            img{
                max-height: 535px;                
                height: 100%;
                object-fit: contain;
                @media (max-width: $small){
                    width: 100%;
                }
            }
        }
        // .btns{
        //     button{
        //         display: inline-block;
        //     }
        // }
        
    }
}
