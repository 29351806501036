.page-template-page-about-us{
    .locations{        
        h2{
            color: #215383;
            text-align: left;
            text-transform: uppercase;
            
            @media (max-width: $small) {
                text-align: center;
            }
            
        }
        #map{
            width: 100%;
            height: 350px;
            border-radius: 16px;
        }
        .mapouter{
            .gmap_canvas{
                iframe{
                    width: 100%;
                    height: 300px;
                    border-radius: 16px;
                }
            }
        }
    }
}