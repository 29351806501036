.single-blog {
    .post-content {

        // padding: 50px 0;
        @media (max-width: $xsmall) {
            // padding: 50px 0 0 0;
            margin-bottom: 50px;
        }

        &>.row {
            justify-content: space-between;

            .columns {
                // margin: 0 auto;
            }
        }

        .featured {
            width: 100%;
            height: auto;
            max-height: 800px;
            object-fit: cover;
        }

        .watermark {
            left: auto;
            right: -35%;
            z-index: -1;
            top: 200px;
        }

        .side-bar {
            padding-left: 20px;
            margin: 50px 0;

            @media (max-width: $small) {
                padding-left: 0.625rem;
            }

            .latest-posts-wrapper {
                grid-template-columns: 1fr;

                @media (max-width: $small) {
                    grid-template-columns: 1fr 1fr;
                }

                @media (max-width: $xsmall) {
                    grid-template-columns: 1fr;
                }

                h3 {
                    @media (max-width: $small) {
                        text-align: left;
                    }
                }

                img {
                    height: 150px;
                }

                p {
                    line-height: 1.2em;
                }
            }
        }

        .next-post {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;

            h2 {
                margin: 0;
            }

            .btns {
                button {
                    margin: 20px 0;
                }
            }
        }
    }
}