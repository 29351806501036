.load-screen{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    top: 0;
    left: 0;
    // transform: translateX(100vw);
    opacity: 1;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    .mask{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255,255,255,.5);
    }
    img{
        width: 100px;
    }
}