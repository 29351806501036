.home{
    .hero{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;        
        // background-image: url('../images/mesh-gradient.jpg');
        height: 915px;
        @media (max-width: $small) {
            align-items: flex-start;                        
        }
        .social{
            @media (max-width: $small) {
                display: none;
            }
        }
        .row{
            position: relative;
            display: flex;
            align-items: center;
        }
        h1{
            margin-top: -110px;
            @media (max-width: $small) {
                margin-top: 180px;
            }
            @media (max-width: $xsmall) {
                margin-top: 130px;                
            }
        }
        
        .row{
            width: 100%;        
        }
        
        .btns{            
            button{
                display: inline-flex;
                @media (max-width: $small) {
                    text-align: center;
                    margin-right: 0;
                }
                &:last-of-type{
                    a{
                        @include font-size(16px);
                    }
                }
            }
            
        }
        

        .images-container{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 150px;
            @media (max-width: $small) {
                margin-top: 0;
            }  
            img{
                position: absolute;                                
                @media (max-width: $small) {
                    top: 0;
                    height: 600px;
                }                
                @media (max-width: $xsmall) {
                    height: 500px;
                }
                &:nth-child(1){
                    left: -50px;
                    right: auto;      
                    @media (max-width: $small) {
                        left: auto;
                        margin-left: -150px;
                    }                          
                }
                &:nth-child(2){
                    right: auto;
                    left: 100px;
                    @media (max-width: $small) {
                        left: auto;
                    }
                }
                
            }
        }
             
    }
}